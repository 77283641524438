const common = {
  eng: "EN",
  swe: "SW",
  english: "ENGLISH",
  swedish: "SVENSKA",
  menu: "MENY",
  close: "Stäng",
  dashboard: "Instrumentbräda",
  aboutUs: "Om Oss",
  career: "Karriär",
  contactUs: "Kontakta Oss",
  newsAndInsights: "Nyheter & Insights",
  ourTeam: "Vårt Team",
  serviceAndSolution: "Expertis & Tjänster",
  termsAndCondition: "Allmänna Villkor",
  privacyNotice: "Integritetspolicy",
  cookieNotice: "Cookie Notice",
  whistleBlowerPolicy: "Visselblåsarpolicy",
  ourOffices: "Våra Kontor",
  vaxjo: "Växjö",
  helsinborg: "Helsingborg",
  kalmar: "Kalmar",
  malmo: "Malmö",
  stockholm: "Stockholm",
  pages: "Sidor",
  socilaMedia: "Sociala Medier",
  linkedin: "LinkedIn",
  instagram: "Instagram",
  links: "Länkar",
  copyrights: "© 2023 RosholmDell Advokatbyrå AB, All Rights Reserved",
  registrationNo: "Registration no. 556858-6290, VAT no. SE556858629001",
  emailCaps: "EMAIL",
  phoneNUmberCaps: "MOBIL",
  copied: "COPIED!",
  writeToUsSmall: "Write to us",
  noteText:
    "We regularly host workshops and seminars within the field. To stay informed, be sure to follow us on",
  readMore: "Läs mer ",
  here: "här",
  createJob: "Skapa jobbannons",
  joblist: "Lista över jobbannonser",
  updateJob: "Uppdatera jobbdetaljer",
  createNewsPost: "Uppdatera Nyheter & Insights Post",
  updateNewsPost: "Skapa inlägg för nyheter och insikter",
  newsPostList: "Lista över nyheter och insikter",
  updateTeam: "Uppdatera Team Member",
  createTeam: "Skapa Team Member",
  teamList: "Gruppmedlemslista",
  updateTestimonial: "Uppdatera vittnesmål",
  createTestimonial: "Skapa vittnesmål",
  testimonialList: "Rekommendationslista",
  login: "Logga in",
  ourStory: "Vår berättelse",
  competence: "Kompetens:",
  experiance: "Erfarenhet:",
  education: "Utbildning:",
  address1: "Järnvägsgatan 10 B, våning 5, SE-252 78, Helsingborg",
};
const routeNames = {
  about: "/Omoss",
};
const homePage = {
  headerText: "Användbar Juridik",
  buttonText: "BÖRJA HÄR",
};
const aboutPage = {
  heroHeaderText: "Vi levererar ",
  heroHeaderText2: "Användbar Juridik",
  heroContentHeader1: "Våra kunders framgång är vår framgång",
  heroContentHeader2: "Fullservice",
  heroContentHeader3: "True partnership",
  heroContentHeader4: "LEAN",
  heroContent1: `Vi sätter våra kunders resultat i första rummet. Vi tar oss an ärenden med en lösningsorienterad
  inställning och eftersträvar enkla lösningar, oavsett ärendets komplexitet. Med kunden i fokus
  strävar vi efter att leverera högkvalitativa produkter som kunden enkelt kan integrera i sin
  verksamhet. Det är det vi kallar Användbar Juridik.`,
  heroContent2: `Vårt team av dedikerade medarbetare har specialisering i att leverera Användbar Juridik inom en rad
  affärsjuridiska områden. Vi tjänar framgångsrika kunder på både nationell och internationell nivå.
  Våra kunder varierar från startups och scaleups, till små och medelstora företag, kommuner och
  andra offentliga organ, och stora internationella industriföretag. Vi har som mål att möjliggöra och
  förenkla deras affärsverksamhet och effektivt adressera deras utmaningar och möjligheter, oavsett
  om dessa är i en lokal eller internationell kontext. `,
  heroContent3:
    "Vår byrå vilar på en true partnership-struktur. Detta innebär att byråns resultat delas lika mellan byråns ägare. Det betyder också att medarbetare med samma erfarenhet har samma ersättning. Med denna struktur främjar vi kundfokus och teamwork. Strukturen medför därför att vi konsekvent kan tjäna våra kunder med högsta servicenivån. Ansvarstagande och pålitlighet är även kärnan i denna struktur.",
  heroContent4:
    "Vi tillämpar LEAN-principer genom hela organisationen. Ett arbetssätt utifrån LEAN-principer möjliggör fokus på att minska slöseri, effektiva processer där resultatet står i fokus samt ständigt förbättrad produktkvalitet. Genom detta arbete gör vi det också möjligt för oss att hela tiden leverera Användbar Juridik.",
  link1: "EXPERTIS & TJÄNSTER",
  link2: "VÅRT TEAM",
  link3: "ARBETA PÅ ROSHOLMDELL",
  link4: "VÅR HISTORIA",
  testimonialHeader: "Sagt Om Oss",
  testimonialText1:
    "“In RosholmDell, we att Genova have found a collaborative partner who is responsive, curious and efficient. The advice from RosholmDell is extremely professional and direct. We are very happy about the competence RosholmDell contributes to the continued development of Genova’s operations.”",
  testimonialWriter: "- Henrik Sandstrom, Property manager Genova",
  contactus: "KONTAKTA OSS",
};

const careerPage = {
  headerText: "Forma din ",
  headerText2: "framtid",
  headerText3: " tillsammans med oss",
  headerContentText: `Våra kunders uppskattning av våra tjänster är avgörande för vår existens. Vårt framgångsrika
  team är också en nyckelfaktor. Vi är stolta över vårt dedikerade team som dagligen arbetar hårt
  utifrån vår mission att leverera Användbar Juridik inom många affärsjuridiska områden.`,
  contentText1:
    "I vår strävan att leverera högkvalitativ Användbar Juridik, letar vi alltid efter talangfulla personer att utvecklas tillsammans med. Vi letar särskilt efter personer som strävar efter att resonera utifrån ”first principles thinking”. Är du den personen?",
  contentText2:
    "På RosholmDell definierar vi talang som viljan att ta ägarskap och ansvar. Vi lovar att motivera och stödja dig i att uppnå din fulla potential. Vi lovar också att hjälpa dig i din strävan efter både personlig och professionell utveckling. Detta vet vi att vi kan göra då vi utvecklar både vår organisation och varandra utifrån vår egen modell som vi kallar PACE. Det är en internt utvecklad modell som möjliggör öppen dialog, möjlighet att sträva efter höga målsättningar och ständiga förbättringar.",
  contentText3:
    "Vi letar alltid efter individer som är nyfikna och intresserade av att vara med och bidra på vår resa att ständigt förbättras och ständigt förbättra vårt kunderbjudande. Om du är intresserad av att lära dig mer om RosholmDell är vi intresserade av att höra från dig.",
  readmore: "Read more",
  readless: "Read less",
  openPosition: "Lediga tjänster",
  position1: "Workplace-related investigation work (APU)",
  expiry1: "Apply by 23 May 2023",
  position2: "Executive trainee",
  expiry2: "Apply by June 2023",
  position3: "Thesis internship",
  expiry3: "Apply throughout the year",
  ourstory: "VÅR BERÄTTELSE",
  contactUs: "Kontakta Oss.",
};
const ourStoryPage = {
  headerText: "RosholmDell: Vår Historia",
  buttontext: "LÄS MER",
  subHeaderText: "Du växer genom att kliva utanför din egen komfortzon",
  headerContentText:
    "Grundarna av RosholmDell, Anders Rosholm och Martin Dell, träffades i början av 2000-talet. Vid den tiden arbetade de med M&A och affärsjuridik på advokatbyrån Mannheimer Swartling. Under sina år tillsammans upptäckte de vikten av ödmjukhet, kundfokus och hårt arbete – inte bara för kunderna utan också för och gentemot kollegor. De blev också medvetna om att man växer genom att utmana sin egen komfortzon och att ”lean in” inför nya utmaningar. Denna tanke har även effektivt uttryckts av Frank Slootman, som en gång sa:",
  quoteText:
    "“If you are feeling comfortable, you are clearly not leaning in enough.”",
  itemHeader1: "Grundad på en minut",
  item1Content1:
    "2010 beslutade Anders och Martin att lämna sina gamla jobb för att utforska nya möjligheter. Anders gick vidare till att bli bolagsjurist på ett stort industriföretag medan Martin tog steget fullt ut som entreprenör. Året efter kände Anders att han ville göra något mer betydelsefullt, så han ringde Martin med ett förslag – att starta en affärsjuridisk advokatbyrå. Martin tvekade inte en sekund.",
  item1Content2:
    "De hade en enkel men ambitiös vision – att leverera högkvalitativ affärsjuridisk rådgivning med fokus på kunden. De ville också vara ödmjuka och i framtiden ge anställda möjlighet att utvecklas i utmanande roller. Med bara två kunder och ett solidariskt partnerskap började de bygga RosholmDell, från grunden.",
  itemHeader2: "Organisk tillväxt och viktiga rekryteringar",
  item2Content1: `Sedan starten har RosholmDell vuxit organiskt. Tillväxten har drivits av ett ständigt växande
  team av kvalificerade, engagerade och lojala medarbetare som lever efter företagets kultur.
  Denna hängivenhet från medarbetarna har varit en central del i byråns utvecklingsresa och
  fortsätter att spela en avgörande roll för dess tillväxt.`,
  itemHeader3: "Vår unika approach: LEAN",
  item3Content1: `Under byråns utveckling har tillämpningen av LEAN-principer varit central, både för att
  tillhandahålla högkvalitativa affärsjuridisk rådgivning och för företagets ledning.`,
  item3Content2: `Arbetet utifrån LEAN-principer fokuserar på minimering av slöseri, kontinuerliga förbättringar
  och en kultur där vi kontinuerligt ifrågasätter både idéer och arbetsmetoder. Detta motiverar
  även oss att ständigt sträva efter möjligheter att utvecklas och bli bättre.`,
  itemHeader4: "Vårt kundfokuserade mindset: Användbar Juridik",
  item4Content1: `Vårt LEAN-baserade angreppssättet är en nyckelkomponent i byråns ständiga strävan att tjäna
  kunderna på det mest effektiva sättet. Engagemanget för byråns kunder fångas i byråns mission:`,
  item4Content2: `“Vi levererar högkvalitativ Användbar Juridik”`,
  item4Content3: `För att leverera Användbar Juridik behöver vi ha ett mindset som utgår ifrån att även om ett råd
  är juridiskt korrekt, kan det vara oanvändbart om det inte passar eller är skräddarsytt för
  kundens unika behov och mål.`,
  item4Content4:
    "Att leverera Användbar Juridik kräver en medvetenhet om inte bara vem som är mottagaren, utan även andra intressenter och deras specifika behov, mål och förutsättningar.",
  itemHeader5: "Vi blickar framåt",
  item5Content1: `Idag är vi tacksamma för att ha kunder från hela världen. Det stöd vi har fått är ovärderligt och vi
  värderar varje framgång vi nått tillsammans med kunder, medarbetare och samarbetspartners.`,
  item5Content2:
    "När vi fortsätter utvecklas kommer kraven på oss att ständigt öka. Digitalisering kommer att hjälpa oss att förbättra våra tjänster, men också ställa krav på smart implementering. Oavsett framtida utmaningar kommer kundfokus alltid att vara kärnan i vårt arbete. Vi är dedikerade att alltid leverera Användbar Juridik till både nuvarande och framtida kunder.",
};
const jobpositionPage = {
  headerText: "Är du vår nästa ",
  headerText2: "Executive Trainee",
  headerText3: "?",
  headerContentText:
    "We educate the lawyers of the future. People with focus on results and the client. Lawyers who can handle the business law of the future. Are you driven to become the best at what you do and do you want to become the best in business law? Do you think it is important to understand concepts from the ground, think critically and own the details? Do you have a doer mentality? We will customize a position for you where you will work in close cooperation with one of our partners. The position will be customised with a focus on corporate commercial, corporate and M&A. At the same time, you will be broadly trained in problem-solving, leadership and business.",
  jobrequirements: "Förutsättningar",
  jobrequirementsText1: `As a person, you must have a "doer" mentality and be responsible. You must want to understand concepts and phenomena fundamentally and strive for simple solutions, regardless of the complexity of the task. You are welcome to be critical of prevailing norms and challenge ideas.`,
  jobrequirementsText2: `You should have about 2 years left on your studies. You are studying Industrial Economics or any education in economics/business at bachelor's or master's level.`,
  jobresponsibilities: "Ansvar",
  jobresponsibilitiesText1: `You will work very closely with the CEO and finance manager. You will learn and work practically with:`,
  jobresponsibilitiesText2: `Organizational and business development`,
  jobresponsibilitiesText3: `Market analyzes and presentations`,
  jobresponsibilitiesText4: `Financial analysis and data analysis`,
  jobresponsibilitiesText5: `Tactical sales work`,
  jobresponsibilitiesText6: `The scope of the position is part-time (50%) and is paid. Great flexibility in the arrangement of working hours will be given. The position also includes work outside the study semesters. Location for the position is Malmö. Certain work from home/at another location will also be possible.`,
  aboutRd: "Om Oss",
  aboutRdText1:
    "RosholmDell is a business law firm focusing on providing high-quality Useful Legal Services to our customers. Useful Legal Services means approaching every matter with a solution-driven mindset, prioritizing simplicity even in the face of complexity. We strive to deliver high-quality products that seamlessly integrate into our customer's businesses, making implementation effortless.",
  aboutRdText2:
    "We are a firm that is continuously developing for the better. We deploy LEAN principles throughout the organization, enabling focus on reducing waste, streamlining procedures, and improving product quality. By deploying these principles, we allow us to deliver Useful Legal Service to our customers.",
  aboutRdText3:
    "With just over 30 employees working together as one team from our offices in Malmö, Helsingborg, Växjö and Kalmar, we help approximately 400 customers on an ongoing basis. We help customers in national and international contexts, and we serve customers in all of Sweden and Europe.",
  cardText:
    "The deadline for applications is 15 April 2023. Applications are processed on an ongoing basis. You apply by sending your CV and a personal letter to",
  cardLink: "rekrytering@rdlaw.se.",
};
const newsAndInsightsPage = {
  headerText: "Nyheter & Insights",
  allposts: "ALLA INLÄGG",
  news: "NYHETER",
  insights: "INSIGHTS",
  loadMore: "MER",
  seeLess: "SEE LESS",
};
const servicePage = {
  headerText: `Vi använder vår expertis och våra
  erfarenheter med ett fokus –
  leverera högkvalitativ`,
  headerOrangeText: ` Användbar Juridik`,
  headerText2: "till våra kunder.",
  headerContentText: `Våra kunders framgångar är våra framgångar. Vi tar oss an varje uppdrag
    med problemlösning som utgångspunkt. Vi vet värdet av att prioritera
    enkelhet oavsett uppdragets komplexitet. Vi strävar alltid efter att leverera
    högkvalitativa produkter som sömlöst kan integreras i våra kunders
    verksamhet. Läs mer om vår expertis och våra tjänster nedan.`,
  ourservices: "Vår Expertis",
  oursolutions: "Våra Tjänster",
  commercialIpTechnology: "Commercial, IP & Tech",
  disputeResolution: "Kommersiell Tvistelösning",
  complainceAndRegulatory: "Compliance & Regulatory",
  corporateAndPrivate: "Corporate & Private Equity",
  startUp: "Startup, Growth & Ventures",
  environment: "Miljörätt",
  employment: "Arbetsrätt",
  mergerAndAcquition: "Mergers & Acquisitions",
  publicAndProcurement: "Offentlig Upphandling",
  railwayAndPublic: "Järnväg & Kollektivtrafik",
  realEstateAndConstruction: "Fastighet, Hyra & Entreprenad",
  whistleblowingSolution: "Visselblåsartjänst",
  employmentLawSolution: "Employment Law as a Service",
  dpoAsService: "DSO as a Service",
  ipportfoliomanagment: "IP Portfolio Management",
};
const contactPage = {
  contactPageHeaderText: "Kom i kontakt med oss",
  contactPageDescText:
    "Med expertis och engagemang står vårt team redo att tillgodose dina juridiska behov med Användbar Juridik. Om du har frågor eller önskar rådgivning, vänligen använd vårt kontaktformulär eller välj en särskild representant att kontakta.",
  contactNumber: "+46 470 78 88 55",
  writeToUsText: "SKRIV TILL OSS",
  WriteToUsSmall: "Skriv till oss",
  yourFullName: "Ditt namn*",
  yourEmailId: "Din Email*",
  yourPhoneNumber: "Telefonnummer*",
  subject: "Ämne",
  yourMessage: "Ditt meddelande här*",
  submit: "SKICKA",
  offices: "Kontor",
  notARobot: "Jag är ingen robot",
  termsAndConditionsText: "Vänligen läs vår ",
  termsAndConditionsLinkText: "Integritetspolicy",
  termsAndConditionsEndText:
    " för mer information om hur RosholmDell behandlar dina personuppgifter.",
  thankYou: "Tack",
  thankyou2: " för att du skriver till oss, vi kommer att kontakta dig snart!",
  okay: "OKEJ",
};
const disputeResolutionPage = {
  author: "Johan Norrlin",
  mail: "johan.norrlin@rdlaw.se",
  mobileNo: "+46 702 748 529",
  designation: "Specialist Counsel",
  title: "Med omfattande erfarenhet av kommersiella ",
  titleOrange1: "  tvister",
  and: " och   ",
  titleOrange2: "processer",
  dot: " lovar vi effektiv, resultatorienterad expertrådgivning",
  description1: `Vår grupp med specialisering inom kommersiella tvister och processer består av
    erfarna jurister med bakgrund från olika positioner inom rättsväsendet. Gruppen
    företräder olika privata och offentliga kunder inom alla sektorer. Med sin erfarenhet
    ger gruppen effektiv rådgivning i, till exempel, statusbedömningar, utarbetande och
    granskning av kravbrev, stämningar, förlikningsförhandlingar, medlingsprocesser
    och annan korrespondens. Gruppen har lång erfarenhet av hantering av både
    skiljeförfaranden och tvister, både i allmän domstol och i alternativa
    tvistlösningsförfaranden. Vi söker alltid ändamålsenliga och kostnadseffektiva
    lösningar som är skräddarsydda efter dina mål.`,
  subTitle1: "Vägledning i Tvister",
  subTitle2: "Lång Erfarenhet och Skräddarsydda råd",
  content1: [
    {
      header: "Bedömningar av Processuell Status",
      text: ": Vi förenklar dina tvister och processer genom att ge tydliga, lösningsorienterade råd om din processuella ställning och strategiska alternativ.",
    },
    {
      header: "Skriftliga Inlagor och Korrespondens",
      text: ": Vår grupp utarbetar och granskar all dokumentation som krävs i din process. Detta inkluderar korrespondens och inlagor som, till exempel, stämningsansökningar och kravbrev.",
    },
    {
      header: "Skiljeförfarande",
      text: ":  Vi tillhandahåller rådgivning före, under och efter ditt skiljeförfarande. Våra tjänster omfattar hantering av förfaranden enligt förfaranderegler som, till exempel, Stockholms Handelskammares regler (SCC). ",
    },
    {
      header: "Tvistemål",
      text: ": Vårt team vägleder dig genom alla steg i en tvist, från att avgöra vart du ska lämna in din stämningsansökan, identifiera tillämpliga lagar, undersöka förhandlings- eller skiljedomsalternativ och säkerställa att åtgärder vidtas i rätt tid, till att hantera ditt ärende, utforma starka juridiska argument och kontrollera kostnaderna under rättstvisten. När dom meddelats hjälper vi dig med verkställighet, undersöker möjligheter till överklagande och klargör dina intressen efter domen.",
    },
  ],
  content2: [
    {
      header: "Erfarenhet",
      text: ": Vårt team har omfattande erfarenhet av kommersiell tvistlösning och rättstvister. Våra advokater, av vilka flera arbetat som domare, har samlat den erfarenhet som krävs för att navigera dig genom alla legala utmaningar kopplat till tvister och tvistelösningsförfaranden.",
    },
    {
      header: "Snabb och Kompetent Rådgivning",
      text: ":  Vi ger snabb och effektiv rådgivning genom att arbeta i små, tätt sammansvetsade team som alltid prioriterar enkla, användbara lösningar.",
    },
    {
      header: "Skräddarsydda Tjänster",
      text: ":  Vi strävar efter att alltid tillhandahålla tjänster skräddarsydda efter dina specifika behov och mål. För att uppnå detta är vi alltid engagerade i er verksamhet och de utmaningar ni möter.",
    },
    {
      header: "Samarbete",
      text: ": För att kunna erbjuda er bästa möjliga rådgivning samarbetar vi med specialister inom andra områden. Vi strävar efter att arbeta som en integrerad del av ert team och att alltid finnas nära till hands för att hålla er informerade och uppdaterade från början till slut.",
    },
  ],
};
const commercialIpTechnology = {
  author: "Nicklas Lång",
  mail: "nicklas.lang@rdlaw.se",
  mobileNo: "+46 768 754 083 ",
  designation: "Senior Counsel",
  title:
    "Vi hjälper ditt företag växa och utvecklas genom expertrådgivning inom",
  titleOrange1: " commercial",
  title2: ",",
  titleOrange2: " IP",
  and: " och ",
  titleOrange3: "tech",
  description1: `I varje skede av ditt företags livscykel strävar vi efter att förenkla hanteringen av
  dina legala utmaningar. Från att utforma kommersiella avtal till att strukturera
  effektiva outsourcingarrangemang, och från att lansera innovationer till att hantera
  immateriella tillgångar, hjälper vi dig med snabba, effektiva och lösningsorienterade
  tjänster. Vi är även väl insatta i E-handel och IT-relaterade frågor, och vi anpassar
  alltid våra tjänster efter dina unika behov och mål.`,
  subTitle1: "Strategisk och Operativ Rådgivning",
  subTitle2: "Hantering av din Teknologi och IP-portfölj",
  subTitle3: "Ett Team för din Affärsresa",
  subTitle4: "IP Portfolio Management Solution",
  content1: [
    {
      header: "Kommersialisering av Innovationer",
      text: `: Vi har gedigen erfarenhet för att kunna hjälpa
      dig att ta produkter och innovationer till marknaden på ett effektivt sätt. Vi hjälper
      dig att utveckla kommersiella strategier, nyttja licensmöjligheter samt hantera
      varumärkesregistrering och upphovsrättsliga frågor.`,
    },
    {
      header: "Outsourcingavtal",
      text: `:  Vi hjälper dig utveckla och driva outsourcingprojekt. Under hela
      outsourcingprocessen hjälper vi dig med, bland annat, sekretessavtal och
      funktionella ramavtal.`,
    },
    {
      header: "Operativa Avtal",
      text: `: Vi hjälper dig med alla juridiska frågor som rör den operativa delen
      av ditt företag. Detta inkluderar utformning och granskning av avtal som rör, till
      exempel, försäljning, distribution, tillverkning, leverantörskedjor eller forskning och
      utveckling (FoU).`,
    },
    {
      header: "Leveranser av Anläggningar",
      text: `:  Vi vägleder dig genom mångfacetterade projekt som
      systemleveranser, turnkey-projekt och olika upphandlings- och leveransprojekt.
      Dessutom hjälper vi till med greenfield- och brownfield-investeringar samt andra
      utländska direktinvesteringar.`,
    },
  ],
  content2: [
    {
      header: "IP-Strategier",
      text: `: Vi hjälper dig med frågor som rör skydd och strategisk licensiering av
      dina immateriella rättigheter (IP). Vi hjälper dig, bland annat, med strategier
      utformade för att fullt ut utnyttja dina immateriella rättigheter. Våra tjänster
      omfattar även analys av patentportföljer, varumärkesgranskning,
      upphovsrättsregistrering, hantering av företagshemligheter, licensförhandlingar och
      IP-due diligence.`,
    },
    {
      header: "Tvister",
      text: `: Vi hjälper dig i immaterialrättsliga tvister med effektiv och strategisk
      rådgivning för konfliktlösning i rättstvister och förhandlingsprocesser`,
    },
    {
      header: "Stöd för E-handel",
      text: `: Vi tillhandahåller en komplett rådgivningstjänst som omfattar ehandelslagstiftning, konsumentlagstiftning, regelefterlevnadsfrågor,
      användarintegritet, dataskydd, användarvillkor samt onlineannonsering och
      marknadsföring. Våra tjänster omfattar även, distributionsavtal och
      sekretessrelaterad dokumentation och information`,
    },
  ],
  content3: [
    {
      header: "IT",
      text: `: Vi hjälper dig upprätta och förhandla IT och teknikrelaterade avtal, till exempel
      avseende outsourcing, support, hosting, programvarulicenser och SaaS-lösningar.
      Våra tjänster omfattar även hantering av upphandling och andra juridiska aspekter
      av FoU-projekt inom IT- och teknikområdet.`,
    },
    {
      header: "Erfarenhet",
      text: `: Våra medarbetare har erfarenhet av att arbeta för och på uppdrag av
      globala företag, IP-byråer, myndigheter, innovativa industriföretag och startups`,
    },
    {
      header: "Snabb och Kompetent Rådgivning",
      text: `: Vi ger snabb och effektiv rådgivning genom att
      arbeta i små, tätt sammansvetsade team som alltid prioriterar enkla, användbara
      lösningar.`,
    },
    {
      header: "Skräddarsydda Tjänster",
      text: `: Vi strävar efter att alltid tillhandahålla tjänster
      skräddarsydda efter dina specifika behov och mål. För att uppnå detta är vi alltid
      engagerade i er verksamhet och de utmaningar ni möter.`,
    },
    {
      header: "Samarbete",
      text: `:  För att kunna erbjuda er bästa möjliga rådgivning samarbetar vi med
      specialister inom andra områden. Vi strävar efter att arbeta som en integrerad del
      av ert team och att alltid finnas nära till hands för att hålla er informerade och
      uppdaterade från början till slut.`,
    },
  ],
  content4: [
    {
      text: `Vi hjälper dig förvalta dina immateriella tillgångar med vår IP Portfolio Management
      Solution. Tjänsten är utformad för att ge dig en robust och effektiv förvaltningstjänst
      för IP-tillgångar. Vår lösning omfattar strategisk varumärkeshantering,
      internationellt skydd, övervakning av licensavtal och viktiga uppdateringar i IP-landskapet. Med vårt erfarna team och AI-drivna verktyg kan vi skydda dina IP-tillgångar. Upptäck mer`,
      clickHere: " här",
      dot: ".",
    },
  ],
};

const complainceAndRegulatoryPage = {
  author: "Kerstin Eifrém",
  mail: "kerstin.eifrem@rdlaw.se",
  mobileNo: "+46 709 795 140",
  designation: "Associate",
  title: "Vi är specialister inom ",
  titleOrange1: "compliance",
  and: " och ",
  title2:
    " fokuserar alltid på effektiva, användbara lösningar i alla situationer",
  description1: `I takt med att omfattande regelverk blir allt fler, och kraven på regelefterlevnad blir mer
    komplexa erbjuder vi tjänster, produkter och lösningar med syfte att hjälpa dig
    navigera och implementera de krav som ställs på din verksamhet. Vårt mål är att
    hjälpa dig uppnå mer, med mindre resurser. Med denna princip som grund hjälper vi
    dig med frågor som rör exempelvis antikorruption, intressekonflikter, penningtvätt
    och sanktioner samt andra utmaningar kopplat till internationell handel. Dessutom
    kan vi tillhandahålla skräddarsydda råd som hjälper dig att uppfylla standarder för
    hållbarhet, mänskliga rättigheter och informations- eller IT-säkerhet. Vi hjälper er
    också att uppfylla kraven på dataskydd och e-privacy, konsumentskydd och marknadsföring. Slutligen tillhandahåller vi en helhetslösning för en intern visselblåsarkanal och ett externt dataskyddsombud som en tjänst.`,
  subTitle1: "Vår Expertis inom Compliance",
  subTitle2: "Effektivt Stöd för Effektiva Lösningar",
  subTitle3: "Lång Erfarenhet och Skräddarsydda Tjänster",
  subTitle4:
    "Effektivisering av Efterlevnadsprocesser med Skräddarsydda Lösningar",
  content1: [
    {
      header: "Antikorruption, Intressekonflikter och Penningtvätt",
      text: ": Vi hjälper er att följa regelverken kring antikorruption, intressekonflikter och penningtvätt, till exempel genom att utarbeta och implementera policyes, samt genomföra interna och externa utredningar.",
    },
    {
      header:
        "Corporate Social Responsibility (CSR), Hållbarhet och Mänskliga Rättigheter",
      text: ":  Vi hjälper dig att navigera i frågor som rör miljö, sociala frågor och bolagsstyrning samt att hantera tvister och krav.   Vi ger vägledning i hur ni kan uppfylla internationella normer för mänskliga rättigheter och stöd för att lösa eventuella tvister relaterade till mänskliga rättigheter.",
    },
    {
      header: "Dataskydd och Informationssäkerhet",
      text: ": Med omfattande erfarenhet av att hjälpa företag inom olika sektorer att implementera och följa dataskydds- och integritetsbestämmelser, såsom GDPR, hjälper vi dig med alla dina integritetsfrågor. Våra tjänster omfattar att skapa och implementera omfattande dataskyddsstrategier, utarbeta interna styrdokument och databehandlingsavtal. Vi ger även råd kring hantering av dataintrång och personuppgiftsincidenter, samt vägledning i internationella dataöverföringar.Vi erbjuder också rådgivning inom EU:s nya direktiv om cybersäkerhet, NIS 2. ",
    },
    {
      header: "Konsumentskydd och marknadsföring",
      text: ":  Vi hjälper er att följa de omfattande krav som ställs på er som säljer varor eller tjänster till konsumenter, t.ex. regler om marknadsföring, prissättning och vad ni som bedriver e-handel måste tänka på. Vi säkerställer exempelvis att era försäljningsvillkor är korrekta, att kundklubbar och lojalitetsprogram fungerar väl och att er marknadsföring på sociala medier och via influencers följer tillämpliga regelverk.",
    },
    {
      header: "Värdekedjor, Sanktioner och Exportkontroll",
      text: ": Vi vägleder dig i ärenden relaterade till internationell handel och hanterar efterlevnaden av sanktioner och exportkontroller. Vi hjälper dig även hantera risker i samband med sanktioner och exportkontroller.",
    },
    {
      header: "Visselblåsning",
      text: `: Vi tillhandahåller en komplett och effektiv visselblåsartjänst.
            Lösningen innefattar rapporteringskanaler och hantering av inkomna rapporter. Vår
            lösning främjar en transparent arbetsmiljö genom att tillhandahålla en säker kanal
            för anställda att rapportera problem samtidigt som den säkerställer efterlevnad av
            lagar om visselblåsning. Vi hjälper dig även vidta åtgärder för att avhjälpa eventuella
            problem.
            `,
    },
    {
      header: "DPOaaS",
      text: `: Med vår DPO as a Service kan vi erbjuda dig en resurs som kan hjälpa dig
            att effektivisera ditt dataskyddsarbete och hjälpa dig att följa de krav som ställs. Läs mer om vår lösning `,
      clickHere: "här",
      dot: ".",
    },
    {
      header: "Arbetsmiljö, Hälsa och Säkerhet på Arbetsplatsen",
      text: `: Vi hjälper dig uppfylla kraven
            på hälsa och säkerhet på arbetsplatsen. Våra tjänster omfattar
            efterlevnadsrevisioner, vägledning vid olycksutredningar, framtagande av olika typer
            av styrdokument och rådgivning i frågor som rör ersättning till arbetstagare.`,
    },
  ],
  content2: [
    {
      header: "Riskanalys och Riskhantering",
      text: `: Vi erbjuder heltäckande tjänster för att identifiera,
            bedöma och minska compliance-risker. Detta innebär att vi genomför interna
            revisioner och tillhandahåller rådgivning om regelverk, skräddarsytt efter era unika
            behov.`,
    },
    {
      header: "Implementering av Lagstadgade Krav",
      text: `: Vi hjälper dig implementera nya eller
            reviderade regelverk. Våra tjänster hjälper dig upprätthålla en verksamhet som
            uppfyller lagkrav och förblir anpassningsbar i en snabbt föränderlig regelmiljö.`,
    },
    {
      header: "Utformning och Implementering av Complianceprogram",
      text: `: Vi är experter på att
            utforma och implementera robusta efterlevnadsprogram. Detta inkluderar att skapa
            interna processer för styrning, rapportering och riskhantering som tar utgångspunkt
            i affären samt som är i linje med företagets mål och lagstadgade krav.`,
    },
    {
      header: "Utredningar och Rapportering",
      text: `: Vi tillhandahåller grundliga utredningstjänster och
            rapportering för att säkerställa transparens och korrekt ansvarsskyldighet inom din
            organisation.`,
    },
    {
      header: "Företrädande i Tvistemål",
      text: ": Vi representerar dig i tvister mot reglerings- och tillsynsmyndigheter i förvaltningsdomstolar och andra rättsliga processer.",
    },
  ],
  content3: [
    {
      header: "Erfarenhet",
      text: `:Vårt team har omfattande expertis inom compliance. Våra jurister har
            arbetat som bolagsjurister i små och medelstora företag, försäkringsbolag och stora
            företag inom olika sektorer, och ger dig alltid lösningsorienterad rådgivning.`,
    },
    {
      header: "Snabb och Kompetent Rådgivning",
      text: `: Vi ger snabb och effektiv rådgivning genom att
            arbeta i små, tätt sammansvetsade team som alltid prioriterar enkla, användbara
            lösningar. `,
    },
    {
      header: "Skräddarsydda Tjänster",
      text: `:  Vi strävar efter att alltid tillhandahålla tjänster
            skräddarsydda efter dina specifika behov och mål. För att uppnå detta är vi alltid
            engagerade i er verksamhet och de utmaningar ni möter.`,
    },
    {
      header: "Samarbete",
      text: `: För att kunna erbjuda er bästa möjliga rådgivning samarbetar vi med
            specialister inom andra områden. Vi strävar efter att arbeta som en integrerad del
            av ert team och att alltid finnas nära till hands för att hålla er informerade och
            uppdaterade från början till slut.`,
    },
  ],
  content4: [
    {
      header: "",
      text: `Våra lösningar för visselblåsning och DSO (dataskyddsombud) erbjuder tillförlitliga, skräddarsydda och
            kostnadseffektiva compliance-tjänster. Vår visselblåsartjänst främjar en transparent arbetsmiljö genom att tillhandahålla en säker kanal för anställda att rapportera problem, samtidigt som den underlättar effektiva utredningar och svar.
            Klicka`,
      clickHere: "här",
      //   dot: ".",
      text2: ` för att läsa mer om vår visselblåsartjänst. Vår DSO-tjänst förenklar efterlevnaden av
            GDPR genom att erbjuda specialiserad rådgivning och proaktiv riskhantering för dina
            dataskyddsbehov. Läs mer om vårt erbjudande
        `,
      clickHere2: "här",
      dot2: ".",
    },
  ],
};
const corporateAndEquityPage = {
  author: "Per Gruwberger",
  mail: "per.gruwberger@rdlaw.se",
  mobileNo: "+46 707 344 641",
  designation: "Partner",
  title:
    "Med omfattande erfarenhet erbjuder vi expertrådgivning inom bolagsrätt till alla typer av",
  titleOrange1: " företag",
  and: " och ",
  titleOrange3: "private equity",
  title2: "-kunder",
  description1: `Vi har stor erfarenhet av alla typer av private equity-ärenden. Vårt team har arbetat med
medelstora företag och stora internationella koncerner som rådgivare och bolagsjurister.
Med denna omfattande erfarenhet kan vi tillhandahålla kostnadseffektiva och
lösningsorienterade råd. Våra tjänster omfattar, bland annat, hantering av
bolagsbildning och struktureringsprojekt, joint ventures, buy-outs, aktieägar- och
styrelsefrågor samt bolagsstyrningsfrågor.`,
  subTitle1: "Omfattande Expertis inom Bolagsrätt",
  subTitle2:
    "Tjänster inom Generationsskiften, Finansiering och Private Equity",
  subTitle3: "Omfattande Erfarenhet och Skräddarsydda Tjänster",
  content1: [
    {
      header: "Bolagsrätt och Bolagsstyrning",
      text: ": Vi erbjuder lösningar på frågor som rör bildande och strukturering av företag, joint ventures och lösning av styrelse- och aktieägartvister. Vi ger även råd kring bolagsstyrning i samband med, till exempel, styrelsearbete och bolagsstämmor.",
    },
    {
      header: "Omstrukturering",
      text: ": Vi har omfattande erfarenhet av omstruktureringar av företag. Våra tjänster omfattar, bland annat, fusioner, avyttringar samt bildande, sammanslagning eller avveckling av dotterbolag, allt i både nationella och internationella sammanhang.",
    },
    {
      header: "En Komplett Tjänst för Företag",
      text: `:  Vi samarbetar nära med andra grupper på vår byrå för
      att erbjuda dig kostnadseffektiva lösningar på dina bolagsrättsliga frågor. Våra tjänster
      omfattar allt från personalöverföringar mellan dotterbolag i internationella koncerner till
      försäljning av enskilda IP-rättigheter. Med expertis inom alla juridiska områden som rör
      företag och private equity, hanterar vi alla dina juridiska frågor på ett effektivt och
      lösningsorienterat sätt.`,
    },
  ],
  content2: [
    {
      header: "Generationsskiften",
      text: `: Vi har omfattande erfarenhet av att hantera generationsskiften i
      både små, medelstora och stora företag. Vi hjälper dig genom hela processen och
      fokuserar på effektiva lösningar, skräddarsydda efter dina önskemål.`,
    },
    {
      header: "Finansiering",
      text: `: Vi erbjuder strategisk rådgivning i frågor som rör finansieringen av ditt
      företag. Våra tjänster omfattar, till exempel, rådgivning om skuldfinansiering,
      insolvensförfaranden och investeringar.`,
    },
    {
      header: "Private Equity",
      text: `: Vi hanterar alla dina private equity-frågor såsom kapitalanskaffning,
      investerarrelationer, förvärv och avyttringar av portföljbolag, management buyouts,
      leveraged buyouts, due-diligence, transaktionsrådgivning och exit-strategier.`,
    },
  ],

  content3: [
    {
      header: "Erfarenhet",
      text: `: Med erfarenhet från att ha arbetat med både stora industrikoncerner och
      PE-aktörer har vårt team omfattande expertkunskaper inom bolagsrätt och private
      equity-transaktioner.`,
    },
    {
      header: "Snabb och Kompetent Rådgivning",
      text: `:  Vi ger snabb och effektiv rådgivning genom att
      arbeta i små, tätt sammansvetsade team som alltid prioriterar enkla, användbara
      lösningar.`,
    },
    {
      header: "Skräddarsydda Tjänster",
      text: `: Vi strävar efter att alltid tillhandahålla tjänster skräddarsydda
      efter dina specifika behov och mål. För att uppnå detta är vi alltid engagerade i er
      verksamhet och de utmaningar ni möter.`,
    },
    {
      header: "Samarbete",
      text: `: För att kunna erbjuda er bästa möjliga rådgivning samarbetar vi med
      specialister inom andra områden. Vi strävar efter att arbeta som en integrerad del av ert
      team och att alltid finnas nära till hands för att hålla er informerade och uppdaterade
      från början till slut.`,
    },
  ],
};

const startupGrowthAndVenturesPage = {
  author: "Carolina Thoft",
  mail: "carolina.thoft@rdlaw.se",
  mobileNo: "+46 705 556 679",
  designation: "Partner, Kontorschef, Helsingborg",
  title: "Med omfattande erfarenhet erbjuder vi expertrådgivning till",
  titleOrange1: " startups ",
  title2: " i alla tillväxtfaser",
  description1: `Startup, Growth & Ventures erbjuder specialiserad juridisk rådgivning till entreprenörer
och bolag som befinner sig i olika uppstarts- och tillväxtfaser. Vi arbetar även nära
investerarsidan och erbjuder rådgivning till VC-bolag, affärsänglar och family offices. Vi
har ett särskilt erbjudande för startups som innebär att vi på ett effektivt och anpassat
sätt hanterar den legala strukturen från början. Som legal partner till Sting – Stockholm
Innovation & Growth, HETCH, E-commerce Park of Sweden, Företagsfabriken i
Kronoberg och Kalmar Science Park, samt som rådgivare till startups inom flera olika
branscher har vi nätverket, kunskapen och erfarenheten som krävs för att skräddarsy vår
juridiska rådgivning utifrån alla startups unika utmaningar, behov och målsättningar.`,
  subTitle1: "Från idé till exit",
  subTitle2: "Kapitalanskaffningar",
  subTitle3: "Rätt stöd genom varje tillväxtfas",
  content1: [
    {
      text: "Varje fas i ett bolags tillväxtresa har sina unika juridiska utmaningar. Vi är med hela vägen. Från grundandet av bolaget till exit-processen ger vi anpassad och värdeskapande rådgivning i varje steg.",
    },
  ],
  content2: [
    {
      text: `Kapitalanskaffningsprocesser är något som de flesta startups och scaleups går igenom.
      Med erfarenhet från både investerar- och entreprenörsidan vet vi vad som krävs för att
      göra bolag ”investor ready” och genomföra kapitalanskaffningar. Vi är med bolaget i
      varje finansieringsrunda, från pre-seed till exit, och har förståelse för
      finansieringsrundornas olika kravställningar på bolaget. `,
    },
  ],

  content3: [
    {
      text: "Expertis och förståelse för startupvärlden gör oss till en pålitlig partner. Vi vet att det i tillväxtfaser handlar om att prioritera rätt för att växa in i nästa steg på bolagets resa. Vårt team hjälper entreprenörer att navigera bolagets juridiska behov så att fokus kan läggas på bolagets och affärens utveckling.",
    },
  ],
};

const environmentalPage = {
  author: "Daniel Drott",
  mail: "daniel.drott@rdlaw.se",
  mobileNo: "+46 702 088 275",
  designation: "Specialist Counsel",
  title:
    "Med omfattande erfarenhet och teknisk expertis erbjuder vi strategisk, användbar och praktisk rådgivning inom",
  titleOrange1: " miljörätt",
  description1: `Våra tjänster omfattar navigering av komplexa regelverk, tillståndsprocesser,
    hantering av miljörisker samt vägledning i frågor som rör förorenad mark. Vi ger
    även råd kring återvinning och cirkulär ekonomi, samt hantering av miljöfrågor
    kopplat till transaktioner, samarbeten och partnerskap. Med vårt engagemang för
    att hitta enkla lösningar som passar din verksamhet och våra djupa tekniska insikter
    hjälper vi dig uppnå dina mål samtidigt som vi säkerställer efterlevnad av gällande
    regelverk.`,
  subTitle1: "Vår Bredd",
  subTitle2: "Lång Erfarenhet och Skräddarsydda Tjänster",
  content1: [
    {
      header: "Hjälp med Tillstånd och Dispenser",
      text: ": Vårt team hjälper dig erhålla tillstånd och dispenser för verksamheter som regleras av miljöbalken. Det gäller, till exempel, tillstånd som krävs för vattenverksamheter, industriproduktion, avfallshantering, hantering av avloppsvatten samt vind- eller solparker.",
    },
    {
      header: "Miljöriskbedömningar",
      text: ": Vi gör djupgående utvärderingar av miljöriskerna i din verksamhet och tar fram skräddarsydda riskhanteringslösningar.",
    },
    {
      header: "Förorenad Mark",
      text: ": Vi ger stöd i juridiska frågor som rör förorenad mark och sanering. Våra tjänster omfattar, till exempel, ansvarsbedömning, rättstvister och säkerställande av efterlevnad av relevanta miljöbestämmelser.",
    },
    {
      header: "Återvinning och Cirkulär Ekonomi",
      text: ": Vårt team hanterar frågor som rör avfallshantering, återvinning, energiåtervinning och cirkulär ekonomi. Vi ger dig råd i frågor kring efterlevnad av bestämmelser som omfattar områden som biprodukter och gränsöverskridande transporter.",
    },
    {
      header: "Juridiska och Regulatoriska Frågor",
      text: ": Vårt team hjälper dig hantera utmanande juridiska och regulatoriska frågor som, till exempel, förelägganden från tillsynsmyndigheter samt miljööverträdelser och olyckor. Vi företräder även dig i domstolsförfaranden.",
    },
    {
      header: "Transaktioner och Due Diligence",
      text: ": Vi hjälper till med miljörättslig due diligence i affärstransaktioner, fastighetsaffärer och andra projekt. Detta inkluderar hantering av miljörisker i olika avtal i syfte att skydda dina intressen.",
    },
  ],
  content2: [
    {
      header: "Erfarenhet",
      text: ": Våra jurister har omfattande erfarenhet av arbete med komplexa frågor inom miljörätt. Denna erfarenhet och expertis kombineras effektivt med teknisk kunskap som härrör från arbete inom olika affärsområden och tillsammans med många av de ledande tekniska rådgivare som är etablerade i Sverige och internationellt.",
    },
    {
      header: "Snabb och Kompetent Rådgivning",
      text: ": Vi ger snabb och effektiv rådgivning genom att arbeta i små, tätt sammansvetsade team som alltid prioriterar enkla, användbara lösningar.",
    },
    {
      header: "Skräddarsydda Tjänster",
      text: ": Vi strävar efter att alltid tillhandahålla tjänster skräddarsydda efter dina specifika behov och mål. För att uppnå detta är vi alltid engagerade i er verksamhet och de utmaningar ni möter.",
    },
    {
      header: "Samarbete",
      text: ": För att kunna erbjuda er bästa möjliga rådgivning samarbetar vi med specialister inom andra områden. Vi strävar efter att arbeta som en integrerad del av ert team och att alltid finnas nära till hands för att hålla er informerade och uppdaterade från början till slut.",
    },
  ],
};

const employmentPage = {
  author: "Kerstin Eifrém",
  mail: "kerstin.eifrem@rdlaw.se",
  mobileNo: "+46 709 795 140",
  designation: "Associate",
  title: "Vi är specialiserade på ",
  titleOrange1: " kommersiell arbetsrätt",
  title2:
    " och fokuserar på snabba,  skräddarsydda tjänster, anpassade efter dina behov",
  description1: `Våra tjänster omfattar allt från allmänna frågor kopplade till anställningar, till expertvägledning i transaktioner, tvister och omorganisationer. Vi ger även råd i
    frågor som rör, till exempel, inledande och avslutande av anställningar, skydd av
    företagshemligheter, fackliga förhandlingar, pensions- och försäkringsfrågor,
    incitamentsprogram, arbetsmiljö och utredningar av hälsa och säkerhet på
    arbetsplatsen.`,
  subTitle1: "Anställa, Kompensera, Säga Upp",
  subTitle2: "Ledning, Förhandlingar och Tvister",
  subTitle3: "Lång Erfarenhet och Skräddarsydda Tjänster",
  subTitle4: "Steget Före med ELaaS",
  content1: [
    {
      header: "Påbörjande och Avslutande av Anställning",
      text: ":  Vi hjälper dig följa relevanta lagar och förordningar när du anställer och säger upp medarbetare. Vi hjälper till med anställningsavtal, ersättning, IP-rättigheter, klausuler som rör värvningsförbud och konkurrerande verksamhet. Vid uppsägning av anställda hjälper vi dig med, till exempel, uppsägningsgrunder, förhandling om arbetsrättsliga regler, avgångsvederlag och sekretess.",
    },
    {
      header: "Utstationering, Bemanning och Konsulter",
      text: ": Vi ger dig råd i samtliga frågor som rör bemanning. Vi hjälper dig med att, till exempel, anlita konsulter, utstationera anställda och anlita personal via bemanningsföretag.",
    },
    {
      header: "Skydd för Företagshemligheter och Konkurrensklausuler",
      text: ": Vi hjälper dig skydda ditt företags immateriella rättigheter och företagshemligheter i förhållande till anställda. Våra tjänster omfattar att skapa och implementera policyer samt att utarbeta och granska sekretessavtal och avtal om tystnadsplikt.",
    },
    {
      header: "Pension och Försäkring",
      text: ":  Vi vägleder dig i pensions- och försäkringsfrågor. Vi hjälper dig utforma och implementera pensionssystem, rådger dig kring försäkringar och hjälper dig säkerställa efterlevnad av relevanta lagar och förordningar.",
    },
    {
      header: "Incitamentsprogram och Ersättningar",
      text: ": Vi hjälper dig utveckla incitamentsprogram för anställda, såsom aktieoptioner, restricted stock units, kvalificerade personaloptioner, prestationsbonusar och planer för uppskjuten ersättning.",
    },
  ],
  content2: [
    {
      header: "Omorganisationer och Omstruktureringar",
      text: ": Vi vägleder dig genom omstrukturering av ditt företag, inklusive verksamhetsförändringar och personalhantering. Våra tjänster inom detta område omfattar, till exempel, övergångsplaner och uppsägningsförfaranden.",
    },
    {
      header: "Kollektiv Arbetsrätt och Fackliga Förhandlingar",
      text: ": Vi företräder er i förhandlingar med fackföreningar i frågor som rör, till exempel, omfattande uppsägningar av anställda, organisatoriska omstruktureringar, tvister, arbetsvillkor och branschövergripande förändringar av anställningsavtal. Vidare hjälper vi er med alla frågor som rör kollektivavtal, inklusive att genomföra lönekartläggningar och förhandla om lokala kollektivavtal.",
    },
    {
      header: "Utredningar av Arbetsmiljö, Hälsa och Säkerhet",
      text: ": Vi erbjuder vägledning vid utredningar av arbetsmiljö och hjälper dig effektivt att upprätthålla en säker arbetsmiljö som uppfyller samtliga krav. Våra tjänster omfattar utarbetande av styrdokument och policyer, genomförande av utredningar, rådgivning om olycksförebyggande strategier och säkerställande av efterlevnad av säkerhetsbestämmelser på arbetsplatsen. Vi erbjuder även juridiskt stöd i samband med utredning, hantering och förebyggande av exempelvis diskriminering, trakasserier och kränkande behandling på arbetsplatsen.",
    },
    {
      header: "Hantering av Regelefterlevnad",
      text: ": Vi hjälper dig att följa alla typer av regler som rör arbetsrätt. Vi ger dig råd i exempelvis GDPR-relaterade frågor, hantering och lagring av information om anställda och kameraövervakning.",
    },
    {
      header: "Arbetsrätt och Företagsöverlåtelser",
      text: ":  Vi hjälper dig med samtliga arbetsrättsliga frågor som uppkommer i samband med verksamhetsövergångar, fusioner och förvärv. Vi ger råd i frågor som rör, till exempel, due diligence, verksamhetsövergångar, informationsskyldighet, kollektivavtal, omorganiseringar,uppsägningar, nyanställningar och att behålla nyckelpersoner. Vi hjälper även till vid övergångar i samband med offentlig upphandling.",
    },
    {
      header: "Verksamhetsövergångar",
      text: ":  Vårt team vägleder dig genom anställningsfrågor i samband med verksamhetsövergångar. Vi hjälper dig säga upp, och anställa nya medarbetare.  ",
    },
    {
      header: "Arbetstvister och Skiljeförfaranden",
      text: ":  Vi företräder dig i anställningstvister som rör, till exempel, illegitima uppsägningar, diskriminering, repressalier eller löneanspråk. Våra tjänster omfattar även rådgivning kring alternativa strategier för tvistlösning och att företräda dig i medlings- och skiljeförfaranden.",
    },
  ],
  content3: [
    {
      header: "Erfarenhet",
      text: ": Vårt team av specialister har lång erfarenhet av kommersiell arbetsrätt och är redo att hjälpa dig med alla juridiska aspekter kring ditt ärende.",
    },
    {
      header: "Snabb och Kompetent Rådgivning",
      text: ":  Vi ger snabb och effektiv rådgivning genom att arbeta i fokuserade team som alltid prioriterar enkla, användbara lösningar. ",
    },
    {
      header: "Skräddarsydda Tjänster",
      text: ": Vi strävar efter att alltid tillhandahålla tjänster skräddarsydda efter dina specifika behov och mål. För att uppnå detta är vi alltid engagerade i er verksamhet och de utmaningar ni möter. ",
    },
    {
      header: "Samarbete",
      text: ": För att kunna erbjuda er bästa möjliga rådgivning samarbetar vi med specialister inom andra områden. Vi strävar efter att arbeta som en integrerad del av ert team och att alltid finnas nära till hands för att hålla er informerade och uppdaterade från början till slut.",
    },
  ],
  content4: [
    {
      header: "Stärka Kunderna med ELaaS",
      text: ": Vi visar vårt engagemang och vår expertis inom arbetsrätt genom att erbjuda Employment Law as a Service (ELaaS) - en heltäckande, anpassad och kostnadseffektiv lösning som hjälper dig att hantera arbetsrättsliga frågor. ELaaS täcker en rad olika områden, inklusive anställningsavtal, stöd för regelverk, incitamentsprogram, medarbetarrelationer, utbildning och riskhantering. Läs mer om hur ELaaS kan lyfta din verksamhet ",
      clickHere: "här",
      dot: ".",
    },
  ],
};

const mergersAndAcquisitionsPage = {
  author: "Anders Rosholm",
  mail: "anders.rosholm@rdlaw.se",
  mobileNo: "+46 707 344 661",
  designation: "Partner, Responsible Växjö office",
  title: "Med omfattande erfarenhet inom ",
  titleOrange1: "M&A",
  dot: " erbjuder vi resultatinriktad rådgivning för effektiva transaktioner ",
  description1: `Vi är specialiserade på att tillhandahålla effektiv rådgivning inom M&A till små och
    medelstora företag. Våra tjänster sträcker sig från att hantera företagsöverlåtelser
    till att vägleda dig genom processerna i, till exempel, strategiska förvärv,
    avyttringar och omorganisationer. Vi hjälper er med due-diligence, förhandlingar
    och förberedelser av dokument såsom avsiktsförklaringar, aktieöverlåtelseavtal,
    budbrev, investerings- eller försäljningsteasers och aktieägaravtal. Vi arbetar i små,
    sammansvetsade team och strävar alltid efter effektivitet och enkla lösningar.`,
  subTitle1: "Våra Tjänster",
  subTitle2: "Lång Erfarenhet och Skräddarsydda Tjänster",
  content1: [
    {
      header: "Fusioner, Förvärv och Försäljningsprocesser",
      text: ": I syfte att skapa en effektiv transaktionsprocess tillhandahåller vi tjänster och produkter som omfattar hela transaktionen. Vår rådgivning innefattar, bland annat:",
      points: [
        "- Due diligence och rapportering",
        "- Förhandlingar",
        "- Avsiktsförklaringar ",
        "- Aktieöverlåtelseavtal",
        "- Sekretessavtal ",
        "- Aktieägaravtal ",
        "- Tilläggsavtal",
        "- Anmälningar till tillsynsmyndigheter ",
      ],
      text2:
        "Vi erbjuder även projektledning, hantering av auktionsprocesser och utarbetande av exempelvis teasers och prospekt. ",
    },
    {
      header: "Försäljningsprocesser",
      text: ": Vi vägleder dig genom strategiska processer. Våra tjänster omfattar planering och genomförande av förvärvs och försäljningsprocesser, due diligence samt stöd i uppföljande arbetet efter transaktionen.",
    },
    {
      header: "Omstruktureringar",
      text: ": Vid omstruktureringar av företag hjälper vi dig med strategisk planering, förhandling av villkor med intressenter och hantering av samtliga juridiska aspekter av omstruktureringen. Vi vägleder dig genom hela omorganisationsprojektet och hjälper dig följa relevant regelverk.",
    },
    {
      header: "Incitamentsprogram",
      text: ": Vi hjälper dig utforma och implementera incitamentsprogram för anställda. Våra tjänster omfattar, bland annat, hantering av juridiska frågor och förberedelse av tillhörande dokumentation såsom individuella anställningsavtal och företagspolicyer.",
    },
  ],
  content2: [
    {
      header: "Erfarenhet",
      text: ": Vårt team har över 20 års erfarenhet av hantering av M&A-projekt. Vi vägleder dig genom transaktioner, oavsett komplexitet, i både nationella och internationella sammanhang. Vi har erfarenhet av att arbeta med en bred kundbas, såsom startups, små och medelstora företag, industriföretag, private equity-bolag och globala industrigrupper. Vårt team har erfarenhet, både som rådgivare, och som interna projektledare i ett brett spektrum av företag och sektorer.",
    },
    {
      header: "Identifiering och Begränsning av Risker",
      text: ":  En viktig del av våra tjänster är att  identifiera potentiella transaktionsrisker. Med hjälp av vår omfattande erfarenhet kan vi proaktivt identifiera risker och hjälpa dig att hantera och minska dem.",
    },
    {
      header: "Snabb och Kompetent Rådgivning",
      text: ": Vi ger snabb och effektiv rådgivning genom att arbeta i små, tätt sammansvetsade team som alltid prioriterar enkla, användbara lösningar.",
    },
    {
      header: "Skräddarsydda Tjänster",
      text: ": Vi strävar efter att alltid tillhandahålla tjänster skräddarsydda efter dina specifika behov och mål. För att uppnå detta är vi alltid engagerade i er verksamhet och de utmaningar ni möter.",
    },
    {
      header: "Samarbete",
      text: ":  För att kunna erbjuda er bästa möjliga rådgivning samarbetar vi med  specialister inom andra områden. Vi strävar efter att arbeta som en integrerad del av ert team och att alltid finnas nära till hands för att hålla er informerade och uppdaterade från början till slut.",
    },
    {
      header: "Ett Komplett M&A-Team",
      text: ": Genom att utnyttja expertisen inom vår egen byrå hjälper vi dig med alla juridiska frågor som uppstår i samband med din transaktion. Detta inkluderar, bland annat, hantering av frågor som rör arbetsrätt, miljö, compliance och fastighetsrätt.",
    },
  ],
};
const publicProcurementPage = {
  author: "Peter Lennartz",
  mail: "peter.lennartz@rdlaw.se",
  mobileNo: "+46 709 915 912",
  designation: "Specialist Counsel, Responsible Kalmar Office",
  title: ", med kundens resultat i fokus",
  title2: "Skräddarsydd rådgivning inom ",
  titleOrange1: "offentlig upphandling",

  description1: `Med omfattande erfarenhet bistår vi både upphandlande myndigheter och
  leverantörer i alla frågor som rör offentlig upphandling. Våra tjänster omfattar
  överprövningsförfaranden i förvaltningsdomstolar, arbete med
  förfrågningsunderlag, anbudshandlingar, frågor- och svar-förfaranden, utlämnande
  av offentlig information och mycket mer. Vi har en bred kundbas bestående av både
  offentliga och privata aktörer. Genom vår kompetens och erfarenheter inom
  området erbjuder vi effektiv och lösningsorienterad rådgivning.`,
  subTitle1: `Vår Omfattande Expertis inom Upphandling`,
  subTitle2: `Omfattande Erfarenhet och Skräddarsydda Tjänster`,
  content1: [
    {
      header: "LOU och LUF-specialister",
      text: `: Vår verksamhetsgrupp vägleder er i frågor kring lagen om
      offentlig upphandling (LOU), lagen om upphandling inom försörjningssektorerna
      (LUF) samt EU-relaterad lagstiftning.`,
    },
    {
      header: "Förfrågningsunderlag, Anbud och Utvärdering",
      text: `: Vi hjälper er förbereda
      förfrågningsunderlag och anbud, värdera samt gå igenom inkomna anbud. Vi kan
      även bistå i exempelvis konkurrenspräglade dialoger och andra
      upphandlingsförfaranden.`,
    },
    {
      header: "Hantering av Upphandlingar",
      text: `: Vi hjälper er granska, justera och hantera alla era
      upphandlingsdokument och säkerställa att allt följer gällande lagstiftning.`,
    },
    {
      header: "Sekretess och Offentlig Information",
      text: `: Vi ger er rådgivning inom områden som berör
      offentlig information och sekretess. Vi hjälper er med, bland annat, skydd av känslig
      information och processer kopplade till frågor om sekretess och utlämnande av
      offentlig information.`,
    },
    {
      header: "Domstolsprocesser",
      text: `: Vi erbjuder råd till leverantörer och upphandlande myndigheter
      i överprövningsförfaranden avseende offentlig upphandling. Vi har gedigen
      erfarenhet från hantering av processer i alla instanser. Vi erbjuder även strategisk
      vägledning i skadeståndstvister och förfaranden i allmänna domstolar som rör
      offentlig upphandling.`,
    },
  ],
  content2: [
    {
      header: "Stor Erfarenhet",
      text: `: Vår kompetens och expertis är grundad på omfattande erfarenhet
      inom offentlig upphandling, där vi har verkat som rådgivare i några av de mest
      betydande upphandlingarna i Sverige på bland annat kollektivtrafikområdet.`,
    },
    {
      header: "Identifiering och Begränsning av Risker",
      text: `: Våra tjänster är inriktade på att identifiera
      och minimera risker relaterade till offentlig upphandling. Med vår erfarenhet kan vi
      effektivt hantera dessa risker och hjälpa er formulera strategier för att bemöta dem.`,
    },
    {
      header: "Snabb och Kompetent Rådgivning",
      text: `:  Vi ger snabb och effektiv rådgivning genom att
      arbeta i små, tätt sammansvetsade team som alltid prioriterar enkla, användbara
      lösningar.`,
    },
    {
      header: "Skräddarsydda Tjänster",
      text: `: Vi strävar efter att alltid tillhandahålla tjänster
      skräddarsydda efter dina specifika behov och mål. För att uppnå detta är vi alltid
      engagerade i er verksamhet och de utmaningar ni möter`,
    },
    {
      header: "Samarbete",
      text: `: För att kunna erbjuda er bästa möjliga rådgivning samarbetar vi med
      specialister inom andra områden. Vi strävar efter att arbeta som en integrerad del
      av ert team och att alltid finnas nära till hands för att hålla er informerade och
      uppdaterade från början till slut.`,
    },
  ],
};

const railwayAndPublicTransportPage = {
  author: "Martin Dell",
  mail: "martin.dell@rdlaw.se",
  mobileNo: "+46 708 344 660",
  designation: "Partner",
  title: "Vi erbjuder branschledande legal expertis inom ",
  titleOrange1: " järnväg",
  and: " och ",
  titleOrange2: "kollektivtrafik",
  description1: `Med över 20 års erfarenhet erbjuder vi expertrådgivning och strategisk rådgivning
    inom bland annat offentlig upphandling av transporttjänster, transaktioner
    avseende rolling stock, godstransportavtal, infrastrukturprojekt och andra
    branschspecifika legala utmaningar. Vi erbjuder resultatorienterade, snabba och
    praktiska lösningar som har befäst vårt rykte som en av Sveriges främsta
    advokatbyråer inom järnväg och kollektivtrafik.`,
  subTitle1: "Fullservice inom Järnväg och Kollektivtrafik",
  subTitle2: "Omfattande Erfarenhet och Skräddarsydda Råd",
  content1: [
    {
      header: "Kollektivtrafiksupphandling",
      text: `: Vi erbjuder rådgivning inom samtliga skeden av
            kollektivtrafiksupphandlingar för både kollektivtrafikmyndigheter och operatörer.`,
    },
    {
      header: "Rolling Stock-Transaktioner",
      text: `: Vi tillhandahåller expertrådgivning inom inköp,
            underhåll, renovering, finansiering och leasing av lokomotiv, passagerarfordon och
            godsvagnar. Vi hanterar även tvister relaterade till transaktioner av rullande
            materiel.`,
    },
    {
      header: "Godstransport",
      text: `:  Vi hjälper till att upphandla kort- och långsiktiga kontrakt avseende
            transport av olika varor, både till järnvägsföretag och uppdragsgivare.`,
    },
    {
      header: "Infrastrukturprojekt",
      text: `: Vi hjälper dig med infrastrukturprojekt som rör järnväg och
            kollektivtrafik och säkerställer regelefterlevnad relaterat till förbättringar av spår,
            ERTMS-installationer, terminaler och utrustning. `,
    },
    {
      header: "Branschspecifika Frågor",
      text: `: Vi hanterar ett brett spektrum av legala utmaningar,
            inklusive garantianspråk, tillstånd, avgifter, olyckor, anställnings- och
            fastighetsfrågor.`,
    },
  ],
  content2: [
    {
      header: "Erfarenhet",
      text: ": Vår erfarenhet är omfattande och omfattar nästan alla upphandlingar av järnvägstransporter och många stora upphandlingar av busstransporter i Sverige sedan 2001.",
    },
    {
      header: "Snabb och Kompetent Rådgivning",
      text: ": Vi ger snabb och effektiv rådgivning genom att arbeta i små, tätt sammansvetsade team som alltid prioriterar enkla, användbara lösningar. ",
    },
    {
      header: "Skräddarsydda Tjänster",
      text: ": Vi strävar efter att alltid tillhandahålla tjänster skräddarsydda efter dina specifika behov och mål. För att uppnå detta är vi alltid engagerade i er verksamhet och de utmaningar ni möter. ",
    },
    {
      header: "Samarbete",
      text: ": För att kunna erbjuda er bästa möjliga rådgivning samarbetar vi med  specialister inom andra områden. Vi strävar efter att arbeta som en integrerad del av ert team och att alltid finnas nära till hands för att hålla er informerade och uppdaterade från början till slut.",
    },
  ],
};
const realEstateAndConstructionPage = {
  author: "Susanna Kihlberg",
  mail: "susanna.kihlberg@rdlaw.se",
  mobileNo: "+46 733 311 646",
  designation: "Partner",
  title: "Med specialisering inom ",
  titleOrange1: " fastighets-,",
  //   titleColon: ",",
  titleOrange2: " hyres- och entreprenadrätt",
  //   and: ", and ",
  //   titleOrange3: "construction law",
  title2:
    ", erbjuder vi skräddarsydd rådgivning under hela fastighetens livscykel — från markförvärv till avyttring",
  description1: `Med över 20 års erfarenhet hjälper vi till i alla frågor kopplat till fastighets-, hyres -och entreprenadrätt. Vår expertis omfattar bland annat fastighetstransaktioner,
  markförvaltning, fastighetsutveckling, hyra av kommersiella fastigheter och
  bostäder, äganderätt, byggprojekt och tvistlösning. Vi har en bred kundbas
  bestående av fastighetsutvecklare, fastighetsägare, offentliga aktörer och
  hyresgäster. Genom vår expertis och djupa branschkunskap kan vi tillhandahålla
  bästa möjliga rådgivning till våra kunder`,
  subTitle1: "Fastighetsutveckling och Entreprenad",
  subTitle2: "Transaktioner och Finansiering",
  subTitle3: "Fastighetsförvaltning",
  subTitle4: "Ett Team för Hela Fastighetens Livscykel",
  content1: [
    {
      header: "Fastighetsbildning och Markförvaltning",
      text: `: Vi hjälper er med detaljplaner, bygglov,
      markintrång och fastighetsbildning. Vi hanterar inlämning av ansökningar, kontakt
      med tillsynsmyndigheter och alla andra juridiska processer som rör bygg-, mark- och
      miljötillstånd`,
    },
    {
      header: "Fastighetsutveckling",
      text: `: Vi hjälper er med de juridiska aspekterna av era
      fastighetsutvecklingsprojekt, såsom upprättande av utvecklingsavtal och frågor
      kring efterlevnad av exempelvis PBL.`,
    },
    {
      header: "Entreprenad",
      text: `: Vi ger er rådgivning före, under och efter era entreprenadprojekt. Vi
      assisterar er, bland annat, i att hantera frågor kring olika standardavtal, utförande- och totalentreprenader och riskfördelning. Våra tjänster omfattar, bland annat,
      upprättande och granskning av förfrågningsunderlag till upphandlingar, mät- och
      ersättningsregler (MER), tolkning av ändrade och tillkommande arbeten (ÄTA) och hantering av kontraktsrelationer under hela projektet.`,
    },
  ],
  content2: [
    {
      header: "Förvärv och Försäljning",
      text: `:  Vi vägleder er genom hela transaktionsprocessen avseende
      både fastighetsbolag och fastigheter, inklusive försäljnings- och lease-back-transaktioner. Vårt team hjälper till med, till exempel, due diligence, förhandlingar,
      framtagande och genomförande av köp- eller försäljningsavtal samt nödvändiga
      myndighetsansökningar.`,
    },
    {
      header: "Finansiering och Säkerhet för Fastigheter",
      text: `: Vi erbjuder rådgivning i finansierings- och
      säkerhetsfrågor. Vår expertis innefattar, bland annat, strukturering av finansiella
      transaktioner, upprättande av låne- och hypoteksdokument samt hantering av
      juridiska frågor rörande säkerheter.`,
    },
  ],
  content3: [
    {
      header: "Kommersiella Hyresavtal och Uthyrning av Bostäder",
      text: `: Vi hjälper er i alla frågor
      kopplat till kommersiella hyresavtal, bostadsuthyrning och andra associerade
      juridiska aspekter. Våra tjänster omfattar utformning av hyresavtal,
      förhandlingsprocesser, frågor kring säkerheter, andrahandsuthyrning, processer vid
      vräkning samt uppsägningar.`,
    },
    {
      header: "Nyttjanderätter, Arrenden och Servitut",
      text: `: Vår expertis omfattar ett brett spektrum av
      egendomsrättsliga frågor, med särskild inriktning på nyttjanderätter, markarrenden
      och servitut. Vi assisterar er vid upprättande och bevarande av äganderättsliga
      intressen och guidar er genom hela processen – från avtalsutformning till
      förhandlingar och genomförande.`,
    },
  ],

  content4: [
    {
      header: "Stor Erfarenhet",
      text: `: Vår kunskap och expertis grundar sig i över 20 års erfarenheter av
      arbete som rådgivare, bolagsjurister och domare.`,
    },
    {
      header: "Bred Kundbas",
      text: `: Vår kundkrets sträcker sig från fastighetsägare, fastighetsutvecklare,
      och förvaltare. Våra kunder är både privata och offentliga aktörer såsom kommuner
      och regioner. Vi företräder också ett stort antal hyresgäster verksamma inom en
      lång rad sektorer. Denna breda kundbas gör det möjligt för oss att anpassa oss till
      olika förutsättningar och effektivt tillgodose era specifika behov.`,
    },
    {
      header: "Samarbete",
      text: `: För att kunna erbjuda er bästa möjliga rådgivning samarbetar vi med
      specialister inom andra områden. Vi strävar efter att arbeta som en integrerad del
      av ert team och att alltid finnas nära till hands för att hålla er informerade och
      uppdaterade från början till slut.`,
    },
    {
      header: "Unik Branschkunskap",
      text: `:  Genom att aktivt bidra till utvecklingen av branschens
      standardavtal håller vi oss i framkant när det gäller tillämpning och best-practice.
      Vårt engagemang innebär också en hög servicenivå gentemot våra kunder genom
      en stor förståelse för kundens affär och fokus på användbarhet i den juridik vi
      levererar.`,
    },
  ],
};

const whistleBlowingSolutionPage = {
  title: "Främja öppenhet och värna om integritet: Implementera vår",
  orangeText: " Visselblåsartjänst",
  titleDesc:
    " Visselblåsning har blivit ett ovärderligt verktyg för att skapa förtroende inom organisationer. Genom att erbjuda en trygg kanal där medarbetare kan rapportera om missförhållanden, uppmuntras öppenhet och ärlighet. Vår visselblåsartjänst anpassas efter era behov och bidrar till en transparent företagskultur. Detta skapar i sin tur en trygg arbetsmiljö där ni upprätthåller era medarbetares förtroende. ",
  ourwhistleBlowingSolution: "Vi har Lösningen ",
  whistleblowingDescOne:
    "Vi tillhandahåller en tjänst som hanterar hela processen kring visselblåsningar. Vi hjälper er med att effektivt hantera rapporterna och genomföra eventuella nödvändiga utredningar på arbetsplatsen och inom organisationen. Vår visselblåsartjänst är designad för att ni enkelt ska kunna uppfylla relevanta lagkrav och samtidigt säkerställa en transparent arbetsmiljö. Tjänsten är användarvänlig och utformad för att enkelt integreras i din organisations befintliga strukturer. ",
  whistleblowingDescTwo: "",
  whistleblowingDescThree:
    ": Från och med 2021 har EU infört ett rättsligt ramverk som är utformat för att skydda och uppmuntra rapportering av missförhållanden och lagöverträdelser inom företag och organisationer. Detta ramverk innebär nya skyldigheter för både offentliga och privata organisationer, särskilt vad gäller visselblåsning och skydd av visselblåsare. Organisationer är nu skyldiga att tillhandahålla information om visselblåsning och att inrätta processer för att hantera rapporter från visselblåsare. Arbetsgivare med över 50 anställda måste dessutom införa en visselblåsarkanal för att möjliggöra regelefterlevnad.",
  legalFramework: "Det Rättsliga Ramverket",
  weprovide: "Vi Tillhandahåller ",
  weprovideDesc:
    "En heltäckande och digital visselblåsartjänst som gör det möjligt för er organisation att följa gällande rättsliga ramar. Vår tjänst möjliggör att:",
  weprovidePoints: {
    one: "Visselblåsare kan lämna rapporter via ett webbformulär, telefon eller digitala/fysiska möten.",
    two: "Visselblåsare har möjlighet att vara helt anonyma.",
    three:
      "Visselblåsare kan aktivt följa hanteringen av sitt rapporterade ärende och kommunicera med vårt team av experter.",
    four: "Rapporterna granskas, registreras och omvandlas till strukturerade sammanfattningar för effektiv utredning. ",
    five: "Rapporter hanteras inom fastställda tidsramar.",
    six: "Vårt team av experter ger råd och vidtar nödvändiga åtgärder baserat på rapporter och utredningar.",
    seven:
      "Alla inkommande rapporter och svar loggas elektroniskt för att underlätta efterföljande utredningar med en elektronisk verifieringskedja.",
    eight:
      "Vi erbjuder effektiva externa utredningstjänster i samband med visselblåsarrapporter.",
  },
};
const employmentLawAsaServicePage = {
  title: "Stärk din organisation med vår skräddarsydda arbetsrättslösning:",
  orangeText: ` Employment Law as a Service`,
  title2: ". Skapad för tillförlitlig och effektiv personalhantering.",
  titleDesc: `Vi förstår att hanteringen av anställningsfrågor kan vara komplex och tidskrävande för företag av alla storlekar. För att kunna hjälpa våra kunder på ett effektivt sätt har vi utvecklat en lösning: Employment Law as a Service (ELaaS). ELaaS erbjuder ett heltäckande, anpassat och  kostnadseffektivt legalt stöd som hjälper dig följa rådande regelverk i dagens ständigt föränderliga arbetsrättsliga landskap. `,
  elaasIncludes: "ElaaS Omfattar",
  benefitsOfElaas: "Fördelar med ELaaS",
  experianceElaas: "Ready to experience ELaaS?",
  includesPoints: {
    one: "Med vår lösning får du tillgång till kostnadseffektiva tjänster som tillhandahålls av vårt team av engagerade arbetsrättsjurister. Teamet förser dig med kvalitetsprodukter och den högsta nivån av  expertis inom följande områden: ",
    two: {
      title: "Anställningskontrakt och Policys",
      desc: ": Tillgång till ett antal mallar, till exempel anställningsavtal, personalhandböcker och interna policyer. ",
    },
    three: {
      title: "Stöd för Efterlevnad av Regelverk",
      desc: ": Vi hjälper ditt företag med arbetsrättsliga frågor som, till exempel, löne- och timbestämmelser, diskrimineringslagar, arbetsmiljö och ledighetspolicyer.",
    },
    four: {
      title: "Incitamentsprogram",
      desc: ": Vi hjälper dig att utforma, implementera och hantera skräddarsydda incitamentsprogram. Detta omfattar, bland annat, aktieoptionsplaner, kvalificerade aktieoptionsplaner för anställda, RSU:er (restricted stock units) och ESPP:er (employee stock purchase plans), för att effektivt motivera och belöna dina medarbetare. Vi hjälper dig även skapa, implementera och hantera provisions- och bonusprogram.",
    },
    five: {
      title: "Medarbetarrelationer och Tvistlösning",
      desc: ": Vårt team tillhandahåller expertrådgivning vid hantering av klagomål från anställda, disciplinära åtgärder och arbetsplatsutredningar.",
    },
    six: {
      title: "Kollektivavtalsförhandlingar",
      desc: `: Vi biträder i frågor kopplat till arbetstagare, fackföreningar och
      kollektivavtalsfrågor. Vi hjälper dig före, under och etier förhandlingar, inom alla typer av sektorer
      och branscher.  `,
    },
    seven: {
      title: "Utbildning",
      desc: ": Vi erbjuder skräddarsydda utbildningsprogram i olika arbetsrätsliga ämnen som, till  exempel, förebyggande av sexuella trakasserier, mångfald, inkludering samt hantering av anställdas prestationer. ",
    },
    eight: {
      title: "Riskhantering och Revision",
      desc: `: Vi hjälper dig genomföra omfatande revisioner för att bedöma
      företagets etierlevnad av gällande lagstitining och identifiera potentiella riskområden, så att du
      proaktivt kan hantera potentiella arbetsrätsliga frågor.`,
    },
  },
  benefitsPoints: {
    one: "Genom att samarbeta med oss för att hantera dina juridiska behov inom arbetsrätt kommer ditt företag att kunna dra nytta av en lång rad fördelar: ",
    two: {
      title: "Kundanpassade Lösningar",
      desc: ": Vi förstår att alla företag är olika. Vårt ELaaS-erbjudande kommer att anpassas efter dina specifika behov. Vårt team kommer att arbeta nära dig för att skapa en skräddarsydd plan som överensstämmer med ditt företags krav och mål.",
    },
    three: {
      title: "Proaktivt Stöd",
      desc: ": Vår ELaaS-strategi är inriktad på att förebygga juridiska problem innan de uppstår. Vi samarbetar med dig för att identifiera potentiella risker, utveckla strategier för att minimera risker och hjälpa ditt företag undvika kostsamma rättstvister.",
    },
    four: {
      title: "Fortlöpande Expertis",
      desc: ": Genom vår ELaaS-lösning får du tillgång till ett dedikerat team av experter inom arbetsrätt som håller dig uppdaterad om den senaste rättsliga utvecklingen. Detta hjälper ditt företag bibehålla regelefterlevnad i det ständigt föränderliga arbetsrättsliga landskapet.",
    },
    five: {
      title: "Arbetsmiljö",
      desc: ": Genom att använda vår ELaaS-lösning kan du fokusera på att effektivisera dina viktiga  processer och främja företagets positiva arbetsmiljö. Vår lösning ger dig också utrymme att fokusera på att förbättra medarbetarnas tillfredsställelse, engagemang och utveckling.",
    },
    six: {
      title: "Kostnadsbesparingar",
      desc: ": ELaaS ger dig en kostnadseffektiv, transparent lösning, anpassat efter dina behov, med ett team av specialiserade arbetsrättsjurister. Våra anpassningsbara prismodeller tar hänsyn till dina specifika krav och din budget.",
    },
  },
  experiancePoints: {
    one: "Trust us to provide you with the comprehensive, customized, and cost-effective solution to your employment law needs.",
    two: "Contact us today to schedule a consultation and learn more about how our ELaaS offerings can benefit your business.",
  },
};

const dpoAsaServicePage = {
  title:
    "Säkerställ effektiv efterlevnad av dataskyddsregler med vår Tjänst som",
  orangeText: " Dataskyddsombud (DSO)",
  titleDesc: `Vi erbjuder ett externt dataskyddsombud som en tjänst, vilket innebär ett sömlöst och skräddarsytt stöd för efterlevnad av regelverken för dataskydd. Genom att samarbeta med oss drar du nytta av vår omfattande kunskap om dataskydd, vilket säkerställer att din organisation behandlar personuppgifter enligt gällande regelverk. Välj vår DSO-tjänst för effektiv implementering och hantering av din organisations dataskyddsansvar.`,
  dpo: "Vad är en DSO? ",
  needsDpo: "Vem behöver en DSO? ",
  roshlommdellOffer: "Vad erbjuder RosholmDell? ",
  dpoPoints: {
    one: `Dataskyddsombudet ansvarar för att organisationen följer dataskyddsförordningen (GDPR). Viktiga ansvarsområden är bland annat:`,
    two: {
      1: "Informera och ge råd till organisationen om skyldigheter enligt GDPR",
      2: "Övervakning av efterlevnaden av GDPR ",
      3: "Bistå med konsekvensbedömningar avseende dataskydd (DPIA)",
      4: "Samarbete och kommunikation med tillsynsmyndigheter ",
      5: "Rådgivning om hantering av förfrågningar och klagomål från registrerad",
      6: "Rådgivning om hantering av personuppgiftsincidenter",
      7: "Övervakning av databehandlingsaktiviteter",
      // 8: "Implementing privacy by design and default.",
      // 9: "Monitoring data processing activities.",
    },
    three: `De specifika uppgifterna kan variera beroende på organisationens storlek och komplexitet, men det primära fokuset är alltid GDPR-efterlevnad och skydd av
    personuppgifter.`,
  },
  needsPoints: {
    one: `Enligt GDPR är organisationer skyldiga att utse ett dataskyddsombud i följande fall:`,
    two: {
      1: "Offentliga myndigheter eller organ: Alla offentliga myndigheter eller organ, med undantag för domstolar som agerar i sin dömande funktion.",
      2: "Storskalig systematisk övervakning: Organisationer som utför storskalig systematisk övervakning av individer som en del av sin kärnverksamhet. Detta gäller normalt företag som ägnar sig åt spårning online, beteendestyrd reklam eller någon annan typ av omfattande övervakning av användarnas beteende.",
      3: `Storskalig behandling av särskilda kategorier av uppgifter eller uppgifter som rör fällande
      domar i brottmål: Organisationer som i stor skala behandlar särskilda kategorier av
      personuppgifter (såsom uppgifter som avslöjar ras eller etniskt ursprung, politiska åsikter,
      religiös eller filosofisk övertygelse, medlemskap i fackförening, biometriska uppgifter eller
      hälsouppgifter) eller uppgifter som rör fällande domar i brottmål, som en del av sin
      kärnverksamhet.`,
    },
    three: `Även om det inte är obligatoriskt för andra organisationer  att utse en DSO, är det en god idé att ändå utse ett dataskyddsombud för att säkerställa efterlevnad av GDPR och hantering av dataskyddsfrågor. Detta gäller särskilt om organisationen behandlar personuppgifter i stor skala eller hanterar känslig information.`,
  },
  offerPoints: {
    one: `Vi erbjuder kvalificerade jurister att agera som ditt företags dataskyddsombud. DSO-tjänsten förenklar ditt företags integritetsarbete genom att tillhandahålla rätt resurser. Vår DSO-tjänst ger  specialiserad hjälp utan större investeringar i tid eller personal. Dataskyddsombudet fungerar som kontaktperson gentemot såväl Integritetsskyddsmyndigheten som kunder, anställda och andra registrerade. Vår DSO-tjänst ger även råd och stöd inom din organisation i dataskydds- och integritetsfrågor, samtidigt som den kontinuerligt granskar efterlevnaden av GDPR och andra tillämpliga integritetsbestämmelser. `,
    two: "",
  },
};

const ipportfolioManagementPage = {
  title:
    "Få värdefulla juridiska tjänster för ditt företags IP-tillgångar med vår",
  orangeText: " IP Portfolio  Management-tjänst ",
  titleDesc: `Vår IP Portfolio  Management-tjänst är utformad för att hjälpa dig att effektivt utnyttja alla dina IP-tillgångar. Med våra juristers omfattande erfarenhet av immaterialrätt förstår vi vad som krävs för att  skydda dina immateriella rättigheter och samtidigt maximera värdet på dina varumärken och din marknadsposition.`,
  ipPortfolio: "Vad är IP Portfolio  Management?",
  needsIpportfolio: "Vem Behöver IP Portfolio Management?",
  roshlommdellOffer: "Vad Erbjuder RosholmDell?",
  ipPortfolioPoints: {
    one: `Vår heltäckande lösning för hantering av din IP-portfölj erbjuder:`,
    two: {
      1: "Strategisk övervakning av ditt företags varumärken, vilket förenklar hanteringen av dina varumärkestillgångar. Vi ser till att dina varumärkestillgångar är säkra och hanteras på ett effektivt sätt. ",
      2: "Specialiserad hjälp med varumärkesgranskningar och aktiv övervakning av varumärkesanvändning.",
      3: "Utveckling av strategier för globalt varumärkesskydd, inklusive registrering av varumärken i flera jurisdiktioner. Vi hjälper dig även förhindra obehörig användning av dina immateriella rätigheter både nationellt och internationellt.",
      4: "Omfattande hantering av dina licensavtal, franchisingavtal och andra IP-relaterade avtal. Vi hjälper kommersialisera dina varumärkestillgångar samtidigt som vi kontinuerligt granskar efterlevnaden av lagar och förordningar relaterade till immateriella rättigheter.",
      5: "Håller dig uppdaterad om betydande förändringar i varumärkeslandskapet. Vi har erfarenhet från  olika branscher och kan identifiera och hantera unika risker i ditt företags verksamhet.",
    },
  },
  needsIpportfolioPoints: {
    one: `Vår tjänst för förvaltning av IP-portföljer är väl lämpad för:`,
    two: {
      1: "Företag av alla storlekar, inklusive startups, som behöver en strategi för att hantera och skydda sina varumärken.",
      2: "Företag i konkurrensutsatta sektorer eller med värdefulla varumärkestillgångar som är i behov av en robust IP-strategi för att hantera sina varumärken.",
      3: `Internationella organisationer med verksamhet som kräver varumärkesskydd över flera juridiska territorier.`,
      4: `Företag som är involverade i olika stadier av produktlivscykeln, från produktutveckling och tillverkning till distribution.`,
      5: "E-handelsföretag, digitala marknadsförare och andra företag som arbetar med digital varumärkesprofilering.",
      6: "Företag som är engagerade i licensprocesser eller franchising av sina varumärken till tredje part.",
    },
    three: `Vår tjänst tillhandahåller de resurser och den professionella expertis som ditt företag behöver, utan betydande investeringar i tid eller personal. Vi agerar som din organisations kontaktpunkt, erbjuder rådgivning och underlättar förhandlingar, samtidigt som vi ständigt granskar efterlevnaden av relevanta varumärkeslagar och förordningar. Vår kompetens inom olika sektorer gör det möjligt för oss att identifiera och effektivt hantera specifika risker kopplade till din immateriella egendom. `,
  },
  roshlommdellOfferPoints: {
    one: `Vi levererar heltäckande tjänster för hantering av varumärken och design och fungerar som din IP-konsult. Vår IP-tjänst effektiviserar ditt företags hantering av sina immateriella tillgångar genom att tillhandahålla alla de resurser som just ditt företag behöver. Med hjälp av vårt omfattande globala partnernätverk hanterar vi varumärkesansökningar i alla era verksamhetsländer.`,
    two: `Vi använder avancerad AI-teknik för att övervaka och undersöka befintliga varumärken och agerar proaktivt vad gäller strategier för riskhantering och skydd av immateriella tillgångar. Vi har även ett nära samarbete med andra intressenter av din immateriella egendom och hjälper dig att förhandla om licensavtal, så att du kan utnyttja dina varumärkestillgångar mer effektivt.`,
    three: `Vårt team av experter säkerställer en effektiv hantering av din IP-portfölj. Med erfarenhet från en rad olika branscher identifierar och hanterar vi risker i ditt företags IP-verksamhet med ett lösningsorienterat förhållningssätt. Vår IP-tjänst omfattar även rådgivning och stöd kring efterlevnad av tillämpliga IP-lagar och förordningar. `,
  },
};
const whistleblowerPolicyPage = {
  title: "Visseblåsarpolicy",
  subtitleone: "Inledning, Syfte och Omfattning",
  subtitletwo: "Ansvar och Ansvarsfördelning",
  subtitlethree: "Rapportering av Missförhållanden",
  subtitlefour: "Skyddet vid Rapportering",
  subtitlefive: "Konfidentialitet, Dataskydd och Dokumentation",
  subtitleoneDesc: {
    one: `RosholmDell strävar efter ett sunt företagsklimat och värnar om att transparens och
    ansvarstagande genomsyrar hela RosholmDell:s verksamhet. RosholmDell är medvetet
    om att organisationer kan råka ut för misskötsel och andra missförhållanden. Genom
    denna Visselblåsarpolicy (”<strong>Policyn</strong>”) informerar RosholmDell om, och uppmuntrar till,
    rapportering av faktiska eller misstänkta missförhållanden inom RosholmDell.`,
    two: `Vidare syftar Policyn till fastställandet av en standard för rapportering av
    missförhållanden inom verksamheten. En individ som vill rapportera om
    missförhållanden (”<strong>Visselblåsare</strong>”) ska känna sig trygg och veta att denne kan
    rapportera om missförhållanden utan risk för repressalier samt att rapporter hanteras
    på ett professionellt och konfidentiellt sätt.`,
    three: `Policyn omfattar hela verksamheten. Alla som ingår i RosholmDell:s visselblåsarkrets
    har möjlighet att rapportera enligt denna Policy. De som ingår i denna krets är sådana
    som är i kontakt med RosholmDell i ett arbetsrelaterat sammanhang, till exempel
    anställda, konsulter, praktikanter eller volontärer.`,
  },
  subtitletwoDesc: {
    one: "RosholmDell:s styrelse har det yttersta ansvaret för att RosholmDell följer de regelverk som reglerar rätten att visselblåsa och skyddet mot repressalier m.m",
    two: "VD har ansvaret för det löpande arbetet inom RosholmDell och för efterlevnaden av Policyn. ",
    three:
      "Samtliga medarbetare förväntas känna till denna Policy och vid var tid gällande instruktioner rörande visselblåsning samt använda sig av möjligheten till att rapportera om missförhållanden enligt Policyn om så är nödvändigt.",
  },
  subtitlethreeDesc: {
    one: `Missförhållanden är händelser av allmänintresse, såsom exempelvis överträdelser av
    lagar och föreskrifter eller av RosholmDell:s egna styrdokument, eller andra
    missförhållanden som det finns ett allmänt intresse av att de framkommer.`,
    two: "Några exempel på aktiviteter eller händelser som RosholmDell anser vara missförhållanden av allmänintresse är:",
    three: {
      1: "i) Mutor, stöld, bedrägeri, bokföringsbrott, skattebrott och andra brottsliga handlingar,",
      2: "ii) grova brott mot interna styrdokument,",
      3: "iii) verksamhet som kan skada person eller egendom,",
      4: "iv) underlåtenhet att rätta till eller rapportera händelse som kan innebära avsevärda kostnader eller förluster,",
      5: "v) missbruk av makt eller position,",
      6: `vi) diskriminering på grund av ålder, ras, kön, religion, sexuell orientering,
      civilstånd, föräldraskapsstatus, politiska åsikter eller etnisk bakgrund för
      anställning eller nyttjande av tjänster,`,
      7: "vii) korruption och intressekonflikter, eller",
      8: `viii) kränkningar av någons grundläggande fri- och rättigheter.`,
    },
    four: {
      title: "Rapportera till din närmaste chef:",
      desc: ` Den som upptäcker eller misstänker missförhållanden uppmuntras att rapportera detta
      till sin närmaste chef. Rapportering av missförhållanden kan även alltid göras till
      RosholmDell:s VD eller styrelseordförande, direkt eller anonymt.`,
    },
    five: {
      title: "Externa rapporteringskanaler och offentliggörande :",
      desc: ` Den som vill rapportera om ett missförhållande kan även göra detta genom att använda
      en extern rapporteringskanal genom vilken rapportering görs till behörig myndighet.
      Information om hur rapportering kan göras till behöriga myndigheter via extern
      rapporteringskanal finns på RosholmDell:s webbplats.`,
    },
    six: {
      title: "Utredning och respons:",
      desc: ` Missförhållanden ska utredas så skyndsamt som omständigheterna kräver och inom
      tillämpliga tidsgränser. RosholmDell ska utreda alla ärenden som rapporteras. Det är
      viktigt att varje individ som rapporterar verkligen misstänker att ett missförhållande
      föreligger. RosholmDell ser allvarligt på medvetet falska eller illvilliga anklagelser och
      kan komma att vidta disciplinära åtgärder gentemot den som i ont uppsåt rapporterar
      sådana anklagelser. En uppgift som inte är sanningsenlig kan komma att åberopas som
      bevisning om uppgiften skulle innebära förtal.`,
    },
  },
  subtitlefourDesc: {
    one: `En Visselblåsare ska skyddas från alla typer av repressalier. Skyddet följer av Lag
    (2021:890) om skydd för personer som rapporterar om missförhållanden. Skyddet gäller
    under förutsättning att Visselblåsaren har haft skälig anledning att anta att
    informationen i rapporten är sann samt att denne inte gör sig skyldig till brott genom
    inhämtandet av informationen. Detsamma gäller den som bistår Visselblåsaren vid
    rapporteringen, såsom en förtroendevald eller ett skyddsombud. Att lämna ut
    handlingar i samband med rapportering omfattas inte av skyddet.`,
    two: "Exempel på repressalier som Visselblåsare ska skyddas ifrån är:",
    three: {
      1: "i) uppsägning, avskedande, omplacering,",
      2: "ii) utebliven löneförhöjning, försämrade förmåner,",
      3: "iii) oberättigat dåliga vitsord, och",
      4: "iv) utebliven befordran, utfrysning samt alla andra repressalier som har ett samband med rapporteringen.",
    },
    four: `RosholmDell får inte vidta repressalier på grund av att någon vänder sig till sin
    arbetstagarorganisation för samråd i fråga om rapportering. RosholmDell får inte heller
    hindra eller försöka hindra sådant samråd.`,
    five: `En Visselblåsare omfattas också av skyddet när denne väljer att rapportera till en
    behörig myndighet via extern rapporteringskanal förutsatt att inga skäliga
    uppföljningsåtgärder vidtagits av RosholmDell efter intern rapportering, eller om
    Visselblåsaren inte har fått mottagandet av rapporten bekräftat inom sju dagar, eller
    inte har fått återkoppling inom tre månader. Skyddet vid extern rapportering gäller
    också när Visselblåsaren har skälig anledning att anta att det föreligger ett
    missförhållande som innebär en överhängande eller uppenbar fara, eller om intern
    rapportering kan antas medföra en risk för repressalier eller att missförhållandet inte
    avhjälps.`,
    six: `Visselblåsare är skyddade mot efterforskning, vilket innebär att RosholmDell inte får
    eftersöka vem det är som lämnat en rapport. RosholmDell får inte heller hindra eller
    försöka hindra rapportering.`,
  },
  subtitlefiveDesc: {
    one: {
      title: "Konfidentialitet:",
      desc: ` En anmälan om missförhållanden eller misstänkta missförhållanden ska hanteras
      konfidentiellt. Uppgift som kan innebära att den rapporterande personen kan komma
      att identifieras får lämnas ut, exempelvis i samband med polisanmälan, förutsatt att det
      inte gör att syftet med uppföljningen av rapporten hindras eller försvåras.`,
    },
    two: {
      title: "Dataskydd:",
      desc: ` Personuppgifter behandlas i samband med uppföljningsärenden, för vidtagande av
      åtgärder, för att rapporter ska kunna användas som bevisning i rättsliga förfaranden
      eller på annat sätt om det sker i överensstämmelse med lag eller förordning.
      Personuppgifter som behandlas i samband med uppföljningsärende och med
      vidtagandet av åtgärder efter ett uppföljningsärende får behandlas i högst två år efter
      det att ärendet avslutats. Personuppgifter som är uppenbart irrelevanta för ett ärendes
      handläggning får inte samlas in och raderas så snart som möjligt. Mer information om
      RosholmDell:s behandling av personuppgifter avseende anställda i informationen som
      finns tillgänglig RosholmDells intranät eller om du är annan än anställd eller liknande, i
      vår`,
      desc1: " Integritetspolicy",
      dot: ".",
    },
    three: {
      title: "Dokumentation:",
      desc: ` Ärendet ska dokumenteras på ett ändamålsenligt sätt. Materialet ska sparas så länge
      som ett ärende pågår, dock som längst i två år efter att ärendet avslutats.
      `,
    },
  },
};

const privacyNoticePage = {
  title: "Integritetspolicy",
  subtitleone: "Bakgrund",
  subtitletwo: "Definitioner",
  subtitlethree: "När Behandlar vi dina Personuppgifter?",
  subtitlefour: "Personuppgiftsansvarig",
  subtitlefive: "Varför Behandlar vi dina Personuppgifter?",
  subtitlesix:
    "Vilka Personuppgifter Behandlas och enligt vilken laglig grund?",
  subtitleseven: "Hur länge sparas dina Personuppgifter?",
  subtitleeight: "Sociala plattformar",
  subtitlenine: "Dina rättigheter",
  subtitleten: "Samtycke till Behandling av Personuppgifter",
  subtitleeleven: "Till vilka överför vi dina Personuppgifter?",
  subtitletwelve: "Hur skyddar vi dina Personuppgifter?",
  subtitlethirteen: "Tillsyn och efterlevnad",
  subtitlefourteen: "Tredjepartsvillkor",
  subtitlefifteen: "Ändring av Integritetspolicyn ",
  subtitlesixteen: "Hur kommer du i kontakt med oss?",
  subtitleoneDesc: {
    one: `Denna policy (”<strong>Integritetspolicy</strong>”) har utformats för att göra dig medveten om att
    RosholmDell Advokatbyrå AB (”<strong>RosholmDell</strong>”, ”<strong>vi</strong>”, ”<strong>oss</strong>”) behandlar dina
    personuppgifter på ett lagligt, ändamålsenligt och säkert sätt när du eller det företag du
    representerar, köper våra tjänster, besöker vår webbplats eller i övrigt är i kontakt med oss.`,
    two: `Integritetspolicyn beskriver hur RosholmDell behandlar dina personuppgifter och vilka
    rättigheter du har enligt bestämmelserna i EU:s dataskyddsförordning (EU) 2016/679 med
    tillhörande genomförandeförfattningar och kompletterande författningar om dataskydd
    (”<strong>Dataskyddsreglerna</strong>”). Utöver Dataskyddsreglerna är vi enligt lag och enligt Sveriges
    advokatsamfunds vägledande regler skyldiga att iaktta sekretess ifråga om information som
    förekommer i vår verksamhet. Dessa regler förhindrar oss normalt att röja information utan
    kundens godkännande, vilket även kan avse andra uppgifter än kundens egna. `,
    three: `Utöver denna Integritetspolicy gäller våra   `,
    four: "Allmänna villkor",
    five: "vid köp av våra tjänster.",
  },
  subtitletwoDesc: {
    one: `I denna Integritetspolicy avser begreppet ”personuppgift” information som kan hänföras
    till en identifierad eller en identifierbar fysisk person, t.ex. namn, adress, personnummer,
    e-postadress, telefonnummer, foto och kontonummer (”<strong>Personuppgifter</strong>”).`,
    two: `I denna Integritetspolicy beskriver vi hur vi behandlar dina Personuppgifter. Med begreppet
    ”behandling” avses exempelvis att vi registrerar, lagrar, skickar och på andra sätt använder
    dina Personuppgifter på det sätt som beskrivs i denna Integritetspolicy (”<strong>Behandling</strong>”).`,
  },
  subtitlethreeDesc: {
    one: `Integritetspolicyn är tillämplig i följande fall då Behandling av Personuppgifter
    aktualiseras i vår verksamhet: `,
    two: {
      1: "(i) då RosholmDell tillhandahåller tjänster till dig eller det företag du representerar;",
      2: "(ii) vid utskick av inbjudan till event och annan information;",
      3: "(iii) vid ditt deltagande i utbildningar, workshops, seminarium och event som hålls eller arrangeras av oss;",
      4: "(iv) i samband med besök på vår webbplats www.rosholmdell.se (”<strong>Webbplatsen</strong>”) eller via sociala plattformar; ",
      5: "(v) när du söker jobb hos oss; samt ",
      6: "(vi) vid förfrågningar eller övrig kontakt med oss via e-post eller telefon.",
    },
  },
  subtitlefourDesc: {
    one: `RosholmDell Advokatbyrå AB, org. nr 556858-6290, Kungsgatan 12, 352 33 Växjö, är
    personuppgiftsansvarig för den Behandling av dina Personuppgifter som sker i enlighet
    med denna Integritetspolicy.`,
  },
  subtitlefiveDesc: {
    one: `RosholmDell Behandlar Personuppgifter avseende våra kunder, företrädare för och andra
    kontaktpersoner hos kund, våra kunders samarbetspartners och kunder, potentiella kunder,
    motparter och företrädare eller kontaktperson hos motparter, potentiella motparter, vittnen,
    arbetssökande, leverantörer och andra samarbetspartners. RosholmDell samlar endast in
    och Behandlar Personuppgifter för specifika ändamål. Detta innebär att Personuppgifter
    Behandlas för att: `,
    two: {
      1: `(i) tillhandahålla tjänster och i övrigt uppfylla det avtal som ingåtts med dig
      personligen, med det företag eller organisation du företräder eller arbetar för, eller
      det företag eller organisation du arbetar på uppdrag av;`,
      2: "(ii) tillhandahålla seminarier, workshops, utbildningar och andra event;",
      3: `(iii) möjliggöra allmän kundvård (kvalitetsarbete, statistik, marknads- och kundanalys
        samt affärs- och metodutveckling) och kundservice, t.ex. när du kontaktar oss via
        e-post, telefon eller via Webbplatsen;`,
      4: `informera och marknadsföra verksamheten genom bland annat publicering av
      kundreferenser och annat material på Webbplatsen, presentationsmaterial som
      framförs vid event eller via broschyrer;`,
      5: `lämna information om event och nyheter och rikta marknadsföring via e-post,
       telefon eller via sociala plattformar avseende vår verksamhet och våra tjänster;`,
      6: "(vi) behandla en jobbansökan;",
      7: "(vii) förhindra bedrägerier, penningtvätt och finansiering av terrorism samt utföra riskhantering; och",
      8: "(viii) följa tillämplig lagstiftning.",
    },
    three: `Vilka Personuppgifter vi samlar in om dig beror bland annat på (i) hur du är i kontakt med
      oss, och (ii) vilken av våra tjänster vi tillhandahåller till dig eller det företag du
      representerar.`,
    four: `Utöver de Personuppgifter du själv lämnar till oss, eller som vi samlar in vid
    tillhandahållandet av våra tjänster, kan vi också komma att samla in Personuppgifter från
    tredje part. Dessa tredje parter varierar från tid till annan men inkluderar bland annat
    information från offentliga register såsom Bolagsverkets näringslivsregister eller
    Lantmäteriets fastighetsregister samt kreditvärderingsinstitut eller banker varifrån vi får
    uppgifter för att göra kontroller för att förhindra penningtvätt och finansiering av terrorism.`,
    five: `Du är inte skyldig att lämna Personuppgifter till oss men önskar du inte göra detta kan det
     innebära att vi inte kan åta oss ett uppdrag eftersom att vi inte kan genomföra nödvändiga
     jävs- och/eller kontroller för att förhindra penningtvätt och finansiering av terrorism. `,
  },
  subtitlesixDesc: {
    one: `Nedan angivna Personuppgifter Behandlas av oss, för ändamål som framgår av punkt 5
    ovan:`,
    two: {
      1: "(i) för- och efternamn;",
      2: "(ii) faktura- och leveransadress;",
      3: "(iii) telefonnummer;",
      4: "(iv) e-postadress;",
      5: "(v) yrkestitel samt arbetsplats;",
      6: "(vi) personnummer;",
      7: "(vii) kontonummer;",
      8: "(viii) betalningsuppgifter;",
      9: "(ix) klientnummer;",
      10: "(x) kopia av id-handling;",
      11: "(xi) information om hälsotillstånd med anledning av anmälan av tex. kostpreferenser och allergier;",
      12: "(xii) omdömen;",
      13: "(xiii) cv;",
      14: "(xiv) kundreferenser; och",
      15: "(xv) uppgifter i korrespondens med dig.",
    },
    three: `Behandlingen av Personuppgifterna är nödvändig för att RosholmDell ska kunna
     tillhandahålla juridiska tjänster och utföra och administrera uppdraget och för att tillvarata
     dina intressen och därmed fullgöra avtalet som ingåtts med dig. I de fall där du agerar som
     företrädare eller kontaktperson hos ett företag eller organisation som utgör kund åt
     RosholmDell eller på annat sätt samarbetar med RosholmDell, Behandlar vi dina
     Personuppgifter med stöd av vårt berättigade affärsintresse. Vid denna bedömning har vi
     genomfört en intresseavvägning där vi bland annat beaktat att Behandlingen inte berör
     några känsliga Personuppgifter och att vi har ett tydligt affärsmässigt intresse av att kunna
     tillhandahålla våra tjänster till det företag du arbetar på eller på uppdrag av på ett effektivt
     sätt.
     `,
    four: `Behandlingen av Personuppgifterna är också nödvändig för att RosholmDell ska kunna
      tillhandahålla god service och kundvård och därmed tillgodose vårt berättigade intresse av
      att utveckla verksamheten och kommunicera med våra kontakter på ett effektivt och
      kundvänligt sätt. Till detta hör att vi i vissa fall Behandlar känsliga personuppgifter om
      hälsotillstånd i samband med att du vid anmälan till ett event som arrangeras av oss,
      informerar oss om tex. kostpreferenser och/eller allergier. Dessa Personuppgifter
      Behandlas endast för att vi ska kunna tillgodose de specifikt efterfrågade önskemålen.`,
    five: `Personuppgifterna kan även Behandlas för annan kontakt med dig t.ex. via nyhetsbrev eller
      andra utskick såsom inbjudningar till event, information om seminarier och utbildningar
      eller annan information som vi bedömer är relevant för dig eller det företag eller
      organisation du representerar. Denna Behandling baserar vi på vårt berättigade
      affärsintresse av att kunna erbjuda dig som har en befintlig affärsrelation med oss, eller
      som tidigare haft en affärsrelation med oss, relevant marknadsföring och information. Vid
      denna bedömning har vi genomfört en intresseavvägning där vi bland annat beaktat att
      Behandlingen inte berör några känsliga Personuppgifter och att vi har ett tydligt
      affärsmässigt intresse av att kunna informera om våra tjänster till dig på ett effektivt sätt. `,
    six: `Om du är i kontakt med oss i samband med att du söker jobb hos oss, baserar vi vår
      Behandling av dina Personuppgifter på ditt samtycke eller med stöd av vårt berättigade
      affärsintresse.`,
    seven: `Vi kan även komma att Behandla dina Personuppgifter efter att vi fått ditt samtycke, t.ex.
      sker detta när du samtycker till att vi publicerar kundreferenser på vår Webbplats. För de
      fall vi inte längre kan basera den Behandling av Personuppgifter som sker i
      marknadsföringssyfte med stöd av vårt affärsintresse, kan vi komma att inhämta ditt
      samtycke till att exempelvis skicka marknadsföring till dig. Samtycke kan även inhämtas
      om vi önskar lagra inkommande CV för framtida rekryteringsändamål. Vi dokumenterar
      alltid om du givit ditt samtycke till att vi Behandlar dina Personuppgifter.`,
    eight: `Dina Personuppgifter Behandlas även i tillämpliga fall för att RosholmDell ska kunna
      uppfylla rättsliga förpliktelser enligt tillämplig lag, domar, myndighetsbeslut eller
      Vägledande regler för god advokatsed. Sådana krav kan till exempel avse krav som följer
      av Bokföringslag (1999:1078), Lag (2017:630) om åtgärder mot penningtvätt och
      finansiering av terrorism eller av god advokatsed i samband med utförande av jävskontroll. `,
  },
  subtitlesevenDesc: {
    one: `Dina Personuppgifter sparas endast så länge det finns behov av att spara dem för att fullgöra
    de ändamål som Personuppgifterna samlades in för i enlighet med denna Integritetspolicy. `,
    two: `Merparten av de Personuppgifter som Behandlas, sparas i enlighet med den skyldighet som
    åvilar RosholmDell enligt Vägledande regler om god advokatsed, under en tid om tio år
    från dagen för uppdragets slutförande, eller den längre tid som påkallas av uppdragets
    natur.`,
    three: `Personuppgifter som Behandlas i syfte att utveckla, analysera och informera om
      RosholmDells verksamhet sparas så länge vi har en affärsrelation med dig, din arbetsgivare
      eller det företag du representerar.`,
    four: `Har du lämnat samtycke till Behandling av dina Personuppgifter, Behandlar RosholmDell
      Personuppgifterna för det specifika ändamålet, fram till dess att du återkallar ditt samtycke.
      Det kan du göra genom att kontakta oss och invända mot detta.`,
    five: `För att möjliggöra för RosholmDell att uppfylla de rättsliga förpliktelser som följer av
    tillämplig lag eller för att kunna bevaka vårt rättsliga intresse kan vi komma att spara
    Personuppgifterna under en längre tid än vad som anges ovan. Personuppgifterna sparas
    dock aldrig längre än vad som är nödvändigt eller lagstadgat för respektive syfte.`,
  },
  subtitleeightDesc: {
    one: `Vi använder oss av Facebook, Instagram och LinkedIn som kanaler för att komma i kontakt
    med våra kunder och andra affärspartners samt marknadsföra och informera om vår
    verksamhet och våra tjänster. I samband med detta är RosholmDell personuppgiftsansvarig
    för publiceringar och information som innehåller Personuppgifter och som görs av dig som
    användare i form av exempelvis kommentarer, bilder och filmklipp. RosholmDell
    accepterar inte på något sätt att kränkande material publiceras eller tillgängliggörs. Vi
    omber våra användare att rapportera kränkande innehåll till oss så att vi kan tillse att sådant
    innehåll inte förekommer. RosholmDell kan således även utifrån vad vi bedömer som
    nödvändigt komma att ta bort innehåll som publicerats på vår Facebooksida, vårt
    Instagramkonto och vår LinkedIn-sida. `,
  },
  subtitlenineDesc: {
    one: `Du har rätt att få information om den Behandling av dina Personuppgifter vi utför. Nedan
    följer en sammanställning av de rättigheter du kan göra gällande genom att kontakta oss.
    Kontaktinformation till oss hittar du i slutet av denna Integritetspolicy.`,
    two: {
      title: "Rätt till tillgång:",
      desc: ` Du har rätt att kostnadsfritt begära information om den Behandling vi genomför avseende
      dina Personuppgifter. Du har även rätt att få en kopia av de Personuppgifter vi Behandlar
      om dig. Denna begäran ska inges skriftligen till oss. Vi kommer att besvara din förfrågan
      så snart vi kan. Om vi inte kan uppfylla dina önskemål om tillgång till de uppgifter din
      begäran avser, lämnar vi en motivering till detta. Kopian av dina Personuppgifter skickas
      till din folkbokföringsadress såvida inte annat skriftligt överenskommits med dig. För att
      vi ska vara säkra på att det är rätt person som mottar Personuppgifterna kan vi komma att
      begära ytterligare information från dig. `,
    },
    three: {
      title: "Rätt till rättelse:",
      desc: ` Huvudansvaret för att de Personuppgifter vi Behandlar är korrekta åvilar RosholmDell. Om
      du meddelar oss att de Personuppgifter du lämnat inte längre är korrekta, kommer visnarast
      att rätta, blockera eller radera sådana Personuppgifter. `,
    },
    four: {
      title: "Rätt till radering:",
      desc: ` Du har rätt att begära att RosholmDell, utan onödigt dröjsmål, raderar dina Personuppgifter.
      Personuppgifter ska raderas i följande fall:`,
      desc1: {
        1: "(i) om Personuppgifterna inte längre är nödvändiga för det ändamål de samlades in för; ",
        2: "(ii) om du återkallat ditt samtycke och Behandlingen endast grundar sig på samtycke;",
        3: "(iii) om Behandling sker för direktmarknadsföring och du motsätter dig att dina Personuppgifter behandlas för detta ändamål;",
        4: "(iv)om du motsätter dig Behandling av Personuppgifter som sker efter en intresseavvägning och ditt intresse väger tyngre än vårt;",
        5: "(v) om dina Personuppgifter inte har Behandlats i enlighet med Dataskyddsreglerna; eller",
        6: "(vi) om radering krävs för att uppfylla en rättslig skyldighet. ",
      },
    },
    five: `Det kan finnas skyldigheter som hindrar oss från att omedelbart radera samtliga dina
    Personuppgifter. Dessa skyldigheter följer av tillämplig lagstiftning inom t.ex. bokföring
    samt enligt den skyldighet som åvilar oss att arkivera samtliga uppdrag i minst tio år från
    det att uppdraget slutförts i enlighet med Vägledande regler för god advokatsed. Om vissa
    Personuppgifter inte kan raderas på grund av tillämplig lagstiftning och andra
    förhållningsregler vi är skyldiga att tillämpa, kommer vi att informera dig om detta samt
    tillse att Personuppgifterna endast kan användas i syfte att uppfylla sådana legala
    skyldigheter och inte för några andra syften. `,
    six: {
      title: "Rätt till begränsning:",
      desc: ` Du har rätt att begära att RosholmDell tillfälligt ska begränsa Behandlingen av dina
      Personuppgifter. En sådan begränsning kan begäras i följande fall:`,
      desc1: {
        1: "(i) om du anser att de Personuppgifter vi har om dig inte är korrekta och att du i samband med det begärt rättelse; ",
        2: `(ii) när den Behandling som utförs av dina Personuppgifter inte är förenlig med
        reglerna i Dataskyddsreglerna men du ändå inte vill att dina Personuppgifter ska
        raderas utan i stället begränsas; och`,
        3: `(iii) när vi inte längre behöver dina Personuppgifter för ändamålen med vår Behandling
        men då du behöver dem för att kunna fastställa, göra gällande eller försvara ett
        rättsligt anspråk.`,
      },
    },
    seven: `Om du har invänt mot Behandlingen av dina Personuppgifter får användningen av
      Personuppgifterna begränsas under tiden som utredning sker. Vid begränsning av dina
      Personuppgifter kommer RosholmDell endast att lagra dina Personuppgifter och vid
      ytterligare Behandling inhämta ditt samtycke.`,
    eight: {
      title: "Rätt till dataportabilitet:",
      desc: ` Du har rätt att, för det fall vi Behandlar dina Personuppgifter med ditt samtycke eller för
      att fullgöra avtalsförpliktelse med dig, kräva att vi tillhandahåller samtliga Personuppgifter
      vi har om dig och som Behandlas på ett automatiserat sätt, i ett maskinläsbart format, vilket
      t.ex. kan vara en Excelfil eller en CSV-fil. Om det är tekniskt möjligt har du vidare rätt att
      begära att vi överför dina Personuppgifter till en annan personuppgiftsansvarig. `,
    },
    nine: {
      title: "Rätt till invändning:",
      desc: ` Du har rätt att invända mot vår Behandling av dina Personuppgifter om Behandlingen sker
      med stöd av en intresseavvägning. RosholmDell kommer i dessa fall be dig specificera
      vilken Behandling du invänder mot. Om du invänder mot någon Behandling kommer vi
      endast att fortsätta med Behandlingen av dina Personuppgifter om det finns berättigade skäl
      till Behandlingen som väger tyngre än dina intressen. Vi kommer även informera dig om
      vårt beslut.`,
    },
  },
  subtitletenDesc: {
    one: `Har du lämnat samtycke till Behandling av dina Personuppgifter bestämmer du själv om
    och när du vill återkalla ditt samtycke till den Behandling av Personuppgifter som du
    frivilligt samtyckt till. Det kan du göra genom att (i) kontakta oss och skriftligen invända
    mot detta eller (ii) följa länken i utskicken om samtycket avser utskick av marknadsföring
    och annat informationsmaterial. `,
  },
  subtitleelevenDesc: {
    one: `För att tillhandahålla vissa av våra tjänster anlitar vi utvalda tredje parter. Det medför att vi
    delar en del av de Personuppgifter vi samlat in om dig med dessa, till exempel till
    samarbetspartners såsom leverantörer av IT-tjänster, ärendehanteringssystem eller som
    tillhandahåller tryck och distribution. RosholmDell vidtar i samband med sådan delning
    eller överföring av dina Personuppgifter, tekniska och organisatoriska åtgärder för att
    säkerställa att dina Personuppgifter hanteras på ett säkert och tryggt sätt. Dessa utvalda
    tredje parter kommer endast att Behandla dina Personuppgifter på sätt som följer av denna
    Integritetspolicy och för att uppfylla något eller några av de ändamål som anges i denna
    Integritetspolicy. I de fall där dessa leverantörer utgör personuppgiftsbiträden åt
    RosholmDell ansvarar vi gentemot dig att dessa tredje parter Behandlar dina
    Personuppgifter på ett korrekt och lagligt sätt. Vi har även i dessa fall avtal med
    leverantörerna med instruktioner för Behandling av Personuppgifterna enligt
    Dataskyddsreglerna. `,
    two: `RosholmDell kan komma att överföra dina Personuppgifter till land utanför EU/EES, om
    det på grund av uppdragets natur är nödvändigt. Det kan till exempel handla om att
    uppdraget kräver biträde av ett utländskt ombud eller rör en internationell transaktion eller
    tvist. Sådan överföring kommer i så fall alltid att ske på ett säkert och lagenligt sätt.`,
    three: `Vi kommer att lämna ut dina Personuppgifter om det krävs enligt lag, myndighetsbeslut
      eller beslut av domstol eller om vi, som företag, rimligen anser att utlämnandet är
      nödvändigt för att skydda vårt företags rättigheter till exempel genom utlämnande av
      Personuppgifter till RosholmDells ansvarsförsäkringsgivare och/eller för att åtlyda ett
      domstolsbeslut eller följa utslaget i en rättslig process. Vi kommer också att lämna ut dina
      Personuppgifter då det inom ramen för ett visst uppdrag är nödvändigt för att tillvarata dina
      rättigheter. Sådan överföring kan till exempel ske till domstolar, myndigheter, motparter,
      motpartsombud samt försäkringsbolag i samband med ansökan om rättskydd.`,
    four: `Dina Personuppgifter kan även till exempel komma att lämnas ut till din/ditt företags
       revisor enligt överenskommelse med dig, till bank i samband med klientmedelshantering
       när banken kräver upplysning och till Sveriges advokatsamfund när vi är skyldiga att göra
       det.`,
    five: `RosholmDell kommer inte att sälja dina Personuppgifter till tredje part om vi inte först fått
        ditt godkännande. Dock kan vi, för det fall att RosholmDell beslutar att dela upp, sälja,
        köpa, gå samman med annat bolag eller annan organisation, eller på annat sätt
        omorganisera affärsverksamheten, överföra dina Personuppgifter till potentiella eller
        faktiska köpare och deras eventuella rådgivare.`,
  },
  subtitletwelveDesc: {
    one: `För att skydda din personliga integritet, upptäcka, förhindra och begränsa risk för
    hackerangrepp etc., vidtar RosholmDell lämpliga tekniska och organisatoriska
    informationssäkerhetsåtgärder. RosholmDell vidtar även åtgärder för att skydda dina
    Personuppgifter mot obehörig åtkomst, missbruk, avslöjande, förändringar och förstörelse.
    RosholmDell tillser att åtkomst till dina Personuppgifter endast ges till personal som
    behöver det för fullgörandet av sina arbetsuppgifter samt att de iakttar konfidentialitet.`,
  },
  subtitlethirteenDesc: {
    one: `Om du är missnöjd med hur dina Personuppgifter har Behandlats eller anser att dina
    Personuppgifter har Behandlats i strid med Dataskyddsreglerna kan du i första hand
    kontakta oss. Du har också möjlighet att vända dig till tillsynsmyndigheten i den
    medlemsstat där du har din hemvist, din arbetsplats eller där det påstådda intrånget begicks
    för att lämna in ett klagomål. I Sverige är det Integritetsskyddsmyndigheten som är
    tillsynsmyndighet`,
    two: "https://www.imy.se/en/RosholmDell",
    three: ". RosholmDell utvärderar årligen denna Integritetspolicy. ",
  },
  subtitlefourteenDesc: {
    one: `RosholmDells tjänster kan i vissa fall vara föremål för tredje parts villkor. RosholmDell är
    inte ansvarig för sådana tredje parters användning av dina Personuppgifter då dessa är
    personuppgiftsansvariga och svarar för Behandlingen av dina Personuppgifter. Det är
    därför viktigt att du beaktar och läser igenom de villkor som gäller hos tredje part.
    Detsamma gäller om det finns en länk på Webbplatsen som länkar till andra webbplatser.`,
  },
  subtitlefifteenDesc: {
    one: `Vi förbehåller oss rätten att ändra i denna Integritetspolicy när vi bedömer att detta är
    nödvändigt. Sådana ändringar är framförallt påkallade vid eventuella lagändringar, vid
    vägledande uttalanden eller rekommendationer från tillsynsmyndighet, Sveriges
    Advokatsamfund eller andra organ som utger yttranden med anledning av
    Dataskyddsreglerna. Vidare kommer denna Integritetspolicy att uppdateras när det behövs
    med anledning av ändringar i vår verksamhet.`,
    two: `Om RosholmDell vidtar större ändringar i denna Integritetspolicy eller ändringar gällande
    hur vi Behandlar dina Personuppgifter, informeras du om detta innan ändringen börjar
    gälla.`,
  },
  subtitlesixteenDesc: {
    one: `Om du har frågor med anledning av denna Integritetspolicy eller gällande Behandling av
    dina Personuppgifter, önskar göra en begäran i enlighet med Integritetspolicyn eller om du
    vill anmäla överträdelse av denna Integritetspolicy m.m. är du välkommen att kontakta oss
    via e-post, telefon eller brev.`,
  },
};
const cookieNoticePage = {
  title: "Cookie Notice",
  titledesc: `Denna cookie notice (”<strong>Cookie Notice</strong>”) beskriver hur RosholmDell Advokatbyrå, org. nr
    556858-6290 (”<strong>RosholmDell</strong>”, ”<strong>vi</strong>” eller ”<strong>oss</strong>”), använder cookies, webb-beacons och
    liknande teknologi (”<strong>Cookies</strong>”) för att samla in och lagra information när du besöker vår
    webbplats `,
  titleLink: "www.rosholmdell.se",
  titledesc2: `(”<strong>Webbplatsen</strong>”). Syftet med denna Cookie Notice är
    att vara tydlig och transparent när det gäller hur vi använder Cookies och dina uppgifter
    så att du har tillräcklig kunskap för att kunna välja om du vill samtycka till vår
    användning av Cookies.`,
  subtitleone: "Sammanfattning",
  subtitletwo: "Vad är en Cookie?",
  subtitlethree: "Varför använder vi Cookies?",
  subtitlefour: "Hur behandlar vi personuppgifter?",
  subtitlefive: "Vilka kategorier av Cookies använder vi?",
  subtitlesix: "Vad menas med samtycke till Cookies?",
  subtitleseven: "Hur tar jag bort Cookies?",
  subtitleeight: "Uppdatering av Cookie Notice",
  subtitlenine: "Kontaktuppgifter",
  subtitleTen: "Tredjepartscookies",
  subtitleoneDesc: {
    one: `Denna Cookie Notice är avsedd att hjälpa dig att förstå mer om denna teknik och hur vi
    använder oss av den på Webbplatsen. Nedan följer en kortare sammanfattning av vår
    användning av sådan teknik. Du kan även läsa mer detaljerad information avseende vår
    användning av Cookies längre ner i denna Cookie Notice. `,
    two: `När du besöker eller interagerar med Webbplatsen, kan RosholmDell eller de
    samarbetspartners som vi har godkänt, använda Cookies för att lagra information för
    att ge dig en bättre, snabbare och säkrare upplevelse.`,
    three: `Våra Cookies har olika användningsområden. De behövs antingen för våra tjänsters
    funktionalitet, för att hjälpa oss att förbättra våra tjänster eller för att ge dig extra
    funktionalitet. Vi använder också olika typer av Cookies, sådana som endast finns kvar
    på din dator, mobil eller surfplatta (”<strong>Enhet</strong>”) så länge som du håller din webbläsare
    aktiv (sessionscookies) och sådana som finns kvar på din Enhet under en längre tid
    (permanenta cookies). När du samtycker till vårt användande av Cookies, godkänner du
    hur vi använder den information som samlas in i samband därmed. Informationen kan
    eventuellt utgöra personuppgifter. Du kan välja att bara godkänna vissa kategorier av
    Cookies. Du kan fritt blockera, ta bort eller inaktivera Cookies om din Enhet tillåter det.
    Du hanterar inställningar för Cookies i din webbläsare eller Enhet.`,
    four: `Vi vidtar lämpliga fysiska, tekniska och organisatoriska säkerhetsåtgärder för att
    förhindra obehörig behandling av personuppgifter som samlas in genom våra Cookies.
    Dessa säkerhetsåtgärder är anpassade till mängden och känsligheten av de insamlade
    personuppgifterna. En unik identifierare säkerställer att endast vi och/eller de
    samarbetspartners som vi har godkänt har tillgång till sådan information som samlas in
    genom användandet av Cookies. `,
    five: `Våra samarbetspartners är företag som hjälper oss med olika delar av vår verksamhet,
    t.ex. drift av Webbplatsen, tillhandahållande av tjänster, applikationer etc. Dessa
    samarbetspartners kan också placera Cookies på din Enhet via våra tjänster
    (”<strong>Tredjepartscookies</strong>”). De kan också samla in information för att identifiera din Enhet,
    såsom IP-adress eller andra unika enhetsidentifierare.
    `,
  },
  subtitletwoDesc: {
    one: `En Cookie är en liten textfil som Webbplatsen begär att få spara på din Enhet och som
    samlar in uppgifter. Cookies kan inte sprida virus eller andra skadliga program. Cookies
    används bland annat för att du ska ha möjlighet att använda Webbplatsens tjänster,
    möjliggöra spårning av din användning av Webbplatsen, spara information som du har
    matat in eller för att komma ihåg dina preferenser, t.ex. språkinställningar. Cookies
    registrerar inte vem du är eller var du bor, men de kan berätta vilka webbplatser du har
    besökt på din Enhet.`,
    two: "Det finns olika typer av Cookies:",
    three: {
      1: "(i) Sessionscookies: Dessa sparas tillfälligt på din Enhet när du besöker vår Webbplats och tas bort när du stänger ner din webbläsare.",
      2: "(ii) Permanenta cookies: Dessa finns kvar på din Enhet till dess att de slutar gälla alternativt till dess du själv tar bort dem.",
    },
    four: `Ovan nämnda två typer av Cookies kan i sin tur delas in i två kategorier: (a)
    Förstapartscookies som placeras på din Enhet av oss i egenskap av
    webbplatsinnehavare när du surfar på vår Webbplats och (b) Tredjepartscookies.
    Tredjepartscookies placeras av en annan webbplats som tillhör våra samarbetspartners
    och ger oss en bild av dina surfvanor, de används för att kunna ge dig anpassat innehåll
    och för vår webbstatistik. Det finns möjlighet att blockera just denna typ av Cookies i
    din webbläsare genom inställningarna i din Enhet.`,
  },
  subtitlethreeDesc: {
    one: `Vi använder Cookies för att vi ska kunna göra Webbplatsen enklare och säkrare för dig
    att använda, få information om hur du använder vår Webbplats samt förbättra de
    tjänster vi erbjuder dig. `,
  },
  subtitlefourDesc: {
    one: `I vissa fall kan vår användning av Cookies innebära att vi behandlar dina
    personuppgifter. I dessa sammanhang följer vi våra principer för behandling av
    personuppgifter, vilket du kan läsa mer om i vår Integritetspolicy.`,
    two: `Den lagliga grunden för vår behandling av dina personuppgifter genom användandet av
    Cookies utgörs av det samtycke som inhämtas från dig när du godkänner vår
    användning av Cookies via cookiebannern. För sådana Cookies som är absolut
    nödvändiga för att vi ska kunna tillhandahålla de tjänster som du uttryckligen har
    begärt, inhämtas inte ditt samtycke. Denna behandling av uppgifter baseras istället på
    vårt berättigade intresse av att säkerställa korrekt teknisk funktion, åtkomst och
    användning av Webbplatsen samt att tillhandahålla de tjänster som du uttryckligen
    begärt.`,
    three: `I vissa fall använder vi oss av så kallade personuppgiftsbiträden som hjälper oss med
      statistik och analys. När vi delar med oss av dina uppgifter till dem kommer dessa att
      användas för samma ändamål som vi från början samlade in dem för.`,
    four: `Vi behåller dina cookieuppgifter i upp till tre år efter att de senast aktiverades (vid ditt
      senaste besök på vår Webbplats). Cookies som placeras av våra samarbetspartners via
      vår Webbplats administreras inte av oss utan av aktuell samarbetspartner – du kan läsa
      om hur du tar del av deras respektive cookiepolicyer under avsnitt 6.`,
  },
  subtitlefiveDesc: {
    one: `Vi har delat in våra Cookies i tre olika kategorier. Du kan samtycka till alla eller endast
    vissa kategorier av Cookies. När du samtycker till en viss kategori, godkänner du alla
    Cookies i den kategorin. Nedan kan du se de olika kategorierna av Cookies som vi
    använder samt hur vi använder informationen som samlas in.`,
    two: `Gå till Cookie-inställningarna i fliken längst ned på sidan för att se en lista med
    fullständig information om samtliga Cookies som används på Webbplatsen.`,
    three: {
      title: "Nödvändiga Cookies:",
      desc: ` Nödvändiga Cookies hjälper till att få vår Webbplats att fungera genom att aktivera
      grundläggande funktioner. De används vanligtvis endast när du använder en funktion
      på Webbplatsen som behöver ett svar, t.ex. när du ställer in val av Cookies. Likaså gör
      dessa Cookies det möjligt att navigera mellan olika sidor, ställa in personliga
      preferenser, t.ex. språk, samt ge dig åtkomst till säkra områden på Webbplatsen. Dessa
      Cookies är nödvändiga för att Webbplatsen ska fungera korrekt och du kan inte avvisa
      dessa Cookies när du använder vår Webbplats. Nödvändiga Cookies lagrar ingen
      personligt identifierbar information. `,
    },
    four: {
      title: "Funktionella Cookies :",
      desc: ` Funktionella Cookies hjälper Webbplatsen att erbjuda bättre funktionalitet och
      personlig anpassning. Dessa Cookies används t.ex. för att komma ihåg dina tidigare val
      när du använt våra tjänster (såsom språkinställning). Om du inte tillåter funktionella
      Cookies kan vissa av eller alla dessa tjänster sluta att fungera korrekt. Denna kategori
      kan inkludera Tredjepartscookies. Du kan välja att avvisa dessa Cookies i
      cookiebannern. `,
    },
    five: {
      title: "Prestandacookies:",
      desc: ` Prestandacookies tillåter oss att räkna antal besök och hur du hittade till vår Webbplats.
      De hjälper oss att veta vilka sidor som är mest besökta och hur besökare navigerar på
      Webbplatsen, för att vi ska kunna sammanställa statistik och på så sätt förbättra hur
      innehållet presenteras på vår Webbplats. Vi sparar information om t.ex. vilka länkar du
      har klickat på och vilken region du befinner dig i. Informationen som Prestandacookies
      samlar in är aggregerad och därför helt anonym. Denna kategori kan inkludera
      Tredjepartscookies. Du kan välja att avvisa dessa Cookies i cookiebannern.`,
    },
  },
  subtitlesixDesc: {
    one: `Oavsett om de Cookies som används på vår Webbplats innebär
    personuppgiftsbehandling eller inte behöver vi ditt samtycke för att placera Cookies
    på din Enhet. Du samtycker genom att acceptera alla eller vissa kategorier av Cookies i
    den cookiebanner som visas när du besöker Webbplatsen. Nödvändiga Cookies som
    måste placeras på din Enhet för att vissa tjänster ska fungera kan du dock inte välja
    bort. `,
    two: `Du kan när som helst återkalla ditt samtycke till Cookies, se avsnitt 8 för information
    om hur du går till väga.`,
  },
  subtitlesevenDesc: {
    one: `Du har möjlighet att avstå från att lämna samtycke till Cookies (undantaget nödvändiga
      Cookies) och du kan även återkalla ditt samtycke. Detta gör du genom att antingen
      ändra dina samtyckesinställningar i cookiebannern eller i Enhetens webbläsare. Notera
      att ändrade cookieinställningar börjar gälla från och med tillfället för ändringen och
      påverkar inte tidigare insamlad information genom Cookies.`,
    two: `I dina webbläsarinställningar kan du blockera alla Cookies men du kan också välja att
    endast acceptera förstapartscookies eller välja att radera Cookies när du stänger ner
    din webbläsare. Tänk på att begränsning av Cookies kan påverka Webbplatsens
    funktionalitet. Kom ihåg att nödvändiga Cookies behövs om du fortfarande ska kunna
    använda vår Webbplats och våra tjänster.`,
    three: `Olika webbläsare använder sig av olika metoder för att hantera Cookies. Följ
      anvisningarna nedan, direkt från webbläsarnas tillverkare för att konfigurera
      inställningarna i din webbläsare.* Har du en dator med en nyare webbläsare kan du
      rensa Cookies genom att använda följande kortkommando: Ctrl+Shift+Delete.
      Observera att om du använder flera webbläsare, behöver du rensa Cookies i alla
      webbläsare du använder.`,
    four: {
      1: "Microsoft Internet Explorer",
      2: "Google Chrome",
      3: "Safari",
      4: "Firefox",
      5: "Microsoft Edge",
    },
    five: `Efter att ha rensat Cookies i webbläsaren får du upp vår cookiebanner igen och kan göra
    ett nytt val. `,
    six: `* Dessa länkar går till webbplatser från tredje part, över vilka vi inte har någon kontroll
      – vi kan därmed inte hållas ansvariga för eventuella felaktigheter i dessa.`,
  },
  subtitleeightDesc: {
    one: `Eventuella förändringar av hur vi använder Cookies kommer att publiceras här och vid
    behov uppmärksammas på vår Webbplats.`,
  },
  subtitlenineDesc: {
    one: `Om du har frågor eller synpunkter på vår Cookie Notice och/eller behandling av
    personuppgifter, är du alltid välkommen att kontakta oss via e-post`,
    link: "info@rdlaw.se.",
  },
  subtitleTenDesc: {
    one: `Vissa av de Cookies som aktiveras på din Enhet när du besöker vår Webbplats och
    samtycker till användning av Cookies ägs av våra samarbetspartners och består av så
    kallade Tredjepartscookies. Dessa samarbetspartners hjälper oss bland annat att
    urskilja besökare och samla in statistik om ditt användande av Webbplatsen. Sådana
    samarbetspartners kan använda uppgifter som samlats in av deras Cookies för andra
    ändamål än de som beslutats av oss, om du samtycker till dessa Cookies gäller
    respektive samarbetspartners cookiepolicy och policy för behandling av
    personuppgifter. Du hittar länkar till dessa i vår cookie-lista, se avsnitt 5 i denna Cookie
    Notice. Vårt gemensamma personuppgiftsansvar med dessa samarbetspartners
    innebär att vi fortsätter att vara ansvariga för våra samarbetspartners användning av
    dina personuppgifter som behandlas för vårt gemensamma ändamål medan de är
    enskilt ansvariga om de behandlar uppgifter för andra ändamål än de som beslutats av
    oss. Du kan läsa mer om hur våra samarbetspartners gör skillnad mellan vårt delade
    ansvar och deras eget ansvar på respektive samarbetspartners webbplats.`,
  },
};
const termsAndConditionsPage = {
  title: "ROSHOLMDELL ADVOKATBYRÅS ALLMÄNNA VILLKOR (2023:1)",
  subtitleone: "TILLÄMPNING",
  subtitletwo: "UPPDRAGET OCH DESS OMFATTNING",
  subtitlethree: "UPPDRAGS ANTAGANDE OCH UPPHÖRANDE",
  subtitlefour: "IDENTIFIERING OCH INTRESSEKONFLIKTER",
  subtitlefive: "BEHANDLING AV PERSONUPPGIFTER",
  subtitlesix: "TYSTNADSPLIKT OCH INFORMATIONSPLIKT",
  subtitleseven: "ELEKTRONISK KOMMUNIKATION",
  subtitleeight: "FÖRVARANDE AV MATERIAL OCH ARKIVERING",
  subtitlenine: "IMMATERIELLA RÄTTIGHETER",
  subtitleten: "ARVODE OCH KOSTNADER",
  subtitleeleven: "FAKTURERING OCH BETALNING",
  subtitletwelve: "ANSVARSBEGRÄNSNING",
  subtitlethirteen: "KLAGOMÅL",
  subtitlefourteen: "TILLÄMPLIG LAG OCH TVISTELÖSNING",
  subtitleoneDesc: {
    one: `Dessa allmänna villkor gäller för de tjänster som
    RosholmDell Advokatbyrå AB (”<strong>RosholmDell</strong>” eller
    ”<strong>vi</strong>”) tillhandahåller på uppdrag av kunder (”<strong>Kunden</strong>”
    respektive ”<strong>Uppdraget</strong>”). Utöver dessa allmänna
    villkor gäller Sveriges Advokatsamfunds Vägledande
    regler om god advokatsed (”<strong>God advokatsed</strong>”) samt
    andra regler om advokatverksamhet föreskrivna i lag
    eller annan författning.`,
  },
  subtitletwoDesc: {
    one: ` Vid början av Uppdraget kommer RosholmDell och
    Kunden överens om Uppdragets omfattning och
    utförande. Omfattning och utförande kan därefter
    ändras. Våra tjänster tillhandahålls utifrån de
    omständigheter, fakta och instruktioner som Kunden
    tillhandahållit i det enskilda Uppdraget. Det förutsätts
    att informationen är fullständig och korrekt. Om inte
    Kunden ger specifika instruktioner avseende
    bemanningen kommer varje Uppdrag bemannas på det
    sätt som bedöms mest effektivt för Kunden.
    RosholmDell har rätt att anlita extern expertis i den
    utsträckning som bedöms nödvändig för utförandet av
    Uppdraget.`,
    two: ` Våra tjänster är endast avsedda för Kunden och de
    ändamål de tillhandahållits för. Vi tar inget ansvar om
    våra tjänster används för andra ändamål än vad de är
    avsedda för. Kunden kan endast förlita sig på
    information som presenteras i slutgiltiga versioner som
    skickas från oss. Utkast utgör endast preliminära
    bedömningar. Innehåll publicerat i sociala medier,
    nyhetsbrev eller andra utskick riktade till allmänheten
    utgör aldrig rådgivning. Även om vi angett en
    uppfattning om hur utfallet av ett Uppdrag kan komma
    att bli, har vi inget ansvar om detta resultat inte uppnås.`,
    three: `Tjänsterna baseras på svensk, gällande rätt.
    Ingen rådgivning lämnas direkt från oss avseende
    förhållanden i utländsk rätt eller skatterätt. Har vi
    förmedlat kontakt till rådgivare från andra
    jurisdiktioner eller med kompetens inom skatterätt
    utgör deras rådgivning aldrig rådgivning från
    RosholmDell.`,
  },
  subtitlethreeDesc: {
    one: `Vi är inte förpliktade att anta ett Uppdrag utan kan
    avböja ett Uppdrag utan att uppge skäl. God advokatsed
    reglerar under vilka omständigheter RosholmDell har
    rätt eller är skyldig att frånträda ett Uppdrag. Vid
    frånträdande av Uppdrag är Kunden skyldig att erlägga
    betalning för det arbete som utförs och för de utlägg
    som görs fram tills Uppdragets upphörande.`,
    two: `Kunden har rätt att när som helst skriftligen begära
    att RosholmDell frånträder Uppdraget. Kunden ska
    dock erlägga betalning för det arbete som utförts och
    för de utlägg som gjorts fram till Uppdragets
    upphörande.`,
  },
  subtitlefourDesc: {
    one: `Vi måste för vissa Uppdrag kontrollera våra
    Kunders identitet och ägarförhållanden samt arten av
    och syftet med Uppdraget. Vi kan be om uppgifter
    avseende Kunden och annan person som för Kundens
    räkning är involverade i Uppdraget samt varifrån medel
    och andra tillgångar kommer. Vidare kan vi vara
    skyldiga att verifiera information som lämnas till oss
    och därför inhämta information från externa källor.
    Information vi inhämtat för detta syfte kommer
    hanteras enligt lag och God advokatsed.`,
    two: `Vi är enligt lag skyldiga att anmäla misstankar om
    penningtvätt eller terrorismfinansiering till
    Finanspolisen. Vi är förhindrade enligt lag att
    underrätta Kunden om att misstankar föreligger samt
    om eventuell anmälan till Finanspolisen. I de fall
    misstankar om penningtvätt eller terrorismfinansiering
    föreligger är vi skyldiga att avböja eller frånträda
    Uppdraget.`,
    three: `nnan vi åtar oss Uppdrag gör vi i enlighet med God
    advokatsed en kontroll avseende eventuell
    intressekonflikt. Vi kommer därför efterfråga den
    information som krävs för att kunna avgöra om en
    faktisk eller potentiell intressekonflikt föreligger.
    Sådana kontroller kommer även att göras under
    pågående Uppdrag om nya omständigheter
    framkommer.`,
  },
  subtitlefiveDesc: {
    one: `När RosholmDell utför Uppdrag behandlas
    personuppgifter. RosholmDell behandlar
    personuppgifter i enlighet med vad som framgår av
    den Integritetspolicy som finns publicerad på `,
    link: "rdlaw.se.",
  },
  subtitlesixDesc: {
    one: `RosholmDell omfattas, om inget annat följer av lag
    eller God advokatsed, av tystnadsplikt avseende
    uppgifter som erhållits från Kunden, gällande Kunden
    och dennes angelägenheter, vid utförande av
    Uppdraget. Om vi inom ett Uppdrag samverkar med
    andra rådgivare eller sakkunniga har vi rätt att lämna ut
    relevant information till dessa, för att de ska kunna
    lämna råd eller utföra tjänster.`,
    two: `Vi har i vissa fall en lagstadgad skyldighet att
    utlämna information till relevanta skattemyndigheter
    om Kundens momsnummer och de belopp vi har
    fakturerat till Kunden. Genom att anlita RosholmDell
    anses Kunden ha samtyckt till vi lämnar sådan
    information till relevanta skattemyndigheter i enlighet
    med aktuella regler.`,
  },
  subtitlesevenDesc: {
    one: `RosholmDell kommunicerar med Kunden på flera olika
    sätt, t.ex. via e-post och andra elektroniska
    kommunikationsvägar. Vi tillser att vi har alla erforderliga säkerhetssystem för detta. Dessa
    kommunikationssätt innebär dock risker som
    RosholmDell inte tar ansvar för. Eftersom vi använder
    säkerhetsanordningar kan viktig e-post hamna i t.ex.
    spamfilter eller blockeras av brandväggar. Kunden bör
    därför vid behov följa upp viktig e-postkorrespondens
    via telefon.`,
  },
  subtitleeightDesc: {
    one: `Vi kommer, i pappersform eller i elektronisk form,
    förvara eller hos tredje part förvara alla relevanta kopior
    av dokument som hanterats och genererats i Uppdraget.
    Dessa dokument arkiveras under den tid som bedöms
    lämplig med hänsyn till Uppdragets art, dock under
    minst den tid som påkallas av lag eller God advokatsed.`,
  },
  subtitlenineDesc: {
    one: `RosholmDell har äganderätt till samtliga immateriella
    rättigheter kopplat till arbetsresultat som vi genererat i
    ett Uppdrag. Kunden äger dock rätt att använda
    resultaten för de ändamål de tagits fram. Om inget annat
    avtalas får arbetsresultat som genererats av oss inte
    spridas till allmänheten, säljas eller användas för
    marknadsföringsändamål.`,
  },
  subtitletenDesc: {
    one: ` Våra arvoden överensstämmer med reglerna och
    principerna enligt God advokatsed. Om vi inte avtalat
    annat fastställs våra arvoden baserat på faktorer såsom
    Uppdragets omfattning, art, svårighetsgrad, den
    skicklighet och erfarenhet som Uppdraget krävt,
    Uppdragets värde, tidsfaktorer och uppnått resultat.
    Som stöd för fastställandet av arvodet anger vi internt
    ett timarvode för respektive jurist baserat på dennes
    kunskap, kompetens och erfarenhet. Normalt sker
    översyn och justering av timarvodena i början av varje
    kalenderår.`,
    two: `Om Uppdraget medger det kan RosholmDell
    lämna en första uppskattning av arvodet för Uppdraget.
    Eventuella arvodesuppskattningar ska inte ses som en
    arvodesbegränsning om vi inte uttryckt detta
    skriftligen. Arvodesuppskattningar kan ändras vid
    ändrade omständigheter.`,
    three: `Utöver arvode debiterar vi för nödvändiga
    kostnader och utlägg som uppkommer i samband med
    Uppdragets utförande. Sådana nödvändiga kostnader
    innefattar exempelvis registreringsavgifter, bud, resor
    samt arvode till externa rådgivare och fackmän. Om ett
    ärende inom ett Uppdrag avgörs i rättegång eller annat
    liknande förfarande och vårt arbete motsvarar ett högre
    arvode än vad Kunden tillerkänns i rättegången,
    förbehåller vi oss rätten att fakturera det högre
    beloppet. Mervärdesskatt tillkommer om RosholmDell
    är skyldig att debitera sådan.`,
  },
  subtitleelevenDesc: {
    one: `Om inget annat avtalas faktureras upparbetat arvode
    och kostnader löpande månadsvis. Fakturor ska betalas
    senast 30 dagar efter fakturans datum. Vid försenad
    betalning utgår dröjsmålsränta enligt räntelagen
    (1975:635).`,
  },
  subtitletwelveDesc: {
    one: `Vi ansvarar enbart för skada som Kunden vållats
    till följd av försummelse från vår sida vid utförande av
    Uppdraget. Ansvaret är begränsat till ett belopp
    motsvarande högst fem gånger det arvode exklusive
    mervärdesskatt som Kunden erlagt för Uppdraget. Vi
    ansvarar vidare endast för den direkta skada som
    Kunden faktiskt lider. Ansvaret reduceras därmed med
    alla belopp som kan utfås från försäkring eller tredje
    part. Ansvaret omfattar heller inte minskning eller
    bortfall av omsättning, utebliven vinst eller annan
    indirekt skada. RosholmDells ansvar omfattar inte
    skador uppkomna hos tredje part. RosholmDells
    medarbetare ansvarar aldrig personligen för skador som
    orsakas Kunden. Krav mot RosholmDell preskriberas
    inom tre år från anspråkets uppkomst om inte talan har
    väckts inom denna tid.`,
  },
  subtitlethirteenDesc: {
    one: `Om Kunden är missnöjd med våra tjänster och vill
    framställa klagomål eller krav ska Kunden kontakta
    RosholmDells VD. VD och för Uppdraget ansvarig
    jurist utreder klagomålet och svarar på Kundens frågor.
    Vid krav mot RosholmDell ska en skriftlig redogörelse
    av den påstådda försummelsen och den förväntande
    skadan skickas till VD.`,
  },
  subtitlefourteenDesc: {
    one: `Svensk lag ska tillämpas på Uppdraget. Tvister
    som uppstår med anledning av Uppdraget ska slutligen
    avgöras genom skiljedom enligt Regler för Förenklat
    Skiljeförfarande för Stockholms Handelskammares
    Skiljedomsinstitut. Skiljeförfarandets säte ska vara
    Växjö.`,
    two: `Parterna förbinder sig, utan begränsningar i tiden,
    att inte avslöja förekomsten av eller innehållet i en
    skiljedom kopplat till Uppdraget eller information om
    förhandlingar, skiljeförfarande eller medling kopplat
    till Uppdraget. Detta gäller dock inte om en rättighet
    eller skyldighet att avslöja informationen följer av
    bindande författning, myndighetsbeslut eller God
    advokatsed. Det gäller inte heller vid klandertalan eller
    annan prövning i domstol som har ett samband med
    tvisten.`,
    three: `Om tvist uppstår mellan en Kund som är
    konsument och RosholmDell där samförståndslösning
    inte kan nås, kan Kunden ha rätt att få tvisten prövad av
    Advokatsamfundets konsumenttvistnämnd. Med
    konsument avses en fysisk person som agerar för
    ändamål som faller utanför närings- eller
    yrkesverksamhet. Mer information inklusive
    kontaktuppgifter till Konsumenttvistnämnden finns på
    Konsumenttvistnämndens webbplats: `,
    link: "advokatsamfundet.se/konsumenttvistnamnden/.",
  },
};
export const SwedishStrings = {
  common,
  routeNames,
  homePage,
  aboutPage,
  careerPage,
  ourStoryPage,
  jobpositionPage,
  newsAndInsightsPage,
  servicePage,
  contactPage,
  disputeResolutionPage,
  commercialIpTechnology,
  complainceAndRegulatoryPage,
  corporateAndEquityPage,
  startupGrowthAndVenturesPage,
  environmentalPage,
  employmentPage,
  mergersAndAcquisitionsPage,
  publicProcurementPage,
  railwayAndPublicTransportPage,
  realEstateAndConstructionPage,
  whistleBlowingSolutionPage,
  employmentLawAsaServicePage,
  dpoAsaServicePage,
  whistleblowerPolicyPage,
  privacyNoticePage,
  cookieNoticePage,
  termsAndConditionsPage,
  ipportfolioManagementPage,
};
