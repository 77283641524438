import React from "react";
import styles from "./styles.module.css";
import Button from "../button";
import Modal from "react-modal";
import { useAppData } from "../../providers/AppDataProvider";

const PopUP = (props) => {
  const { strings } = useAppData();
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      border: "none",
      padding: "0",
    },
  };
  return (
    <Modal isOpen={props.open} style={customStyles} ariaHideApp={false}>
      <div className={styles.popupContainerStyle}>
        <p className={styles.msgTextStyle}>
          <span className={styles.thankYouTextStyle}>
            {strings.contactPage.thankYou}
          </span>
          {strings.contactPage.thankyou2}
        </p>
        <Button
          buttonName={strings.contactPage.okay}
          btnStyle={styles.btnStyle}
          onClick={props.onClick}
        />
      </div>
    </Modal>
  );
};

export default PopUP;
