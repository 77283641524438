import React from "react";
import styles from "./styles.module.css";
import { whiteArrowRight } from "../../resources/images";
import moment from "moment";

const NewsCard = (props) => {
  return (
    <div className={styles.cardOuterContainerStyle} onClick={props.onClick}>
      <p className={styles.cardDateTextStyle}>
        {moment(props.date).format("MMMM YYYY")}
      </p>
      <div className={styles.newsImgWrapperStyle}>
        <img src={props.image} className={styles.imgNewsStyle} />
        <div className={styles.imageBackgoundHoverStyle}>
          <div className={styles.imgHoverStyle}>
            <img src={whiteArrowRight} className={styles.imgNewsStyle} />
          </div>
        </div>
      </div>
      <div className={styles.cardDescContainerStyle}>
        <p className={styles.cardDateMobileTextStyle}>
          {moment(props.date).format("MMMM YYYY")}
        </p>
        <p className={styles.cardDescHeaderTextStyle}>{props.headerText}</p>
        <p className={styles.cardDescTextStyle}>{props.headerContentText}</p>
      </div>
    </div>
  );
};

export default NewsCard;
