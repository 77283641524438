import React from "react";
import styles from "./styles.module.css";
const TextInput = (props) => {
  const { placeholder, value, inputStyle, onChange, type, ...rest } = props;
  return (
    <input
      placeholder={placeholder}
      value={value}
      className={[inputStyle, styles.inputStyle].join(" ")}
      onChange={(text) => onChange(text)}
      type={type || "text"}
      {...rest}
    />
  );
};

export default TextInput;
