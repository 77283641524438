import React from "react";
import styles from "./styles.module.css";
import { orangeRightUpArrow } from "../../resources/images";
import { useAppData } from "../../providers/AppDataProvider";
const ProfileCard = (props) => {
  const { currentLanguage } = useAppData();
  return (
    <div
      className={[props.cardContainerStyle, styles.cardContainerStyle].join(
        " "
      )}
      onClick={() => props.onClick()}
    >
      <div
        className={[props.imgContaineStyle, styles.imgContaineStyle].join(" ")}
      >
        <img
          src={props.data.image}
          className={[props.imgStyle, styles.imgStyle].join(" ")}
        />
      </div>
      <div>
        <div
          className={[props.titleContainer, styles.titleContainer].join(" ")}
        >
          <p
            className={[props.cardTitleStyle, styles.cardTitleStyle].join(" ")}
          >
            {props.data.name}
          </p>
          <div
            className={[
              props.arrWrapperStyle,
              styles.arrowImgConatinerStyle,
            ].join(" ")}
          >
            <img src={orangeRightUpArrow} className={styles.arrowImgStyle} />
          </div>
        </div>
        <p className={[props.cardDescStyle, styles.cardDescStyle].join(" ")}>
          {props.data.designation[currentLanguage]}
        </p>
      </div>
    </div>
  );
};

export default ProfileCard;
