import AppRoutes from "./routes";
import "./App.css";
import { AppDataProvider } from "./providers/AppDataProvider";
import { UserDataProvider } from "./providers/UserDataProvider";

function App() {
  return (
    <UserDataProvider>
      <AppDataProvider>
        <AppRoutes />
      </AppDataProvider>
    </UserDataProvider>
  );
}

export default App;
