// user endpoints
export const userLogin = "/auth/login";
//job endpoints
export const createJobPost = "/job/create";
export const getJobPostList = "/job/all";
export const deleteJobPost = "/job/delete/{id}";
export const getJobDetails = "/job/get?slug={slug}";
export const updateJobDetails = "/job/update";

//team endpoints
export const createTeamMember = "/team-member/create";
export const getTeamMembersList = "/team-member/all";
export const deleteTeamMember = "/team-member/delete/{id}";
export const updateTeamMember = "/team-member/update";
export const getTeamMembersDetails = "/team-member/get/{id}";
export const getContactlist = "/team-member/get/cp-list";

//blogpost endpoints
export const createPost = "/post/create";
export const postsList = "/post/all";
export const deletePost = "/post/delete/{id}";
export const updatePost = "/post/update";
export const getPostDetails = "/post/get";
export const getPostDetailsById = "/post/get-by-id?post_id={id}";

//testimonial endpoints
export const createTestimonial = "/testimonial/create";
export const testimonialList = "/testimonial/all";
export const deleteTestimonial = "/testimonial/delete/{id}";
export const updateTestimonial = "/testimonial/update";
//image Upload
export const imageUpload = "/upload/image";
// file upload
export const fileUpload = "/upload/file";
// contact us
export const contactForm = "/mail/send";
