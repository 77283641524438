import React from "react";
import { Navigate } from "react-router-dom";
import { useUserData } from "../providers/UserDataProvider";

const GuestGaurd = (props) => {
  const { accessToken } = useUserData();
  //authentication using token
  return (
    <>{accessToken ? <Navigate to={"/dashboard"} /> : <>{props.children}</>}</>
  );
};

export default GuestGaurd;
