import React, { useState, useEffect } from "react";
import styles from "./styles.module.css";
import { useAppData } from "../../providers/AppDataProvider";
import { DownArrowSvg, GlobeSvg, UpArrowSvg } from "../svgcomponents";
import { useNavigate, useLocation } from "react-router-dom";
import {
  arrowRightUp,
  logoDarkBlue,
  logoWhite,
  orangeCheckBox,
  circleEmpty,
} from "../../resources/images";
import MenuPopover from "../popover";

const Navbar = (props) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { strings, setModalOpen, currentLanguage, setLanguage, modalOpen } =
    useAppData();
  const [open, setOpen] = useState(false);
  const [isNavigating, setIsNavigating] = useState(false);
  const [menuName, setMenuName] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const divRefProfile = React.useRef();
  const openPop = Boolean(anchorEl);
  const id = openPop ? "simple-popover" : undefined;
  useEffect(() => {
    document.body.style.cssText =
      open || modalOpen ? "overflow:hidden" : "overflow:auto";
  }, [open]);

  // useEffect(() => {
  //   if (pathname === "/about") {
  //     setMenuName(strings.common.aboutUs.toLocaleUpperCase());
  //   } else if (pathname === "/careers") {
  //     setMenuName(strings.common.career.toLocaleUpperCase());
  //   } else if (pathname === "/contactus") {
  //     setMenuName(strings.common.contactUs.toLocaleUpperCase());
  //   } else if (pathname === "/news&insights") {
  //     setMenuName(strings.common.newsAndInsights.toLocaleUpperCase());
  //   } else if (pathname === "/ourteam") {
  //     setMenuName(strings.common.ourTeam.toLocaleUpperCase());
  //   } else if (pathname === "/services&solutions") {
  //     setMenuName(strings.common.serviceAndSolution.toLocaleUpperCase());
  //   } else {
  //     setMenuName(strings.common.menu.toLocaleUpperCase());
  //   }
  // }, [pathname]);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(divRefProfile.current);
  };
  return (
    <div className={isNavigating ? styles.navBarStyle : styles.navBarStyle2}>
      <div
        className={
          open || modalOpen
            ? styles.navBarMenuContainerStyle
            : styles.navBarMenuCloseContainerStyle
        }
      >
        <div className={styles.navOptionsMainContainerStyle}>
          <div
            className={styles.logoImgWrapperStyle}
            onClick={() => {
              navigate("/");
              setOpen(false);
              setModalOpen(false);
            }}
          >
            <img src={logoWhite} className={styles.imgStyle} />
          </div>
          <div
            className={styles.navRightContainerStyle}
            onClick={() => {
              setOpen(false);
              setModalOpen(false);
            }}
          >
            <div className={styles.navItemMenuWrapperStyle}>
              <p className={styles.navMenuCloseTextWhiteStyle}>
                {strings.common.close}
              </p>
              <div className={styles.navMenuImgWrapperStyle}>
                <UpArrowSvg color={styles.upArrowStyles} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.navBarOptionsContainerStyle}>
          <div className={styles.navBarOptionsWrapperStyle}>
            <div
              className={
                pathname === "/about"
                  ? styles.navOptionNotContainerStyle
                  : styles.navOptionHoverContainerStyle
              }
              onClick={() => {
                if (pathname === "/about") {
                  navigate("/about");
                  setOpen(false);
                  setModalOpen(false);
                } else {
                  setIsNavigating(true);
                  setTimeout(() => {
                    navigate("/about");
                    setOpen(false);
                    setModalOpen(false);
                    setIsNavigating(false);
                  }, 150);
                }
              }}
            >
              <p
                className={
                  pathname === "/about"
                    ? styles.navOptionSelectedStyle
                    : styles.navBarOptionTextStyle
                }
              >
                {strings.common.aboutUs}
              </p>
              {pathname === "/about" ? null : (
                <div className={styles.navOptionHoverArrImgWrapperStyle}>
                  <img src={arrowRightUp} className={styles.imgStyle} />
                </div>
              )}
            </div>
            <div
              className={
                pathname === "/careers"
                  ? styles.navOptionNotContainerStyle
                  : styles.navOptionHoverContainerStyle
              }
              onClick={() => {
                if (pathname === "/careers") {
                  navigate("/careers");
                  setOpen(false);
                  setModalOpen(false);
                } else {
                  setIsNavigating(true);
                  setTimeout(() => {
                    navigate("/careers");
                    setOpen(false);
                    setModalOpen(false);
                    setIsNavigating(false);
                  }, 150);
                }
              }}
            >
              <p
                className={
                  pathname === "/careers"
                    ? styles.navOptionSelectedStyle
                    : styles.navBarOptionTextStyle
                }
              >
                {strings.common.career}
              </p>
              {pathname === "/careers" ? null : (
                <div className={styles.navOptionHoverArrImgWrapperStyle}>
                  <img src={arrowRightUp} className={styles.imgStyle} />
                </div>
              )}
            </div>
            <div
              className={
                pathname === "/contactus"
                  ? styles.navOptionNotContainerStyle
                  : styles.navOptionHoverContainerStyle
              }
              onClick={() => {
                if (pathname === "/contactus") {
                  navigate("/contactus");
                  setOpen(false);
                  setModalOpen(false);
                } else {
                  setIsNavigating(true);
                  setTimeout(() => {
                    navigate("/contactus");
                    setOpen(false);
                    setModalOpen(false);
                    setIsNavigating(false);
                  }, 150);
                }
              }}
            >
              <p
                className={
                  pathname === "/contactus"
                    ? styles.navOptionSelectedStyle
                    : styles.navBarOptionTextStyle
                }
              >
                {strings.common.contactUs}
              </p>
              {pathname === "/contactus" ? null : (
                <div className={styles.navOptionHoverArrImgWrapperStyle}>
                  <img src={arrowRightUp} className={styles.imgStyle} />
                </div>
              )}
            </div>
            <div
              className={
                pathname === "/news&insights"
                  ? styles.navOptionNotContainerStyle
                  : styles.navOptionHoverContainerStyle
              }
              onClick={() => {
                if (pathname === "/news&insights") {
                  navigate("/news&insights");
                  setOpen(false);
                  setModalOpen(false);
                } else {
                  setIsNavigating(true);
                  setTimeout(() => {
                    navigate("/news&insights");
                    setOpen(false);
                    setModalOpen(false);
                    setIsNavigating(false);
                  }, 150);
                }
              }}
            >
              <p
                className={
                  pathname === "/news&insights"
                    ? styles.navOptionSelectedStyle
                    : styles.navBarOptionTextStyle
                }
              >
                {strings.common.newsAndInsights}
              </p>
              {pathname === "/news&insights" ? null : (
                <div className={styles.navOptionHoverArrImgWrapperStyle}>
                  <img src={arrowRightUp} className={styles.imgStyle} />
                </div>
              )}
            </div>
            <div
              className={
                pathname === "/ourteam"
                  ? styles.navOptionNotContainerStyle
                  : styles.navOptionHoverContainerStyle
              }
              onClick={() => {
                if (pathname === "/ourteam") {
                  navigate("/ourteam");
                  setOpen(false);
                  setModalOpen(false);
                } else {
                  setIsNavigating(true);
                  setTimeout(() => {
                    navigate("/ourteam");
                    setOpen(false);
                    setModalOpen(false);
                    setIsNavigating(false);
                  }, 150);
                }
              }}
            >
              <p
                className={
                  pathname === "/ourteam"
                    ? styles.navOptionSelectedStyle
                    : styles.navBarOptionTextStyle
                }
              >
                {strings.common.ourTeam}
              </p>
              {pathname === "/ourteam" ? null : (
                <div className={styles.navOptionHoverArrImgWrapperStyle}>
                  <img src={arrowRightUp} className={styles.imgStyle} />
                </div>
              )}
            </div>
            <div
              className={
                pathname === "/services&solutions"
                  ? styles.navOptionNotContainerStyle
                  : styles.navOptionHoverContainerStyle
              }
              onClick={() => {
                if (pathname === "/services&solutions") {
                  navigate("/services&solutions");
                  setOpen(false);
                  setModalOpen(false);
                } else {
                  setIsNavigating(true);
                  setTimeout(() => {
                    navigate("/services&solutions");
                    setOpen(false);
                    setModalOpen(false);
                    setIsNavigating(false);
                  }, 150);
                }
              }}
            >
              <p
                className={
                  pathname === "/services&solutions"
                    ? styles.navOptionSelectedStyle
                    : styles.navBarOptionTextStyle
                }
              >
                {strings.common.serviceAndSolution}
              </p>
              {pathname === "/services&solutions" ? null : (
                <div className={styles.navOptionHoverArrImgWrapperStyle}>
                  <img src={arrowRightUp} className={styles.imgStyle} />
                </div>
              )}
            </div>
          </div>
          <div className={styles.navBarRightOptionsContainerStyle}>
            <div className={styles.navBarPolicyContainerStyle}>
              <p
                className={styles.navBarPolicyTextStyle}
                onClick={() => {
                  if (pathname === "/terms&conditions") {
                    navigate("/terms&conditions");
                    setOpen(false);
                    setModalOpen(false);
                  } else {
                    setIsNavigating(true);
                    setTimeout(() => {
                      navigate("/terms&conditions");
                      setOpen(false);
                      setModalOpen(false);
                      setIsNavigating(false);
                    }, 150);
                  }
                }}
              >
                {strings.common.termsAndCondition}
              </p>
              <p
                className={styles.navBarPolicyTextStyle}
                onClick={() => {
                  if (pathname === "/privacynotice") {
                    navigate("/privacynotice");
                    setOpen(false);
                    setModalOpen(false);
                  } else {
                    setIsNavigating(true);
                    setTimeout(() => {
                      navigate("/privacynotice");
                      setOpen(false);
                      setModalOpen(false);
                      setIsNavigating(false);
                    }, 150);
                  }
                }}
              >
                {strings.common.privacyNotice}
              </p>
              <p
                className={styles.navBarPolicyTextStyle}
                onClick={() => {
                  if (pathname === "/cookienotice") {
                    navigate("/cookienotice");
                    setOpen(false);
                    setModalOpen(false);
                  } else {
                    setIsNavigating(true);
                    setTimeout(() => {
                      navigate("/cookienotice");
                      setOpen(false);
                      setModalOpen(false);
                      setIsNavigating(false);
                    }, 150);
                  }
                }}
              >
                {strings.common.cookieNotice}
              </p>
              <p
                className={styles.navBarPolicyTextStyle}
                onClick={() => {
                  if (pathname === "/whistleblowerpolicy") {
                    navigate("/whistleblowerpolicy");
                    setOpen(false);
                    setModalOpen(false);
                  } else {
                    setIsNavigating(true);
                    setTimeout(() => {
                      navigate("/whistleblowerpolicy");
                      setOpen(false);
                      setModalOpen(false);
                      setIsNavigating(false);
                    }, 150);
                  }
                }}
              >
                {strings.common.whistleBlowerPolicy}
              </p>
            </div>
            <div className={styles.navBarOptionsLanguageContainerStyle}>
              <div
                className={styles.navBarOptionsLangWrapperStyle}
                onClick={() => setLanguage("en")}
              >
                {currentLanguage === "en" && (
                  <GlobeSvg color={styles.globeColor} />
                )}
                <p
                  className={
                    currentLanguage === "en"
                      ? styles.languageSelectedTextStyle
                      : styles.languageTextStyle
                  }
                >
                  {strings.common.english}
                </p>
              </div>
              <div
                className={styles.navBarOptionsLangWrapperStyle}
                onClick={() => setLanguage("sw")}
              >
                {currentLanguage === "sw" && (
                  <GlobeSvg color={styles.globeColor} />
                )}
                <p
                  className={
                    currentLanguage === "sw"
                      ? styles.languageSelectedTextStyle
                      : styles.languageTextStyle
                  }
                >
                  {strings.common.swedish}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={
          open
            ? styles.navMainCloseContainerStyle
            : props.blueNav
            ? styles.navBlueMainContainerStyle
            : props.whiteLogoNav
            ? styles.navMainWhiteLogoContainerStyle
            : styles.navMainContainerStyle
        }
      >
        <div
          className={
            pathname === "/"
              ? styles.logoImgWrapper2Style
              : styles.logoImgWrapperStyle
          }
          onClick={() => {
            if (pathname === "/") {
            } else {
              navigate("/");
              setOpen(false);
              setModalOpen(false);
            }
          }}
        >
          <img
            src={props.blueNav ? logoDarkBlue : logoWhite}
            className={styles.imgStyle}
          />
        </div>
        <div className={styles.navRightContainerStyle}>
          {pathname === "/" && (
            <div
              className={styles.navItemLangWrapperStyle}
              onClick={() => {
                if (pathname === "/contactus") {
                  navigate("/contactus");
                  setOpen(false);
                  setModalOpen(false);
                } else {
                  setIsNavigating(true);
                  setTimeout(() => {
                    navigate("/contactus");
                    setOpen(false);
                    setModalOpen(false);
                    setIsNavigating(false);
                  }, 150);
                }
              }}
            >
              <p
                className={
                  props.blueNav
                    ? styles.navLangTextBlueStyle
                    : styles.navLangTextWhiteStyle
                }
              >
                {strings.common.contactUs.toLocaleUpperCase()}
              </p>
            </div>
          )}
          <div className={styles.navRightContainerStyle}>
            {pathname === "/" && (
              <div
                className={styles.navItemLangWrapperStyle}
                ref={divRefProfile}
                onClick={() => handleClick()}
              >
                {/* <div className={styles.navlangImgWrapperStyle}>
                  <GlobeSvg
                    color={
                      props.blueNav
                        ? styles.globeBlueImgStyle
                        : styles.globeWhiteImgStyle
                    }
                  />
                </div> */}
                <p
                  className={
                    props.blueNav
                      ? styles.navLangTextBlueStyle
                      : styles.navLangTextWhiteStyle
                  }
                >
                  {currentLanguage === "en"
                    ? strings.common.eng
                    : strings.common.swe}
                </p>
              </div>
            )}
          </div>
          <div
            className={styles.navItemMenuWrapperStyle}
            onClick={() => {
              setOpen(true);
              setModalOpen(true);
            }}
          >
            <p
              className={
                props.blueNav
                  ? styles.navMenuTextBlueStyle
                  : styles.navMenuTextWhiteStyle
              }
            >
              {strings.common.menu}
            </p>
            <div className={styles.navMenuImgWrapperStyle}>
              <DownArrowSvg
                color={
                  props.blueNav
                    ? styles.downArrBlueImgStyle
                    : styles.downArrWhiteImgStyle
                }
              />
            </div>
          </div>
        </div>
      </div>
      <MenuPopover
        id={id}
        open={openPop}
        anchorEl={anchorEl}
        onClose={handleClose}
        position={"left"}
      >
        <div className={styles.popoverContainerStyle}>
          <div
            className={styles.popoverLangContainerStyle}
            onClick={() => setLanguage("en")}
          >
            <div className={styles.imageWrapperStyle}>
              <img
                src={currentLanguage === "en" ? orangeCheckBox : circleEmpty}
                className={styles.imgStyle}
              />
            </div>
            <p
              className={
                currentLanguage === "en"
                  ? styles.popOverSelectTextStyle
                  : styles.popoverTextSTyle
              }
            >
              {strings.common.english}
            </p>
          </div>
          <div
            className={styles.popoverLangContainerStyle}
            onClick={() => setLanguage("sw")}
          >
            <div className={styles.imageWrapperStyle}>
              <img
                src={currentLanguage === "sw" ? orangeCheckBox : circleEmpty}
                className={styles.imgStyle}
              />
            </div>
            <p
              className={
                currentLanguage === "sw"
                  ? styles.popOverSelectTextStyle
                  : styles.popoverTextSTyle
              }
            >
              {strings.common.swedish}
            </p>
          </div>
        </div>
      </MenuPopover>
    </div>
  );
};

export default Navbar;
