import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import commonStyles from "../../../components/css/common.module.css";
import Navbar from "../../../components/navbar";
import Footer from "../../../components/footer";
import { useAppData } from "../../../providers/AppDataProvider";
import {
  downOrangeSmallArrow,
  plusBlackIcon,
  CarolinaThoft,
  orangeCheckBox,
  circleEmpty,
} from "../../../resources/images";
import ProfileCard from "../../../components/profilecard";
import MenuPopover from "../../../components/popover";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const EmploymentLawAsaServicePage = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { strings, modalOpen, focus, setFocus } = useAppData();
  const [includes, setIncludes] = useState(false);
  const [benefits, setBenefits] = useState(false);
  const [experiance, setExperiance] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const divRefProfile = React.useRef();
  const openPop = Boolean(anchorEl);
  const id = openPop ? "simple-popover" : undefined;
  useEffect(() => {
    setFocus(true);
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [modalOpen]);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(divRefProfile.current);
  };
  const userData = {
    name: "Carolina Thoft",
    designation: "Partner, Responsible Helsingborg Office",
    image: CarolinaThoft,
    email: "carolina.thoft@rdlaw.se",
    mobile: "+46 705 556 679",
  };
  const Solutions = [
    {
      title: strings.servicePage.whistleblowingSolution,
      url: "/services&solutions/whistleblowingsolution",
    },
    {
      title: strings.servicePage.employmentLawSolution,
      url: "/services&solutions/employmentlawasaservice",
    },
    {
      title: strings.servicePage.dpoAsService,
      url: "/services&solutions/dpoasaservice",
    },
    {
      title: strings.servicePage.ipportfoliomanagment,
      url: "/services&solutions/ipportfoliomanagement",
    },
  ];
  return (
    <div
      className={
        focus
          ? styles.employmentLawPageFocusContainerStyle
          : styles.employmentLawPageBlurContainerStyle
      }
    >
      <Helmet>
        <meta name="description" content="Useful Legal Services" />
        <meta name="keywords" content="Useful Legal Services" />
        <link
          rel="canonical"
          href="https://rosholmdell.se/services&solutions/employmentlawasaservice"
        />
        <title>RosholmDell | {strings.servicePage.employmentLawSolution}</title>
      </Helmet>
      <Navbar blueNav={true} />
      <div
        className={
          modalOpen
            ? commonStyles.commonMainDownContainerStyle
            : commonStyles.commonMainContainerStyle
        }
      >
        <div className={styles.pageInsideContainerStyle}>
          <div className={styles.headerContainerStyle}>
            <div className={styles.headerTopContainerStyle}>
              <div
                className={styles.linkContainerStyle}
                onClick={() => handleClick()}
                ref={divRefProfile}
              >
                <p className={styles.headerLinkListTextSTyle}>
                  {strings.servicePage.employmentLawSolution.toLocaleUpperCase()}
                </p>
                <div className={styles.arrowImgWrapperStyle}>
                  <img
                    src={downOrangeSmallArrow}
                    className={styles.arrImgStyle}
                  />
                </div>
              </div>
              <h3 className={styles.headerTextStyle}>
                {strings.employmentLawAsaServicePage.title}
                <span className={styles.headerTextOrangeStyle}>
                  {strings.employmentLawAsaServicePage.orangeText}
                </span>
                {strings.employmentLawAsaServicePage.title2}
              </h3>
            </div>
            <p className={styles.contentTextStyle}>
              {strings.employmentLawAsaServicePage.titleDesc}
            </p>
          </div>
          <div className={styles.middleContainerStyle}>
            <div className={styles.middleInsideContainerStyle}>
              <div
                className={
                  includes
                    ? styles.itemContainerStyle
                    : styles.itemContainerStyle2
                }
                onClick={() => {
                  setIncludes(!includes);
                }}
              >
                <p className={styles.contentTextStyle}>
                  {strings.employmentLawAsaServicePage.elaasIncludes}
                </p>

                <div
                  className={
                    includes
                      ? styles.contentContainerStyle
                      : styles.contentContentOpenStyle
                  }
                >
                  <p className={styles.contentTextStyle}>
                    {strings.employmentLawAsaServicePage.includesPoints.one}
                  </p>
                  <p className={styles.contentTextStyle}>
                    <span className={styles.contentOrangeTextStyle}>
                      {
                        strings.employmentLawAsaServicePage.includesPoints.two
                          .title
                      }
                    </span>
                    {
                      strings.employmentLawAsaServicePage.includesPoints.two
                        .desc
                    }
                  </p>
                  <p className={styles.contentTextStyle}>
                    <span className={styles.contentOrangeTextStyle}>
                      {
                        strings.employmentLawAsaServicePage.includesPoints.three
                          .title
                      }
                    </span>
                    {
                      strings.employmentLawAsaServicePage.includesPoints.three
                        .desc
                    }
                  </p>
                  <p className={styles.contentTextStyle}>
                    <span className={styles.contentOrangeTextStyle}>
                      {
                        strings.employmentLawAsaServicePage.includesPoints.four
                          .title
                      }
                    </span>
                    {
                      strings.employmentLawAsaServicePage.includesPoints.four
                        .desc
                    }
                  </p>
                  <p className={styles.contentTextStyle}>
                    <span className={styles.contentOrangeTextStyle}>
                      {
                        strings.employmentLawAsaServicePage.includesPoints.five
                          .title
                      }
                    </span>
                    {
                      strings.employmentLawAsaServicePage.includesPoints.five
                        .desc
                    }
                  </p>
                  <p className={styles.contentTextStyle}>
                    <span className={styles.contentOrangeTextStyle}>
                      {
                        strings.employmentLawAsaServicePage.includesPoints.six
                          .title
                      }
                    </span>
                    {
                      strings.employmentLawAsaServicePage.includesPoints.six
                        .desc
                    }
                  </p>
                  <p className={styles.contentTextStyle}>
                    <span className={styles.contentOrangeTextStyle}>
                      {
                        strings.employmentLawAsaServicePage.includesPoints.seven
                          .title
                      }
                    </span>
                    {
                      strings.employmentLawAsaServicePage.includesPoints.seven
                        .desc
                    }
                  </p>
                </div>
                {!includes && (
                  <div className={styles.imgWrapperStyle}>
                    <img src={plusBlackIcon} className={styles.imgStyle} />
                  </div>
                )}
              </div>
            </div>
            <div className={styles.middleInsideContainerStyle}>
              <div
                className={
                  benefits
                    ? styles.itemContainerStyle
                    : styles.itemContainerStyle2
                }
                onClick={() => {
                  setBenefits(!benefits);
                }}
              >
                <p className={styles.contentTextStyle}>
                  {strings.employmentLawAsaServicePage.benefitsOfElaas}
                </p>

                <div
                  className={
                    benefits
                      ? styles.contentContainerStyle
                      : styles.contentContentOpenStyle
                  }
                >
                  <p className={styles.contentTextStyle}>
                    {strings.employmentLawAsaServicePage.benefitsPoints.one}
                  </p>
                  <p className={styles.contentTextStyle}>
                    <span className={styles.contentOrangeTextStyle}>
                      {
                        strings.employmentLawAsaServicePage.benefitsPoints.two
                          .title
                      }
                    </span>
                    {
                      strings.employmentLawAsaServicePage.benefitsPoints.two
                        .desc
                    }
                  </p>
                  <p className={styles.contentTextStyle}>
                    <span className={styles.contentOrangeTextStyle}>
                      {
                        strings.employmentLawAsaServicePage.benefitsPoints.three
                          .title
                      }
                    </span>
                    {
                      strings.employmentLawAsaServicePage.benefitsPoints.three
                        .desc
                    }
                  </p>
                  <p className={styles.contentTextStyle}>
                    <span className={styles.contentOrangeTextStyle}>
                      {
                        strings.employmentLawAsaServicePage.benefitsPoints.four
                          .title
                      }
                    </span>
                    {
                      strings.employmentLawAsaServicePage.benefitsPoints.four
                        .desc
                    }
                  </p>
                  <p className={styles.contentTextStyle}>
                    <span className={styles.contentOrangeTextStyle}>
                      {
                        strings.employmentLawAsaServicePage.benefitsPoints.five
                          .title
                      }
                    </span>
                    {
                      strings.employmentLawAsaServicePage.benefitsPoints.five
                        .desc
                    }
                  </p>
                  <p className={styles.contentTextStyle}>
                    <span className={styles.contentOrangeTextStyle}>
                      {
                        strings.employmentLawAsaServicePage.benefitsPoints.six
                          .title
                      }
                    </span>
                    {
                      strings.employmentLawAsaServicePage.benefitsPoints.six
                        .desc
                    }
                  </p>
                </div>
                {!benefits && (
                  <div className={styles.imgWrapperStyle}>
                    <img src={plusBlackIcon} className={styles.imgStyle} />
                  </div>
                )}
              </div>
            </div>
            {/* <div className={styles.middleInsideContainerStyle}>
              <div
                className={
                  experiance
                    ? styles.itemContainerStyle
                    : styles.itemContainerStyle2
                }
                onClick={() => {
                  setExperiance(!experiance);
                }}
              >
                <p className={styles.contentTextStyle}>
                  {strings.employmentLawAsaServicePage.experianceElaas}
                </p>

                <div
                  className={
                    experiance
                      ? styles.contentContainerStyle
                      : styles.contentContentOpenStyle
                  }
                >
                  <p className={styles.contentTextStyle}>
                    {strings.employmentLawAsaServicePage.experiancePoints.one}
                  </p>
                  <p className={styles.contentTextStyle}>
                    {strings.employmentLawAsaServicePage.experiancePoints.two}
                  </p>
                </div>
                {!experiance && (
                  <div className={styles.imgWrapperStyle}>
                    <img src={plusBlackIcon} className={styles.imgStyle} />
                  </div>
                )}
              </div>
            </div> */}
          </div>
          <div className={styles.userContainerStyle}>
            <div className={styles.userDetailsStyle}>
              <a
                className={styles.userContactLinksTextStyle}
                href={`mailto:${userData.email}`}
              >
                {userData.email}
              </a>
              <a
                className={styles.userContactLinksTextStyle}
                href={`tel:${userData.mobile}`}
              >
                {userData.mobile}
              </a>
              <a
                className={styles.userContactLinksTextStyle}
                onClick={() => navigate("/contactus")}
              >
                {strings.common.writeToUsSmall}
              </a>
            </div>
            <div className={styles.cardContainerStyle}>
              <ProfileCard
                data={userData}
                cardContainerStyle={styles.cardStyle}
                arrWrapperStyle={styles.cardArrowStyle}
                onClick={() => console.log("c")}
              />
            </div>
          </div>
        </div>
        <Footer blueFooter={true} />
      </div>
      <MenuPopover
        id={id}
        open={openPop}
        anchorEl={anchorEl}
        onClose={handleClose}
        position={"center"}
      >
        <div className={styles.popoverContainerStyle}>
          {Solutions.map((item, index) => {
            return (
              <div
                className={styles.popoverLangContainerStyle}
                onClick={() => navigate(item.url)}
              >
                <div className={styles.imageWrapperStyle}>
                  <img
                    src={pathname === item.url ? orangeCheckBox : circleEmpty}
                    className={styles.imgStyle}
                  />
                </div>
                <p className={styles.popoverTextSTyle}>
                  {item.title.toLocaleUpperCase()}
                </p>
              </div>
            );
          })}
        </div>
      </MenuPopover>
    </div>
  );
};

export default EmploymentLawAsaServicePage;
