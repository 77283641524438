const common = {
  eng: "EN",
  swe: "SW",
  english: "ENGLISH",
  swedish: "SWEDISH",
  menu: "MENU",
  close: "CLOSE",
  dashboard: "Dashboard",
  aboutUs: "About Us",
  career: "Career",
  contactUs: "Contact Us",
  newsAndInsights: "News & Insights",
  ourTeam: "Our Team",
  serviceAndSolution: "Services & Solutions",
  termsAndCondition: "Terms & Conditions",
  privacyNotice: "Privacy Notice",
  cookieNotice: "Cookie Notice",
  whistleBlowerPolicy: "Whistleblower Policy",
  ourOffices: "Our Offices",
  vaxjo: "Växjö",
  helsinborg: "Helsingborg",
  kalmar: "Kalmar",
  malmo: "Malmö",
  stockholm: "Stockholm",
  pages: "Pages",
  socilaMedia: "Social Media",
  linkedin: "LinkedIn",
  instagram: "Instagram",
  links: "Links",
  copyrights: "© 2023 RosholmDell Advokatbyrå AB, All Rights Reserved",
  registrationNo: "Registration no. 556858-6290, VAT no. SE556858629001",
  emailCaps: "EMAIL",
  phoneNUmberCaps: "PHONE NO.",
  copied: "COPIED!",
  writeToUsSmall: "Write to us",
  noteText:
    "We regularly host workshops and seminars within the field. To stay informed, be sure to follow us on",
  readMore: "Read more ",
  here: "here",
  createJob: "Create Job Posting",
  joblist: "Job Postings List",
  updateJob: "Update Job Details",
  createNewsPost: "Update News & Insights Post",
  updateNewsPost: "Create News & Insights Post",
  newsPostList: "News & Insights List",
  updateTeam: "Update Team Member",
  createTeam: "Create Team Member",
  teamList: "Team Members List",
  updateTestimonial: "Update Testimonial",
  createTestimonial: "Create Testimonial",
  testimonialList: "Testimonials List",
  login: "Login",
  ourStory: "Our Story",
  competence: "Competence:",
  experiance: "Experience:",
  education: "Education:",
  address1: "Järnvägsgatan 10 B, floor no. 5, SE-252 78, Helsingborg",
};
const routeNames = {
  about: "/about",
};

const homePage = {
  headerText: "Useful Legal Services",
  buttonText: "GET STARTED",
};
const aboutPage = {
  heroHeaderText: "We exist to deliver ",
  heroHeaderText2: "Useful Legal Services",
  heroContentHeader1: "Succeeding when our customers do",
  heroContentHeader2: "Full-service",
  heroContentHeader3: "True partnership",
  heroContentHeader4: "LEAN",
  heroContent1: `We put our customers' success at the forefront. We approach every matter with a solution-driven mindset, prioritizing simplicity even in the face of complexity. We strive to deliver high-quality products that seamlessly integrate into our customer’s businesses, making implementation effortless. That is what we call Useful Legal Services.`,
  heroContent2: `Our team of dedicated lawyers specializes in delivering Useful Legal Services across a wide range of business law fields. We serve successful national and international customers. Our customers are everything from startups, scaleups, SME:s, municipalities and other government bodies, to large international industry groups. We serve them to simplify their business and effectively managing their opportunities and challenges in both local and international contexts. `,
  heroContent3:
    "Our firm is based on a true partnership structure. This means that our earnings are shared equally between the partners. This also means that associates with the same amount of experience are paid the same salary. With a true partnership structure, we foster customer focus and teamwork, enabling us to consistently serve our customers with the most effective services. The foundation of this structure is built upon accountability and reliability.",
  heroContent4:
    "We deploy LEAN principles throughout the organization, enabling focus on reducing waste, streamlining procedures, and improving product quality. By deploying these principles, we allow us to deliver Useful Legal Service to our customers.",
  link1: "SERVICES & SOLUTIONS",
  link2: "OUR TEAM",
  link3: "WORK AT ROSHOLMDELL",
  link4: "OUR STORY",
  testimonialHeader: "Testimonials",
  testimonialText1:
    "“In RosholmDell, we at Genova have found a collaborative partner who is responsive, curious and efficient. The advice from RosholmDell is extremely professional and direct. We are very happy about the competence RosholmDell contributes to the continued development of Genova’s operations.”",
  testimonialWriter: "- Henrik Sandstrom, Property manager Genova",
  contactus: "CONTACT US",
};
const careerPage = {
  headerText: "Build your ",
  headerText2: "career",
  headerText3: " with us",
  headerContentText: `Our customers' appreciation of our services is instrumental to our existence. We also attribute our success to the dedication of our team. We are proud of our committed team who embody our mission of providing Useful Legal Services across multiple fields of business law.`,
  contentText1:
    "To deliver high-quality Useful Legal Services, we constantly seek to collaborate with talented individuals. We appreciate individuals reasoning from first principles, continuously.",
  contentText2:
    "At RosholmDell, we define talent as the willingness to take ownership and responsibility. We pledge to motivate and support you in achieving your utmost potential and pursuing both personal and professional growth. For example, we develop our organization and each other with our own model: PACE. This is an internally developed model that enables continuous progress evaluations and encourages open discussions.",
  contentText3:
    "We are always seeking individuals who are eager to join us in our pursuit of growth and continuously improving our customer offerings. If you are interested in learning more about RosholmDell, we are interested in getting to know you.",
  readmore: "Read more",
  readless: "Read less",
  openPosition: "Open positions",
  position1: "Workplace-related investigation work (APU)",
  expiry1: "Apply by",
  position2: "Executive trainee",
  expiry2: "Apply by June 2023",
  position3: "Thesis internship",
  expiry3: "Apply throughout the year",
  ourstory: "OUR STORY",
  contactUs: "Contact Us.",
};
const ourStoryPage = {
  headerText: "RosholmDell: Our Story",
  buttontext: "READ MORE",
  subHeaderText: "Growth comes from stepping outside your comfort zone",
  headerContentText:
    "The founders of RosholmDell, Anders Rosholm and Martin Dell, met in the early 2000s. At that time, they were working with M&A and corporate commercial law at the law firm Mannheimer Swartling. During their years together, they discovered the importance of sincerity, customer focus and putting in hard work – for their customers and for their colleagues. They also realized that personal growth comes from stepping outside of one's comfort zone, and constantly “leaning in” when faced with new challenges. The idea is once also effectively captured by Frank Slootman who once said:",
  quoteText:
    "“If you are feeling comfortable, you are clearly not leaning in enough.”",
  itemHeader1: "Founded in a minute",
  item1Content1:
    "In 2010, Anders and Martin left their positions at the old firm to pursue new opportunities. Anders transitioned to corporate law as an in-house counsel at a large industry corporation, and Martin embarked on an entrepreneurial venture. The following year, Anders realized that he wanted to do something more, he called up Martin with an idea – to start their own law firm together. Without hesitation, Martin said yes.",
  item1Content2:
    "Their idea was simple to envision but complex to execute – provide high-quality legal services with a customer-centric approach. Their vision included a commitment to stay unpretentious, and to further down the line, offer employees autonomy as well as engaging and challenging roles. With only two customers and their unwavering partnership, Anders and Martin started building RosholmDell, from scratch.",
  itemHeader2: "Organic growth and strong recruitments",
  item2Content1: `Since the start, RosholmDell has grown organically. The growth has been fuelled by a steadily expanding team of highly skilled, dedicated, and loyal coworkers who have embodied the company-culture. This commitment from our employees has been a cornerstone of the firm's development and continues to drive its growth to this day.`,
  itemHeader3: "Our unique approach: LEAN",
  item3Content1: `Throughout the firm’s expansion, in providing high quality legal services, and managing the company, one specific method has been fundamental: the LEAN methodology.`,
  item3Content2: `At its core lies a mindset of minimizing waste, continuous improvement, and encouraging each other to constantly question ideas and operational models. The methodology also pushes the firm to constantly seek opportunities for further development.`,
  itemHeader4: "Our customer focused mindset: Useful Legal Services",
  item4Content1: `The LEAN-based approach is a key component in the firm’s continuous strive to serve customers in the most effective way possible. The unwavering commitment to the firm’s customers is captured in the mission of the firm:`,
  item4Content2: `“We deliver high-quality Useful Legal Services”`,
  item4Content3: `In order to deliver Useful Legal Services, we must maintain a mindset focused on the fact that a legally correct advice is not necessarily useful if it is not tailored to the individual customer's needs.`,
  item4Content4:
    "Delivering Useful Legal Services requires consideration of not only who the recipient is, but also other stakeholders and their relevant needs, perspectives, and unique circumstances.",
  itemHeader5: "Looking forward",
  item5Content1: `Today, we are honoured to have customers from around the world. The support we have received has been encouraging and we appreciate every milestone we have reached together with our customers, colleagues, and external partners.`,
  item5Content2:
    "As we move forward, the requirements on us as a firm will constantly increase. The digitalization will help us enhance our services but will also require smart implementation. Whatever the future holds, customer focus will remain crucial, as we are committed to serving all our existing and new customers with Useful Legal Services.",
};
const jobpositionPage = {
  headerText: "Are you our next ",
  headerText2: "Executive Trainee",
  headerText3: "?",
  headerContentText:
    "We educate the lawyers of the future. People with focus on results and the client. Lawyers who can handle the business law of the future. Are you driven to become the best at what you do and do you want to become the best in business law? Do you think it is important to understand concepts from the ground, think critically and own the details? Do you have a doer mentality? We will customize a position for you where you will work in close cooperation with one of our partners. The position will be customised with a focus on corporate commercial, corporate and M&A. At the same time, you will be broadly trained in problem-solving, leadership and business.",
  jobrequirements: "Job Requirements",
  jobrequirementsText1: `As a person, you must have a "doer" mentality and be responsible. You must want to understand concepts and phenomena fundamentally and strive for simple solutions, regardless of the complexity of the task. You are welcome to be critical of prevailing norms and challenge ideas.`,
  jobrequirementsText2: `You should have about 2 years left on your studies. You are studying Industrial Economics or any education in economics/business at bachelor's or master's level.`,
  jobresponsibilities: "Job Responsibilities",
  jobresponsibilitiesText1: `You will work very closely with the CEO and finance manager. You will learn and work practically with:`,
  jobresponsibilitiesText2: `Organizational and business development`,
  jobresponsibilitiesText3: `Market analyzes and presentations`,
  jobresponsibilitiesText4: `Financial analysis and data analysis`,
  jobresponsibilitiesText5: `Tactical sales work`,
  jobresponsibilitiesText6: `The scope of the position is part-time (50%) and is paid. Great flexibility in the arrangement of working hours will be given. The position also includes work outside the study semesters. Location for the position is Malmö. Certain work from home/at another location will also be possible.`,
  aboutRd: "About RosholmDell",
  aboutRdText1:
    "RosholmDell is a business law firm focusing on providing high-quality Useful Legal Services to our customers. Useful Legal Services means approaching every matter with a solution-driven mindset, prioritizing simplicity even in the face of complexity. We strive to deliver high-quality products that seamlessly integrate into our customer's businesses, making implementation effortless.",
  aboutRdText2:
    "We are a firm that is continuously developing for the better. We deploy LEAN principles throughout the organization, enabling focus on reducing waste, streamlining procedures, and improving product quality. By deploying these principles, we allow us to deliver Useful Legal Service to our customers.",
  aboutRdText3:
    "With just over 30 employees working together as one team from our offices in Malmö, Helsingborg, Växjö and Kalmar, we help approximately 400 customers on an ongoing basis. We help customers in national and international contexts, and we serve customers in all of Sweden and Europe.",
  cardText:
    "The deadline for applications is 15 April 2023. Applications are processed on an ongoing basis. You apply by sending your CV and a personal letter to",
  cardLink: "rekrytering@rdlaw.se.",
};
const newsAndInsightsPage = {
  headerText: "News & Insights",
  allposts: "ALL POSTS",
  news: "NEWS",
  insights: "INSIGHTS",
  loadMore: "LOAD MORE",
  seeLess: "SEE LESS",
};
const servicePage = {
  headerText: `We are dedicated to deploying our expertise and experiences with one focus – delivering high-quality`,
  headerOrangeText: `Useful Legal Services`,
  headerText2: "to our customers.",
  headerContentText:
    "We put our customers' success at the forefront. We approach every matter with a solution-driven mindset, prioritizing simplicity even in the face of complexity. We strive to deliver high-quality products that seamlessly integrate into our customer’s businesses, making implementation effortless. Explore our Services & Solutions.",
  ourservices: "Our Services",
  oursolutions: "Our Solutions",
  commercialIpTechnology: "Commercial, IP & Technology",
  disputeResolution: "Dispute Resolution",
  complainceAndRegulatory: "Compliance & Regulatory",
  corporateAndPrivate: "Corporate & Private Equity",
  startUp: "Startup, Growth & Ventures",
  environment: "Environment",
  employment: "Employment",
  mergerAndAcquition: "Mergers & Acquisitions",
  publicAndProcurement: "Public Procurement",
  railwayAndPublic: "Railway & Public Transport",
  realEstateAndConstruction: "Real Estate & Construction",
  whistleblowingSolution: "Whistleblowing Solution",
  employmentLawSolution: "Employment Law as a Service",
  dpoAsService: "DPO as a Service",
  ipportfoliomanagment: "IP Portfolio Management",
};
const contactPage = {
  contactPageHeaderText: "Get in touch with us",
  contactPageDescText:
    "Ready to address your legal needs with expertise and dedication, our team is here to provide Useful Legal Services. Please reach out to us. Fill in the contact form or chose the representative you would like to connect with.",
  contactNumber: "+46 470 78 88 55",
  writeToUsText: "WRITE TO US",
  WriteToUsSmall: "Write to us",
  yourFullName: "Your full name*",
  yourEmailId: "Your Email ID*",
  yourPhoneNumber: "Phone number*",
  subject: "Subject",
  yourMessage: "Your message here*",
  submit: "SUBMIT",
  offices: "Offices",
  notARobot: "I’m not a robot",
  termsAndConditionsText: "Please read our",
  termsAndConditionsLinkText: "Privacy Notice",
  termsAndConditionsEndText:
    "for further information on how RosholmDell process your personal information.",
  thankYou: "Thank you",
  thankyou2: " for writing to us, we will get back to you very soon!",
  okay: "OKAY",
};
const disputeResolutionPage = {
  author: "Johan Norrlin",
  mail: "johan.norrlin@rdlaw.se",
  mobileNo: "+46 702 748 529",
  designation: "Specialist Counsel",
  title: "With extensive experience in ",
  titleOrange1: " commercial dispute resolutions",
  and: " and ",
  titleOrange2: "litigations",
  dot: ", we promise expert advisory services",
  description1: `Our team consists of experienced lawyers with backgrounds from various positions in the judiciary system. The team serves various private and public customers across several industry sectors. With their experience, they efficiently advise in, for example, status assessments, as well as drafting and reviewing letters of demand, plaints, and other correspondences. The team also represents you in arbitrations and litigations, both in public courts and in alternative dispute resolution proceedings. We also guide you through mediation processes and settlement negotiations. We always seek efficient, cost-effective solutions, tailored to your objectives.`,
  subTitle1: "Dispute and Litigation Guidance",
  subTitle2: "Vast Experience and Tailored Advice",
  content1: [
    {
      header: "Procedural Status Assessments",
      text: ": We simplify your dispute and litigation matters by providing clear, tailored advice on your procedural position and possible paths ahead.",
    },
    {
      header: "Written Submissions and Correspondence",
      text: ": Our team drafts and reviews all documentation necessary for your proceeding. This includes correspondence and submissions such as plaints, letters of demand, and other critical documents required to present a compelling case on your behalf.",
    },
    {
      header: "Arbitration",
      text: ": We provide counsel before, during, and after your arbitration proceedings. Our services include handling proceedings according to specific procedural rules such as the Stockholm Chamber of Commerce rules (SCC), and various other arbitration rules, as well as managing relationships with arbitrating bodies throughout the process.",
    },
    {
      header: "Litigation",
      text: ": Our team guides you through every step of litigation: from determining where to file your lawsuit, identifying applicable laws, exploring negotiation or arbitration options, and ensuring timely action, to managing your case, forming strong legal arguments, and controlling costs during the litigation. Once a judgment is made, we are there to assist with enforcement, exploring appeal options, and clarifying post-judgment interests.",
    },
  ],
  content2: [
    {
      header: "Experience",
      text: ": Our team has extensive experience in commercial dispute resolution and litigation. Our lawyers, many of whom have served as associate and assistant judges, are ideally skilled to navigate you through every legal challenge.",
    },
    {
      header: "Prompt, Executive Advice",
      text: ": Our team is dedicated to delivering prompt advice, empowering swift decisions. We accomplish this through always working in small, tightly-knit teams focusing on the best outcome for your business.",
    },
    {
      header: "Tailored Services",
      text: ": We offer services tailored to your specific circumstances. Whether it is managing arbitration rules or other regulations, we devise solutions that meet your specific objectives.",
    },
    {
      header: "Collaborative Approach",
      text: ": To be able to provide you with the best possible solutions, we collaborate with specialists across related fields. We aim to work as an integrated part of your team and to always be close at hand, keeping you informed and updated from start to finish.",
    },
  ],
};
const commercialIpTechnology = {
  author: "Nicklas Lång",
  mail: "nicklas.lang@rdlaw.se",
  mobileNo: "+46 768 754 083 ",
  designation: "Senior Counsel",
  title: "We empower business growth and operational development within",
  titleOrange1: " commercial",
  title2: ",",
  titleOrange2: " IP",
  and: ", and ",
  titleOrange3: "technology law",
  description1: `At every stage of your business journey, we strive to simplify your business-related legal matters. From shaping commercial agreements to structuring efficient outsourcing arrangements, and from launching innovations to managing intangible assets, we serve you with swift, expert, advisory services. Furthermore, we are well-versed in e-commerce and IT-related matters, and we always personalize our approach to align our services with your unique needs and objectives.`,
  subTitle1: "Strategic and Operational Advice",
  subTitle2: "Management of your Technology and IP-Portfolio",
  subTitle3: "One Team for your Business Journey",
  subTitle4: "IP Portfolio Management Solution",
  content1: [
    {
      header: "Innovation Commercialization",
      text: ": Our approach is focused on helping you bring products and innovations to the market in an effective and efficient manner. We assist you in developing commercial strategies, address licensing opportunities as well as handling trademark registration and copyright matters.",
    },
    {
      header: "Outsourcing Arrangements",
      text: ": We assist in developing and refining outsourcing projects. Before, during, and after your outsourcing arrangements we help you with, for example, non-disclosure agreements and functional master service agreements.",
    },
    {
      header: "Operational Contracts",
      text: ": We help you with all legal matters related to the operation of your business. This includes crafting and reviewing contracts related to areas such as sales, distribution, manufacturing, supply chain, or research and development (R&D). ",
    },
    {
      header: "Facility Deliveries",
      text: ": We guide you through multifaceted projects such as system deliveries, turnkey projects, or various procurement and delivery ventures. Furthermore, we assist in green- and brownfield investments as well as in other foreign direct investments.",
    },
  ],
  content2: [
    {
      header: "IP-Strategies",
      text: ": We help you with matters related to protection and strategic licensing of your intellectual property rights (IP). For example, we assist you with strategies designed to fully utilize your IP. Our services also include, for example, patent portfolio analysis, trademark clearance searches, copyright registration, trade secret management, licensing negotiations, and IP-due diligence.",
    },
    {
      header: "Disputes",
      text: ": We support you in intellectual property infringement disputes with prompt and strategic advice for conflict resolution, litigation, and negotiation processes.",
    },
    {
      header: "E-commerce Support",
      text: ": We provide a complete guidance service, addressing e-commerce regulation, consumer regulations, compliance issues, user privacy, data protection, terms of service, and online advertising and marketing. Our services include, for example, terms and conditions, distribution agreements and privacy-related documentation and information.",
    },
  ],
  content3: [
    {
      header: "IT",
      text: ": We assist in drafting and negotiating IT- and technology-related agreements regarding, for example, outsourcing, support, hosting, software licenses, and SaaS solutions. Our services also include managing procurement, and all legal aspects of R&D projects in the field of IT and technology.",
    },
    {
      header: "Experience",
      text: ": Our team members have experience from working in, and on behalf of, both global industries, IP firms, government bodies, innovative industrial companies, and startups.",
    },
    {
      header: "Prompt, Executive Advice",
      text: ": Our team is dedicated to delivering prompt advice, empowering swift decisions. We accomplish this through always working in small, tightly-knit teams focusing on the best outcome for your business. ",
    },
    {
      header: "Tailored Services",
      text: ": We promise to provide you with bespoke legal solutions aligned with your strategic goals and specific needs. To achieve this, we are always immersed in your business, the market you operate in, and different stakeholders’ interests. ",
    },
    {
      header: "Collaborative Approach",
      text: ": To be able to provide you with the best possible solutions, we collaborate with specialists across related sectors. We aim to work as an integrated part of your team and to always be close at hand, keeping you informed and updated from start to finish.",
    },
  ],
  content4: [
    {
      text: "We help you manage your IP with our IP Portfolio Management Solution. The solution is designed to provide you with a robust and efficient IP asset management service. Our solution includes strategic trademark handling, international protection, licensing agreement oversight, and crucial updates in the IP landscape. With our experienced team and AI-powered tools, we safeguard, monetize, and boost your IP assets' success. Discover more ",
      clickHere: "here",
      dot: ".",
    },
  ],
};
const complainceAndRegulatoryPage = {
  author: "Kerstin Eifrém",
  mail: "kerstin.eifrem@rdlaw.se",
  mobileNo: "+46 709 795 140",
  designation: "Associate",
  title: "We specialize in ",
  titleOrange1: "compliance",
  and: " and ",
  titleOrange2: "regulatory",
  title2: ", focusing on efficient solutions in any environment",
  description1: `As regulatory environments and compliance requirements continuously expand, we
  provide expert assistance to help you navigate and implement efficient solutions.
  Our aim is to help you achieve more, with fewer resources. Based on this principle,
  we help you with matters related to areas such as anti-corruption, conflicts of
  interest, money laundering, and intricacies of international trade. Furthermore, we
  provide tailored services helping you meet sustainability, human rights, and safety
  standards. We also help you comply with data protection, e-privacy, and
  information security obligations. Lastly, we provide solutions such as a
  Whistleblowing Solution and a Data Protection Officer as a Service.`,
  subTitle1: "Our Expertise Within Compliance",
  subTitle2: "How We Help",
  subTitle3: "Vast Experience and Tailored Services ",
  subTitle4: "Streamlining Compliance Processes with Tailored Solutions",
  content1: [
    {
      header:
        "Anti-Corruption, Conflicts of Interests, and Anti-Money Laundering",
      text: ": We help you comply with regulations on anti-corruption, conflicts of interests, and anti-money laundering. We assist you in drafting and implementing policies, as well as internal and external investigations.",
    },
    {
      header: "Corporate Social Responsibility (CSR) and Sustainability",
      text: ": We guide you in incorporating policies into your business operations. We also assist in navigating environmental, social, and governance issues, as well as managing disputes and claims. Furthermore, we assist you in sustainability standards and requirements related to, for example, CSRD and the EU Taxonomy.",
    },
    {
      header: "Data Protection and Privacy",
      text: ": With extensive experience in helping companies in various sectors implement and adhere to data protection and privacy regulations, such as GDPR, we help you with all your privacy matters. Our services include creating and implementing comprehensive data protection strategies, drafting internal governing documents, data processing agreements, and privacy information. We also provide counsel on data breach response, and guidance on cross-border data transfers. ",
    },
    {
      header: "Value Chain, Sanctions, and Export Controls",
      text: ": We navigate you through the legal complexities of international trade, managing compliance with sanctions and export controls. Our services include helping you manage risks associated with sanctions and export controls, as well as providing support during trade negotiations.",
    },
    {
      header: "Human Rights",
      text: ": We provide advice on developing human rights policies, guidance on meeting international human rights norms, and support in resolving potential human rights disputes.",
    },
    {
      header: "Whistleblowing",
      text: `: We provide you with a complete, effective whistleblowing solution.
      The system includes reporting channels, and report processing. Our solution fosters
      a transparent work environment by providing a secure channel for employees to
      report concerns whilst ensuring compliance with whistleblowing laws. We can also
      help you with mitigations and to take measures to remedy eventual issues.`,
    },
    {
      header: "Data Protection Officer",
      text: `: Our Data Protection Officer-Service provides you with a
      resource that can help you streamline your privacy matters and help you adhere to
      privacy regulations such as GDPR. Read more about our solution`,
      clickHere: "here",
      dot: ".",
    },
    {
      header: "Work Environment and Occupational Health and Safety",
      text: `: We help you meet
      occupational health and safety regulations. Our services cover compliance audits,
      guidance on accident investigation, policy development, and counsel on worker
      compensation matters.`,
    },
  ],
  content2: [
    {
      header: "Risk Analysis and Management",
      text: `: We offer comprehensive services in identifying,
      assessing, and mitigating compliance risks. This involves conducting internal audits
      and providing regulatory advice tailored to your unique needs.`,
    },
    {
      header: "Implementation of Regulatory Requirements",
      text: `: We support you in implementing
      new or revised regulatory or business requirements. Our services help you maintain
      compliant operations and stay adaptable in a rapidly changing regulatory
      environment`,
    },
    {
      header: "Design and Implementation of Compliance Programs",
      text: `: Our team is proficient in
      designing and implementing robust compliance programs. This includes creating
      internal processes for governance, reporting, and risk management that aligns with
      your business' objectives and regulatory requirements.`,
    },
    {
      header: "Investigations and Reporting",
      text: `: We provide thorough investigative services and
      reporting to ensure transparency and accurate accountability within your
      organization.`,
    },
    {
      header: "Representation in Litigation",
      text: ": We offer expert representation in litigations against regulatory and supervisory authorities in administrative courts.",
    },
  ],
  content3: [
    {
      header: "Experience",
      text: `: Our lawyers have extensive expertise within the field of compliance and
      regulatory law. Having worked as in-house lawyers in SME´s, insurance companies,
      and large corporations across various sectors, our team provides you with efficient
      solutions.`,
    },
    {
      header: "Prompt, Executive Advice",
      text: `: Our team is dedicated to delivering prompt advice,
      empowering swift decisions. We accomplish this through always working in small,
      tightly-knit teams focusing on the best outcome for your business. `,
    },
    {
      header: "Tailored Services",
      text: `:  We promise to provide you with bespoke legal solutions aligned
      with your strategic goals and specific needs. To achieve this, we are always
      immersed in your business, the market you operate in, and different stakeholders’
      interests. `,
    },
    {
      header: "Collaborative Approach",
      text: `: To be able to provide you with the best possible solutions,
      we collaborate with specialists across related sectors. We aim to work as an
      integrated part of your team and to always be close at hand, keeping you informed
      and updated from start to finish.`,
    },
  ],
  content4: [
    {
      text: `Our Whistleblowing and Data Protection Officer as a Service solutions offer reliable,
      tailored, and cost-effective compliance services. Our Whistleblowing Solution
      fosters a transparent work environment, providing a secure channel for employees
      to report concerns, whilst also facilitating efficient investigations and responses. To
      read more about our Whistleblowing Solution, click `,
      clickHere: "here",
      dot: ".",
      text2: ` Our Data Protection
        Officer Service simplifies GDPR compliance, offering specialized advice and proactive
        risk management for your data protection needs. Discover more about our offer 
        `,
      clickHere2: "here",
      dot2: ".",
    },
  ],
};
const corporateAndEquityPage = {
  author: "Per Gruwberger",
  mail: "per.gruwberger@rdlaw.se",
  mobileNo: "+46 707 344 641",
  designation: "Partner",
  title: "With extensive expertise, we serve ",
  titleOrange1: "corporate",
  and: " and ",
  titleOrange3: "private equity ",
  title2: "customers with efficient services",
  description1: `We have vast experience within all kinds of private equity matters. Our team has served
medium-sized businesses and large international corporate groups as advisors and inhouse counsels. With this comprehensive experience and understanding of corporate
and private equity matters, we offer you efficient, cost-effective, and solution-oriented
advice. Our services include handling company formation and structuring projects, joint
ventures, buy-outs, shareholder and board matters, as well as corporate governance
issues.`,
  subTitle1: "Comperhensive Corporate Law Expertise",
  subTitle2: "Succession, Financing, and Private Equity Services",
  subTitle3: "Vast Experience and Tailored Services",
  content1: [
    {
      header: "Corporate Law and Corporate Governance",
      text: ": We provide solutions to matters related to company formation and structuring, joint ventures, and resolving board and shareholder disputes. We also provide advice on corporate governance related to, for example, board work and general meetings.",
    },
    {
      header: "Restructuring",
      text: ": We have extensive experience in corporate restructurings. Our services include, for example, corporate fissions and fusions, divestitures, as well as forming, merging, or liquidating subsidiaries, all in both national and international settings.",
    },
    {
      header: "A Complete Corporate Service",
      text: ": We work closely with other groups at our firm to offer you efficient solutions to your corporate law matters. Our services range from transferring personnel between subsidiaries in international groups to selling individual IP-rights. With expertise in all legal areas related to corporate and private equity, we handle all your legal matters in an efficient, cost-effective way.",
    },
  ],
  content2: [
    {
      header: "Family Business Succession",
      text: ": We have vast experience in handling family business successions in both SMEs and large companies. We help you through the entire process, focusing on efficient solutions tailored to your objectives.",
    },
    {
      header: "Financing",
      text: ": We offer strategic advice on matters related to the financing of your company. Our services include, for example, advice on debt financing, insolvency proceedings, and investments.",
    },
    {
      header: "Private Equity",
      text: ": We handle all your private equity matters such as fund-raising, investor relations, portfolio company acquisitions and divestitures, management buyouts, leveraged buyouts, due diligence, transactional advice, and exit strategies.",
    },
  ],

  content3: [
    {
      header: "Experience",
      text: ": With experience from working with both large industrial groups and PE actors, our team brings a wealth of knowledge in corporate law and private equity transactions, ready to support your business in all legal aspects.",
    },
    {
      header: "Prompt, Executive Advice",
      text: ": Our team is dedicated to delivering prompt advice, empowering swift decisions. We accomplish this by always working in small, tightly-knit teams focusing on the best outcome for your business.",
    },
    {
      header: "Tailored Services",
      text: ": We promise to provide you with bespoke legal solutions aligned with your strategic goals and specific needs. To achieve this, we are always immersed in your business, the market you operate in, and different stakeholders’ interests.",
    },
    {
      header: "Collaborative Approach",
      text: ": To be able to provide you with the best possible solutions, we collaborate with specialists across related sectors. We aim to work as an integrated part of your team and to always be close at hand, keeping you informed and updated from start to finish.",
    },
  ],
};
const startupGrowthAndVenturesPage = {
  author: "Carolina Thoft",
  mail: "carolina.thoft@rdlaw.se",
  mobileNo: "+46 705 556 679",
  designation: "Partner, Head of Office, Helsingborg",
  title: "With extensive experience, we offer expert advice to",
  titleOrange1: " startups ",
  title2: "at all stages of growth",
  description1: `Startup, Growth & Ventures offers specialized legal advice to entrepreneurs and
              companies in various startup and growth phases. We also work closely with investors
              and offer advice to VC companies, angel investors and family offices. With our special
              offer for startups, we handle the legal structure from the beginning in an efficient and
              customized way. As a legal partner to Sting – Stockholm Innovation & Growth, HETCH, Ecommerce Park of Sweden, Företagsfabriken i Kronoberg and Kalmar Science Park, and
              as an advisor to startups in several different industries, we have the network, knowledge,
              and experience required to tailor our legal advice based on all startups' unique
              challenges, needs and goals.`,
  subTitle1: "From Idea to Exit",
  subTitle2: "Raising of Capital",
  subTitle3: "The Right Support Through Each Growth Phase",
  content1: [
    {
      text: "Each phase of a company's growth journey has its unique legal challenges. We are with you all the way. From the founding of the company to the exit process, we provide customized and value-creating advice at every stage.",
    },
  ],
  content2: [
    {
      text: `Fundraising processes are something that most startups and scaleups go through. With experience on both the investor and entrepreneur sides, we know what it takes to make companies "investor ready" and to raise capital. We support companies in every funding round, from pre-seed to exit, and understand the different requirements of the rounds.`,
    },
  ],

  content3: [
    {
      text: "Expertise and understanding of the startup world make us a reliable partner. We know that in growth phases, it's all about setting the right priorities to grow into the next stage of the company's journey. Our team supports entrepreneurs in navigating their legal challenges, enabling them to concentrate fully on driving the growth and success of their company and business.",
    },
  ],
};
const environmentalPage = {
  author: "Daniel Drott",
  mail: "daniel.drott@rdlaw.se",
  mobileNo: "+46 702 088 275",
  designation: "Specialist Counsel",
  title:
    "With comprehensive experience and technical expertise, we provide strategic ",
  titleOrange1: "environmental law ",
  title2: "services",
  description1: `Our services include navigating complex regulations, obtaining permits and licenses, managing environmental risks, and guiding you in questions related to contaminated land. We also advise in areas related to recycling and circular economy, as well as diligent environmental oversight in transactions, collaborations, and partnerships. With our commitment to crafting practical solutions tailored to fit
  your specific operation, and our deep technical insights, we help you achieve your
  environmental goals whilst fulfilling your legal obligations.`,
  subTitle1: "Driving your Environmental Legal Objectives",
  subTitle2: "Vast Experience and Tailored Services",
  content1: [
    {
      header: "Permit and License Assistance",
      text: ": Our team helps you acquire permits and licenses for operations regulated by the Environmental Code. This includes, for example, permits required for building in water and other water operations, industry production, waste management, sewage, and wind or solar parks.",
    },
    {
      header: "Environmental Risk Assessments",
      text: ": We conduct in-depth evaluations of environmental compliance risks related to your business and craft tailored risk management solutions.",
    },
    {
      header: "Contaminated Land",
      text: ": We provide support in legal matters concerning contaminated land and remediation. Our services include, for example, responsibility assessment, litigation, and ensuring compliance with relevant environmental regulations.",
    },
    {
      header: "Recycling and Circular Economy",
      text: ": Our team handles matters related to waste management, recycling, energy recovery, and circular economy adherence. To accomplish this, we advise you on compliance questions regarding various regulations, encompassing areas such as by-products and cross-border transport.",
    },
    {
      header: "Legal and Regulatory Issues",
      text: ": Our team assists you in addressing challenging legal and regulatory concerns,  such as injunctions from your supervisory authority as well as environmental violations and accidents. We also represent you, and your case, in court proceedings.",
    },
    {
      header: "Transactions and Due Diligence",
      text: ": We assist with environmental due diligence for business transactions, properties, and other projects. This includes managing environmental risks in various agreements, always protecting your interests.",
    },
  ],
  content2: [
    {
      header: "Experience",
      text: ": Our lawyers have extensive expertise within the field of environmental law. This expertise is effectively combined with deep technical knowledge derived from working in various fields of business and together with many of the leading technical advisors established in Sweden.",
    },
    {
      header: "Prompt, Executive Advice",
      text: ": Our team is dedicated to delivering prompt advice, empowering swift decisions. We accomplish this through always working in small, tightly-knit teams focusing on the best outcome for your business. ",
    },
    {
      header: "Tailored Services",
      text: ": We promise to provide you with bespoke legal solutions aligned with your strategic goals and specific needs. To achieve this, we are always immersed in your business, the market you operate in, and different stakeholders’ interests. ",
    },
    {
      header: "Collaborative Approach",
      text: ": To be able to provide you with the best possible solutions, we collaborate with specialists across related sectors. We aim to work as an integrated part of your team and to always be close at hand, keeping you informed and updated from start to finish.",
    },
  ],
};
const employmentPage = {
  author: "Kerstin Eifrém",
  mail: "kerstin.eifrem@rdlaw.se",
  mobileNo: "+46 709 795 140",
  designation: "Associate",
  title: "We specialize in ",
  titleOrange1: " commercial employment law",
  title2: ", focusing on swift and tailored services",
  description1: `Our services span from daily employment advise to expert guidance in transactions, disputes, as well as both small and large-scale reorganizations. We also advice in matters related to, for example, employment commencement and termination, trade secrets protection, trade union negotiations, pension and insurance matters, incentive programs, work environment and occupational health and safety investigations.`,
  subTitle1: "Hire, Compensate, and Terminate",
  subTitle2: "Management, Negoiations, and Disputes",
  subTitle3: "Vast Experience and Tailored Services",
  subTitle4: "Staying Ahead with ELaaS",
  content1: [
    {
      header: "Employment Commencement and Termination",
      text: ": We help you comply with relevant laws and regulations when hiring and terminating employees. We assist with employment agreements, compensation, IP-rights, and clauses related to non-solicitation and non-compensation. In relation to employee termination, we help you with, for example, grounds for termination, labour rules negotiation, severance pay, and confidentiality.",
    },
    {
      header: "Secondments, Staffing, and Consultants",
      text: ": We advise you on matters related to staffing. We help you with, for example, hiring consultants, employee secondments, and hiring staff through staffing companies.",
    },
    {
      header: "Trade Secret Protection and Non-Compete Clauses",
      text: ": We assist you in, for example, protecting your business' intellectual property and secrets in relation to employees. Our services include creating and implementing policies as well as drafting and reviewing confidentiality and non-disclosure agreements.",
    },
    {
      header: "Pensions and Insurance",
      text: ": We guide you in pension and insurance matters. We assist with the design and implementation of pension schemes, advice on insurance policies, and help you ensure compliance with relevant laws and regulations.",
    },
    {
      header: "Incentive Programs and Remuneration",
      text: ": We help you develop employee incentive programs such as stock options, restricted stock units, qualified employee stock options, performance bonuses, and deferred compensation plans.",
    },
  ],
  content2: [
    {
      header: "Reorganizations and Restructurings",
      text: ": We guide you through the legal complexities of corporate restructuring, including operational changes and workforce management. Our services within the field encompass, for example, transition plans and redundancy procedures.",
    },
    {
      header: "Collective Employment Law and Trade Union Negotiations",
      text: ": We represent you in negotiations with trade unions in matters related to, for example, comprehensive dismissals of employees, organizational restructurings, disputes, working conditions, and industry wide employment agreement changes. Furthermore, we help you with all matters related to collective employment agreements, including conducting pay surveys and negotiating local collective agreements. ",
    },
    {
      header:
        "Work Environment and Occupational Health and Safety Investigations",
      text: ": We offer guidance through workplace safety inquiries and efficiently help you maintain a safe and compliant work environment. Our services include crafting governing documents and policies, conducting investigations, advising on accident prevention strategies, and ensuring compliance with workplace safety regulations. We also provide legal support related to investigating, addressing, and preventing workplace discrimination, harassment, and victimization.",
    },
    {
      header: "Compliance Management",
      text: ": We help you comply with all types of regulations related to employment law. We advise you in, for example, GDPR related matters, handling and storing information related to employees, and camera surveillance. ",
    },
    {
      header: "Employment Law in M&A",
      text: ": We assist you with all employment-related matters that arise during mergers and acquisitions. We advise in matters related to, for example, due diligence, transfer of undertakings, information obligations, collective agreements, redundancies, and retention of key employees.",
    },
    {
      header: "Business Transitions",
      text: ": Our team guides you through employment matters related to business transitions. We help you, for example, relocate, terminate, reorganize, and hire new employees. We also help in transitions related to public procurement. ",
    },
    {
      header: "Employment Disputes and Arbitral Proceedings",
      text: ": We represent you in employment disputes related to, for example, unfair dismissal, discrimination, retaliations, or wage claims. Our services include handling litigations, providing advice on dispute resolution strategies, and representing you in mediation and arbitration proceedings.",
    },
  ],
  content3: [
    {
      header: "Experience",
      text: ": Our team of specialists has extensive experience in commercial employment law, ready to assist you in every legal facet.",
    },
    {
      header: "Prompt, Executive Advice",
      text: ": Our team is dedicated to delivering prompt advice, empowering swift decisions. We accomplish this through always working in small, tightly-knit teams focusing on the best outcome for your business. ",
    },
    {
      header: "Tailored Services",
      text: ": We promise to provide you with bespoke legal solutions aligned with your strategic goals and specific needs. To achieve this, we are always immersed in your business, the market you operate in, and different stakeholders’ interests. ",
    },
    {
      header: "Collaborative Approach",
      text: ": To be able to provide you with the best possible solutions, we collaborate with specialists across related fields. We aim to work as an integrated part of your team and to always be close at hand, keeping you informed and updated from start to finish.",
    },
  ],
  content4: [
    {
      header: "Empowering Customers with ELaaS",
      text: ": Showcasing our commitment and expertise in employment law, we provide Employment Law as a Service (ELaaS) – a comprehensive, customized, and cost-effective solution to help you manage employment law matters. ELaaS covers a range of areas including employment contracts, regulatory support, incentive programs, employee relations, training and education, as well as risk management. Discover more about how ELaaS can elevate your operations ",
      clickHere: "here",
      dot: ".",
    },
  ],
};
const mergersAndAcquisitionsPage = {
  author: "Anders Rosholm",
  mail: "anders.rosholm@rdlaw.se",
  mobileNo: "+46 707 344 661",
  designation: "Partner, Responsible Växjö office",
  title: "With our experience within ",
  titleOrange1: "M&A",
  dot: ", we ensure result-oriented advisory services and efficient transactions",
  description1: `We specialize in providing efficient M&A-services to small and medium-sized companies. Our services range from managing business transfers, to guiding you through the processes of, for example, strategic carve-outs and corporate reorganizations. We help you with efficient due diligence investigations and reports, negotiations, and the preparation of key documents such as letters of intent, share purchase agreements, bid letters, investment or sale teasers, and shareholder
  agreements. Working in efficient, tightly-knit teams, we strive for efficiency and
  precision in delivering your targeted outcomes.`,
  subTitle1: "Our Services",
  subTitle2: "Vast Experience and Tailored Services",
  content1: [
    {
      header: "Mergers, Acquisitions, and Divestitures",
      text: ": With the aim of an efficient transaction process, we provide you with services and products covering the entire transaction. Our deliverables and advice include, for example:",
      points: [
        "- Due diligence investigations and reports",
        "- Negotiations",
        "- Letter of intents",
        "- Share purchase agreements",
        "- Non-disclosure agreements",
        "- Shareholder agreements",
        "- Ancillary agreements",
        "- Regulatory filings ",
      ],
      text2:
        "We also offer project management, management of controlled auctions, and drafting teasers and prospects.",
    },
    {
      header: "Carve-Outs",
      text: ": We guide you through strategic carve-outs. Our services include planning and executing divestiture transactions, due diligence, and handling post-separation arrangements.",
    },
    {
      header: "Reorganizations",
      text: ": In corporate restructuring projects, we help you with strategic planning, negotiation of terms with stakeholders, and managing the legal aspects of restructuring. We provide legal guidance throughout the whole reorganizational project, helping you comply with relevant laws and regulations.",
    },
    {
      header: "Incentive Schemes",
      text: ": We help you design and implement employee incentive programs. Our services include, for example, navigating related legal questions and preparing associated documentation such as individual employment agreements and company policies.",
    },
  ],
  content2: [
    {
      header: "Experience",
      text: ": Our team has over 20 years of experience handling several types of M&A-projects. We guide you through transactions, regardless of their complexity, in both national and international settings. We have experience from working with a wide array of customers such as startups, small and medium-sized businesses, industrial companies, private equity firms, and global industry groups. Our team has extensive experience, both as advisors, local counsels, and as in-house project leaders in a wide array of companies and sectors.",
    },
    {
      header: "Risk Identification and Mitigation",
      text: ": A crucial part of our services involves identifying potential transactional risks. By leveraging our extensive experience, we proactively pinpoint risks and guide you in managing and mitigating them.",
    },
    {
      header: "Prompt, Executive Advice",
      text: ": Our team is dedicated to delivering prompt advice, empowering swift decisions. We accomplish this through always working in small, tightly-knit teams focusing on the best outcome for your business. ",
    },
    {
      header: "Tailored Services",
      text: ": We promise to provide you with bespoke legal solutions aligned with your strategic goals and specific needs. To achieve this, we are always immersed in your business, the market you operate in, and different stakeholders’ interests.",
    },
    {
      header: "Collaborative Approach",
      text: ": To be able to provide you with the best possible solutions, we collaborate with specialists across related sectors such financial and technical experts. We aim to work as an integrated part of your team and to always be close at hand, keeping you informed and updated from start to finish.",
    },
    {
      header: "A Complete M&A Team",
      text: ": By utilizing the expertise within our own firm, we help you with all legal matters that arise in your transaction. This includes, for example, handling questions related to employment, environmental, compliance, and real estate law.",
    },
  ],
};
const publicProcurementPage = {
  author: "Peter Lennartz",
  mail: "peter.lennartz@rdlaw.se",
  mobileNo: "+46 709 915 912",
  designation: "Specialist Counsel, Responsible Kalmar Office",
  title: "made simple with our expert legal services",
  titleOrange1: "Public procurement law ",

  description1: `We have extensive experience in assisting both contracting authorities and suppliers within public procurement law. We help you ensure efficient management of all public procurement process aspects. For example, we serve you with solutions related to review proceedings in administrative courts, preparation of requests for proposal, and tender documents. We also help you with evaluating tenders, manage public procurement contracts, and offer expert advice on matters of confidentiality and public information.`,
  subTitle1: "Our Comprehensive Procurement Expertise",
  subTitle2: "Vast Experience and Tailored Services",
  content1: [
    {
      header: "Experts in LOU and LUF",
      text: ": We provide advice and solutions tailored to the Swedish public procurement act (LOU), and the act on public procurement in the utility sector (LUF), as well as related EU legislations.",
    },
    {
      header: "Tender Preparation and Evaluation",
      text: ":  We assist you in preparing and refining public tenders, maximizing your chances of success in the procurement process. Our team also provides support in evaluating and assessing submitted tenders.",
    },
    {
      header: "Procurement Management",
      text: ": Our team handles the drafting, reviewing, and processing of procurement documents to help you comply with relevant laws and regulations.",
    },
    {
      header: "Confidentiality and Public Information",
      text: ": We advise on matters related to public information and confidentiality. We help you with, for example, protection of sensitive information, transparency requirements, and appeals regarding public information.",
    },
    {
      header: "Court proceedings",
      text: ": We advise suppliers and contracting authorities on review proceedings related to public procurements within the administrative court system. We also provide strategic guidance in tort litigation and in navigating proceedings in general courts regarding public procurement law.",
    },
  ],
  content2: [
    {
      header: "Experience",
      text: ": Our team possesses extensive expertise in public procurement. For example, we have acted as advisors in some of the largest procurements of transport services and PPP projects in Swedish history.",
    },
    {
      header: "Risk Identification and Mitigation",
      text: ": Our services are focused on identifying and avoiding risks linked to public procurement law matters. Our team, drawing on extensive experience, effectively addresses these risks and helps you devise strategies to manage and mitigate them.",
    },
    {
      header: "Prompt, Executive Advice",
      text: ": Our team is dedicated to delivering prompt advice, empowering swift decisions. We accomplish this through always working in small, tightly-knit teams focusing on the best outcome for your business. ",
    },
    {
      header: "Tailored Services",
      text: ": We promise to provide you with bespoke legal solutions aligned with your strategic goals and specific needs. To achieve this, we are always immersed in your business, the market you operate in, and different stakeholders’ interests. ",
    },
    {
      header: "Collaborative Approach",
      text: ": To be able to provide you with the best possible solutions, we collaborate with specialists across related sectors. We aim to work as an integrated part of your team and to always be close at hand, keeping you informed and updated from start to finish.",
    },
  ],
};
const railwayAndPublicTransportPage = {
  author: "Martin Dell",
  mail: "martin.dell@rdlaw.se",
  mobileNo: "+46 708 344 660",
  designation: "Partner",
  title:
    "We serve you with unique, industry leading, expertise within the field of ",
  titleOrange1: " railway ",
  and: " and ",
  titleOrange2: "public transportation law",
  description1: `We serve you with industry-leading expertise in railway and public transportation
  law. With over 20 years of experience, we offer expert advisory services and
  strategic advice within, for example, public procurement of transport services,
  rolling stock transactions, goods transportation contracts, infrastructure projects,
  and industry-specific legal issues. We provide you with result-oriented, swift, and
  practical solutions that have solidified our reputation as one of Sweden's premier
  law firms within the field of railway and public transportation law.`,
  subTitle1: "Full-Service Within Railway and Public Transport",
  subTitle2: "Vast Experience and Tailored Advice",
  content1: [
    {
      header: "Public Transport Procurement",
      text: `: We provide you with strategic advice,
      documentation assistance, and project management for both Public Transport
      Authorities (PTAs) and operators`,
    },
    {
      header: "Rolling Stock Transactions",
      text: `: We provide expert counsel on purchasing, maintaining,
      refurbishing, financing, and leasing locomotives, passenger vehicles and freight cars.
      We also handle disputes related to rolling stock transactions`,
    },
    {
      header: "Goods Transportation Contracts",
      text: `:  We assist in procuring short and long-term
      contracts regarding transportation of various goods, both to Railway Undertakings
      (RUs) and principals.`,
    },
    {
      header: "Infrastructure Projects",
      text: `: We help you in infrastructure projects related to railway
      and public transport, ensuring compliance with legal requirements for track
      improvements, ERTMS installations, terminals, and equipment.`,
    },
    {
      header: "Industry-Specific Issues",
      text: `: We address a wide range of challenges, including warranty
      claims, permits, fees, accidents, employment, and real estate matters.`,
    },
  ],
  content2: [
    {
      header: "Experience",
      text: ": Our experience is extensive, encompassing almost all railway transport procurements and numerous major bus transport procurements in Sweden since 2001.",
    },
    {
      header: "Prompt, Executive Advice",
      text: ": Our team is dedicated to delivering prompt advice, empowering swift decisions. We accomplish this through always working in small, tightly-knit teams focusing on results. ",
    },
    {
      header: "Tailored Services",
      text: ": We promise to provide you with bespoke legal solutions aligned with your strategic goals and specific needs. To achieve this, we are always immersed in your business, the market you operate in, and different stakeholders’ interests. ",
    },
    {
      header: "Collaborative Approach",
      text: ": To be able to provide you with the best possible solutions, we collaborate with specialists across related sectors. We aim to work as an integrated part of your team and to always be close at hand, keeping you informed and updated from start to finish.",
    },
  ],
};
const realEstateAndConstructionPage = {
  author: "Susanna Kihlberg",
  mail: "susanna.kihlberg@rdlaw.se",
  mobileNo: "+46 733 311 646",
  designation: "Partner",
  title: "Specializing in ",
  titleOrange1: " real estate",
  titleColon: ",",
  titleOrange2: " lease",
  and: ", and ",
  titleOrange3: "construction law",
  title2:
    ", we promise expert advice throughout the property lifecycle, from land acquisition to exit",
  description1: `We serve you in every aspect of real estate, lease, and construction law. Our areas of expertise include property transactions, land management, property development, commercial and residential leases, property rights, dispute resolution, and construction projects. Serving a diverse customer base including property developers, property owners, the public sector, and tenants, we leverage our multifaceted industry insights to provide expert services.`,
  subTitle1: "Property Development and Construction",
  subTitle2: "Transactions and Financing",
  subTitle3: "Property Management",
  subTitle4: "One Team for the Whole Property Lifecycle",
  content1: [
    {
      header: "Property Formation and Land Management",
      text: ": Our team provides advise on zoning plans, building permits, land intrusion matters, and property formation. Furthermore, we facilitate filing applications, handle regulatory authorities, and manage legal processes concerning building, land, and environmental permits.",
    },
    {
      header: "Property Development",
      text: ":  We help you oversee the legal facets of your property development projects. This includes, for example, establishing development agreements and ensuring compliance with construction laws.",
    },
    {
      header: "Construction Projects",
      text: ":  We advise you in legal questions before, during and after your construction projects. We help you address questions related to areas such as contract clarity and calculability, risk allocation, liability, incentives, and remuneration. Our services include drafting and reviewing tender documents, changing orders as well as managing contractor relationships throughout the project, or disputes that arise thereof.",
    },
  ],
  content2: [
    {
      header: "Acquisitions and Sales",
      text: ": We help you navigate and execute real estate company and property transactions, including sale and lease-back transactions. Our team aids in property due diligence, negotiations, drafting and executing purchase or sale agreements, and regulatory filings.",
    },
    {
      header: "Real Estate Financing and Security",
      text: ": We provide support in financing and security matters related to real estate. Our expertise includes, for example, structuring financial transactions, drafting loan and mortgage documents, and managing legal issues concerning liens.",
    },
  ],
  content3: [
    {
      header: "Commercial Leases and Residential Letting",
      text: ": We provide you with advice in handling commercial leases, residential letting, and related legal matters. We help you with, for example, lease agreements, negotiation, security, subletting, eviction procedures, and terminations.",
    },
    {
      header: "Usufructs, Land Leases, and Easements",
      text: ": We help you with various property rights, including usufructs, land leases, and easements. We also ensure the establishment and protection of your property rights, guiding you through the process of drafting, negotiating, and enforcing relevant agreements.",
    },
  ],

  content4: [
    {
      header: "Specialized Expertise",
      text: ": Our team's knowledge and expertise derive from extensive experiences as advisors, corporate lawyers, and judiciary professionals in the fields of real estate and construction law.",
    },
    {
      header: "Diverse Customers",
      text: ": Our clientele ranges from property management companies and municipalities to tenants, encompassing a diverse group of entities. This breadth of experience enables us to adapt to various business contexts and effectively address your specific needs.",
    },
    {
      header: "Collaborative Approach",
      text: ": To be able to provide you with the best possible solutions, we collaborate with specialists across related sectors. We aim to work as an integrated part of your team and to always be close at hand, keeping you informed and updated from start to finish.",
    },
    {
      header: "Industry Involvement",
      text: ": As active contributors to the development of commonly negotiated contract templates, we stay at the forefront of industry best practices, ensuring a high standard of service.",
    },
  ],
};
const whistleBlowingSolutionPage = {
  title:
    "Encourage openness and preserve your organization’s integrity: Implement our streamlined ",
  orangeText: "Whistleblowing Solution",
  titleDesc:
    "Our whistleblowing solution provides a confidential and secure channel for employees and others to report wrongdoing, fostering a transparent workplace environment. The system is designed for efficient and seamless implementation. With a single URL, we connect your organization to our system, and handle the rest. By implementing our solution, businesses can demonstrate their commitment to compliance and ethical conduct, whilst minimizing potential risks to their reputation and bottom line.",
  ourwhistleBlowingSolution: "We have the solution",
  whistleblowingDescOne:
    "We provide a software that handles whistleblowing reports. We also provide you with robust processes for effectively handling the reports and conducting any necessary investigations. With our solution, we assist you in handling safety and security obligations in your workplace and to maintain the trust and confidence of your employees.",
  whistleblowingDescTwo:
    "Our software solution is designed for easy implementation within your organization, enabling you to minimize both the costs and risks associated with workplace misconduct.",
  whistleblowingDescThree:
    "As of 2021, the EU has implemented a legal framework designed to protect and encourage reporting of misconduct and breaches of the law within businesses and organizations. This framework places new obligations on both public and private entities, particularly regarding whistleblowing and whistleblower protection. Organizations are now required to provide information about whistleblowing, and to establish processes to handle whistleblower reports. Furthermore, employers with over 50 employees are required to implement a robust whistleblowing channel to ensure compliance.",
  legalFramework: "Legal framework: ",
  weprovide: "We provide",
  weprovideDesc:
    "A comprehensive and digital whistleblowing solution that enables your organization to comply with applicable legal frameworks. With our solution:",
  weprovidePoints: {
    one: "Whistleblowers can file reports via a website form, telephone, or digital/physical meetings.",
    two: "Whistleblowers have the option to remain completely anonymous.",
    three:
      "Whistleblowers can actively follow the handling of their reported matter and communicate with our team of experts.",
    four: "Reports are reviewed, registered, and converted into structured summaries for efficient investigation.",
    five: "Reports are handled within the stipulated timeframes. ",
    six: "Our team of experts provides advice and necessary actions based on the reports and investigations.",
    seven:
      "All incoming reports and responses are electronically logged to facilitate downstream investigations with an electronic audit trail.",
    eight:
      "We offer efficient external investigation services related to whistleblower reports.",
  },
};
const employmentLawAsaServicePage = {
  title: "Empower Your Organization: Tailored ",
  orangeText: "Employment Law as a Service",
  title2: " for reliable and efficient operations",
  titleDesc: `We understand that managing employment matters can be complex and time-consuming, for businesses of all sizes. To assist our customers effectively, we have developed a solution – Employment Law as a Service (ELaaS). ELaaS offers a comprehensive, customized, and cost-effective legal support designed to help you keep your business up to date and thriving in today's ever-changing landscape related to employment law.`,
  elaasIncludes: "ELaaS includes",
  benefitsOfElaas: "Benefits of ELaaS",
  experianceElaas: "Ready to experience ELaaS?",
  includesPoints: {
    one: "With our solution you gain access to cost-effective services provided by our team of dedicated employment law lawyers. The team is committed to providing you with quality products and the highest level of expertise in the following areas:",
    two: {
      title: "Employment Contracts and Policies:",
      desc: " Access to a number of templates, for example: employment contracts, employee handbooks, and internal policies.",
    },
    three: {
      title: "Compliance and Regulatory Support:",
      desc: " We assist your business in your day-to-day employment law matters, including, for example, wage and hour regulations, discrimination laws, workplace safety, and leave policies.",
    },
    four: {
      title: "Incentive Programs:",
      desc: " We guide you in designing, implementing, and managing customized incentive schemes, such as stock option plans, qualified employee stock option plans, restricted stock units (RSUs), and employee stock purchase plans (ESPPs), to effectively motivate and reward your employees.We also help you create, implement, and manage commission and bonus programs.",
    },
    five: {
      title: "Employee Relations and Dispute Resolution:",
      desc: " Our team provides expert guidance in handling employee grievances, disciplinary actions, and workplace investigations.",
    },
    six: {
      title: "Collective bargaining:",
      desc: ` We assist employees, trade unions, and businesses in collective bargaining
      maters. We help you before, during, and after negotiations, in all types of sectors and industries.`,
    },
    seven: {
      title: "Training and Education:",
      desc: " We offer customized training programs on various employment law topics, such as sexual harassment prevention, diversity, and inclusion, and managing employee performance.",
    },
    eight: {
      title: "Risk Management and Legal Audits:",
      desc: ` We help you conduct comprehensive audits to assess your business's compliance with employment laws and identify potential areas of risk, ensuring you proactively address potential legal issues.`,
    },
  },
  benefitsPoints: {
    one: "By partnering with us to handle your legal needs within employment law, your business will enjoy a wide range of benefits:",
    two: {
      title: "Customized Solutions:",
      desc: " We understand that every business is different. Our ELaaS offering will be tailored to meet your specific requirements. Our team will work closely with you to create a customized plan that aligns with your business’ needs and objectives.",
    },
    three: {
      title: "Proactive Legal Support:",
      desc: " Our ELaaS approach is focused on preventing legal issues before they arise. We work with you to identify potential risks and develop strategies to minimize liability and increase your business possibilities avoid costly litigations.",
    },
    four: {
      title: "Ongoing Expertise:",
      desc: " Opting for our ELaaS-solution provides you with a dedicated team of expert employment law attorneys, dedicated in staying updated on the latest legal developments. This will help your business remain well-informed in the ever-evolving employment law landscape.",
    },
    five: {
      title: "Improved Employee Relations:",
      desc: " By utilizing our ELaaS solution, you can focus on streamlining your important processes and foster your business’ positive work environment. Our solution will also give you room to focus on enhancing employee satisfaction, engagement, and retention.",
    },
    six: {
      title: "Cost Savings:",
      desc: " You benefit from a cost-effective, transparent solution, featuring a team of specialized employment law lawyers. Our adaptable pricing models cater to your distinct needs and budget.",
    },
  },
  experiancePoints: {
    one: "Trust us to provide you with the comprehensive, customized, and cost-effective solution to your employment law needs.",
    two: "Contact us today to schedule a consultation and learn more about how our ELaaS offerings can benefit your business.",
  },
};
const dpoAsaServicePage = {
  title: "Experience efficient privacy compliance: ",
  orangeText: "Data Protection Officer as a Service,",
  title2: " tailored to your needs",
  titleDesc: `Our Data Protection Officer as a Service offers a seamless approach to GDPR compliance by providing tailored support. By partnering with us, you benefit from our extensive knowledge in data protection, ensuring that your organization stays ahead of evolving privacy matters and regulations. Choose our DPO solution for efficient implementation and management of your organization's data protection responsibilities.`,
  dpo: "What is DPO?",
  needsDpo: "Who needs a DPO?",
  roshlommdellOffer: "What does RosholmDell offer?",
  dpoPoints: {
    one: `A Data Protection Officer (DPO) is responsible for ensuring an organization's compliance with the EU's General Data Protection Regulation (GDPR). Key responsibilities include:`,
    two: {
      1: "Informing and advising the organization about GDPR obligations",
      2: "Monitoring compliance with GDPR",
      3: "Assisting with Data Protection Impact Assessments (DPIAs)",
      4: "Cooperating and communicating with supervisory authorities",
      5: "Advising on handling legal inquiries and complaints from data subjects",
      6: "Advising on the handling of data breaches",
      7: "Monitoring data processing activities",
      // 8: "Implementing privacy by design and default.",
      // 9: "Monitoring data processing activities.",
    },
    three: `The specific tasks may vary depending on the organization's size and the complexity of data processing activities, but the primary focus is always GDPR compliance and protecting personal data.`,
  },
  needsPoints: {
    one: `According to the GDPR, organizations are required to appoint a Data Protection Officer (DPO) in the following cases:`,
    two: {
      1: "Public authorities or bodies: Any public authority or body, with the exception of courts acting in their judicial capacity.",
      2: "Large-scale systematic monitoring: Organizations that carry out large-scale systematic monitoring of individuals as part of their core activities. This normally applies to businesses engaged in online tracking, behavioural advertising, or any other type of extensive monitoring of user behaviour.",
      3: `Large-scale processing of special categories of data or data relating to criminal convictions: Organizations that process, on a large scale, special categories of personal data (such as information revealing racial or ethnic origin, political opinions, religious or philosophical beliefs, trade union membership, biometric data, or health data) or data relating to criminal convictions, as part of their core activities.`,
    },
    three: `Whilst not mandatory for other organizations, it is considered good practice to appoint a DPO to ensure GDPR compliance and manage data protection matters. This particularly applies if the organization processes personal data on a significant scale or handles sensitive information.`,
  },
  offerPoints: {
    one: `We offer qualified lawyers to act as your company’s DPO. The DPO service simplifies your company’s privacy work by providing the right resources. Our DPO service provides specialized help without major investments in time or staff. The DPO acts as a contact person towards the Swedish Data Protection Authority as well as customers, employees, and other data subjects. Our DPO service also provides advice and support within your organization on data protection and privacy issues, whilst continuously reviewing compliance with the GDPR and other applicable privacy regulation.`,
    two: `With our expertise in data protection and information security, we ensure effective DPO work. With experience from different business sectors, we can easily identify and manage specific risks in your company’s operations and handle these with a pragmatic approach.`,
  },
};
const ipportfolioManagementPage = {
  title:
    "Experience valuable legal services for your company's IP assets with our ",
  orangeText: "IP Portfolio Management solution",
  titleDesc: `Our IP Portfolio Management solution is designed to help you efficiently utilize all your IP assets. With our lawyers’ extensive experience within IP law, we understand what it takes to protect your IP while simultaneously maximizing the value of your brands and market position.`,
  ipPortfolio: "What is IP Portfolio Management?",
  needsIpportfolio: "Who Needs IP Portfolio Management?",
  roshlommdellOffer: "What Does RosholmDell Offer?",
  ipPortfolioPoints: {
    one: `Our comprehensive IP Portfolio Management solution offers:`,
    two: {
      1: "Strategic oversight of your company's trademarks, simplifying your brand asset management. We ensure that your valuable brand assets are secure and proficiently managed.",
      2: "Specialized assistance with trademark clearance searches and active monitoring of trademark usage, all without necessitating significant time or staff investments.",
      3: "Development of strategies for global trademark protection, including registering trademarks in multiple jurisdictions. We also help you deter unauthorized use of your IP both nationally, and internationally.",
      4: "Comprehensive management of your licensing agreements, franchising contracts, and other IP-related contracts. We assist in effectively monetizing and commercializing your trademark assets while continuously reviewing compliance with IP laws and regulations.",
      5: "Keeping you updated with significant changes in the trademark landscape. We are experienced across different business sectors and can identify and manage unique risks in your company's operations.",
    },
  },
  needsIpportfolioPoints: {
    one: `Our IP portfolio management service is well-suited for:`,
    two: {
      1: "Businesses of all sizes, including emerging startups requiring a thorough approach to managing and protecting their trademarks.",
      2: "Companies in competitive sectors or with high-value brand assets who are in need of a robust IP strategy to manage their trademarks.",
      3: `International organizations with operations that require trademark protection across multiple legal territories.`,
      4: `Businesses involved in various stages of product lifecycles, from product development and manufacturing to distribution.`,
      5: "E-commerce businesses, digital marketers, and other companies involved in digital branding.",
      6: "Businesses engaged in the process of granting licenses or franchises of their trademarks to third parties.",
    },
    three: `Our service provides the necessary resources and professional expertise that your business requires, without the need for significant time or staffing investments. We act as your organization's contact point, offering advice and facilitating negotiations, while constantly reviewing compliance with relevant trademark laws and regulations. Our proficiency in various sectors enables us to identify and efficiently manage specific risks tied to your IP.`,
  },
  roshlommdellOfferPoints: {
    one: `We deliver comprehensive trademark and design management services, acting as your IP consultant. Our IP service streamlines your company's IP portfolio management by providing all the resources your business requires. We navigate the complexities of trademark filings in each of your operational countries using our extensive global partner network.`,
    two: `We incorporate advanced AI technology to monitor and research existing trademarks, acting proactively on risk management and protection strategies. We also work closely with other stakeholders of your IP and help you negotiate license deals, enabling you to utilize your trademark assets more effectively.`,
    three: `Our team of experts ensures efficient management of your IP portfolio. With experience from a variety of business sectors, we recognize and handle risks in your company's IP operations with a pragmatic approach. Our IP service also includes advice and support within your organization, whilst continuously ensuring compliance with applicable IP laws and regulations.`,
  },
};
const whistleblowerPolicyPage = {
  title: "Whistleblower Policy",
  subtitleone: "Introduction, Purpose, and Scope",
  subtitletwo: "Responsibilities and Accountability",
  subtitlethree: "Reporting misconduct",
  subtitlefour: "Protection when reporting",
  subtitlefive: "Confidentiality, Date protection, and Documentation",
  subtitleoneDesc: {
    one: `RosholmDell strives for a healthy business environment and ensures that transparency and accountability permeate all of RosholmDell's activities. RosholmDell is aware that organisations can be subject to mismanagement and other misconduct. Through this Whistleblower Policy ("<strong>Policy</strong>"), RosholmDell informs about and encourages reporting of actual or suspected misconduct within RosholmDell.`,
    two: `Furthermore, the Policy aims to establish a standard for reporting misconduct within the organisation. An individual who wants to report misconduct ("<strong>Whistleblower</strong>") should feel safe and know that he or she can report misconduct without risk of retaliation and that reports will be handled in a professional and confidential manner.`,
    three: `The policy covers the entire organisation. Everyone who is part of RosholmDell's whistleblowing circle has the possibility to report according to this Policy. Those included in this circle are those who are in contact with RosholmDell in a workrelated context, such as employees, consultants, trainees or volunteers.`,
  },
  subtitletwoDesc: {
    one: "The Board of Directors of RosholmDell has the ultimate responsibility for ensuring that RosholmDell complies with the regulations governing the right to whistleblowing and protection against retaliation, etc.",
    two: "The CEO is responsible for the ongoing work within RosholmDell and for compliance with the Policy.",
    three:
      "All employees are expected to be familiar with this Policy and the whistleblowing instructions in force from time to time, and to make use of the opportunity to report misconduct under the Policy if necessary.",
  },
  subtitlethreeDesc: {
    one: `Misconducts are events of public interest, such as, for example, breaches of laws and regulations or of RosholmDell's own policies, or other misconducts for which there is a public interest in disclosure.`,
    two: "Some examples of activities or events that RosholmDell considers to be abuses of public interest are:",
    three: {
      1: "i) Bribery, theft, fraud, accounting offences, tax offences and other criminal acts,",
      2: "ii) gross violations of internal policy documents,",
      3: "iii) activities that could damage people or property,",
      4: "iv) failure to rectify or report events that could result in significant costs or losses,",
      5: "v) abuse of power or position,",
      6: "vi) discrimination on grounds of age, race, gender, religion, sexual orientation, marital status, parental status, political opinion or ethnic origin in employment or use of services,",
      7: "vii) corruption and conflicts of interest; or",
      8: `viii) violations of someone's fundamental rights and freedoms.`,
    },
    four: {
      title: "Report to your line manager:",
      desc: `Anyone who discovers or suspects misconduct is encouraged to report it to their immediate manager. Reports of misconduct can also always be made to RosholmDell's CEO or Chairman of the Board, directly or anonymously.`,
    },
    five: {
      title: "External reporting channels and publication:",
      desc: " Those who wish to report a misconduct can also do so by using an external reporting channel through which reports are made to the competent authority. Information on how to report to competent authorities via an external reporting channel can be found on the RosholmDell website.",
    },
    six: {
      title: "Investigation and response:",
      desc: "Misconduct will be investigated as expeditiously as the circumstances require and within applicable time limits. RosholmDell will investigate all reported cases. It is important that each individual who reports genuinely suspects that misconduct has occurred. RosholmDell takes deliberately false or malicious allegations seriously and may take disciplinary action against anyone who maliciously reports such allegations. An untruthful statement may be invoked as evidence if the statement would constitute defamation.",
    },
  },
  subtitlefourDesc: {
    one: "A whistleblower must be protected from all types of retaliation. The protection follows from the Act (2021:890) on the protection of persons reporting misconduct. The protection applies provided that the whistleblower has had reasonable grounds to assume that the information in the report is true and that he or she is not guilty of a criminal offence by obtaining the information. The same applies to a person who assists the whistleblower in reporting, such as a shop steward or a safety representative. The disclosure of documents in connection with reporting is not covered by the protection.",
    two: "Examples of retaliation from which whistleblowers should be protected are:",
    three: {
      1: "i) termination, dismissal, reassignment,",
      2: "ii) lack of a pay rise, loss of benefits,",
      3: "iii) unjustifiably poor grades, and",
      4: "iv) loss of promotion, ostracism and any other retaliation related to reporting.",
    },
    four: `RosholmDell may not retaliate because someone turns to their workers' organisation for consultation on reporting. Nor may RosholmDell obstruct or attempt to obstruct such consultation.`,
    five: `A Whistleblower is also covered by the protection when he/she chooses to report to a competent authority via an external reporting channel provided that no reasonable follow-up action has been taken by RosholmDell after internal reporting, or if the Whistleblower has not received confirmation of receipt of the report within seven days, or has not received feedback within three months. The protection in case of external reporting also applies when the Whistleblower has reasonable grounds to believe that there is a misconduct involving an imminent or obvious danger, or if internal reporting can be assumed to entail a risk of retaliation or that the misconduct is not remedied.`,
    six: "Whistleblowers are protected from investigation, which means that RosholmDell may not seek the identity of the person who made a report. RosholmDell may also not obstruct or attempt to obstruct reporting.",
  },
  subtitlefiveDesc: {
    one: {
      title: "Confidentiality:",
      desc: "A report of misconduct or suspected misconduct should be handled confidentially. Information that may mean that the reporting person can be identified may be disclosed, for example in connection with a police report, provided that it does not hinder or complicate the purpose of the follow-up of the report.",
    },
    two: {
      title: "Data protection:",
      desc: ` Personal data is processed in connection with follow-up cases, for the purpose of taking action, for the purpose of using reports as evidence in legal proceedings or otherwise in accordance with the law or regulation. Personal data processed in the context of a follow-up case and the taking of measures following a follow-up case may be processed for a maximum of two years after the case has been closed. Personal data that is clearly irrelevant to the processing of a case may not be collected and will be deleted as soon as possible. More information about RosholmDell's processing of personal data regarding employees in the information available on RosholmDell's intranet or if you are other than an employee or similar, in our`,
      desc1: "Privacy Notice",
      dot: ".",
    },
    three: {
      title: "Documentation:",
      desc: "The case must be appropriately documented. The material must be kept for as long as the case is ongoing, but for a maximum of two years after the case is closed.",
    },
  },
};
const privacyNoticePage = {
  title: "Privacy Notice",
  subtitleone: "Background",
  subtitletwo: "Definitions",
  subtitlethree: "When do we Process your Personal Data?",
  subtitlefour: "Data controller",
  subtitlefive: "Why do we Process your Personal Data?",
  subtitlesix: "What Personal Data is Processed on what legal ground?",
  subtitleseven: "How long is your Personal Data stored?",
  subtitleeight: "Social media platforms",
  subtitlenine: "Your rights",
  subtitleten: "Consent to the Processing of Personal Data",
  subtitleeleven: "To whom do we transfer your Personal Data?",
  subtitletwelve: "How do we protect your Personal Data?",
  subtitlethirteen: "Supervision and compliance",
  subtitlefourteen: "Third party’s terms and conditions",
  subtitlefifteen: "Changes to the Privacy Notice",
  subtitlesixteen: "How do you contact us?",
  subtitleoneDesc: {
    one: `This Privacy Notice (“<strong>Privacy Notice</strong>”) explains how RosholmDell Advokatbyrå AB (“<strong>RosholmDell</strong>”, “<strong>we</strong>”, “<strong>us</strong>”) processes your personal data in a legal, appropriate and safe manner when you or the company you represent purchase our services, visit our website or come in contact with us in some other way.`,
    two: `The Privacy Notice describes how RosholmDell processes your personal data and which rights you have according to the rules in the EU’s General Data Protection Regulation (GDPR) (EU) 2016/679 with associated statutes of implementation and supplementary statutes on data protection (the “<strong>Data Protection Rules</strong>”). In addition to the Data Protection Rules we are bound by law and the Swedish Bar Association’s code of conduct to exercise confidentiality regarding information that occurs in our business. These rules normally prevent us disclosing information without the client’s approval, even regarding other data than the client’s own.`,
    three: `In addition to this Privacy Notice, our`,
    four: "General terms and conditions",
    five: "apply to any purchases of our services.",
  },
  subtitletwoDesc: {
    one: "In this Privacy Notice, the term “personal data” refers to any information which can be attributable to an identified or identifiable natural person, such as e.g. name, address, personal registration number, e-mail address, phone number and photo (“<strong>Personal Data</strong>”).",
    two: "This Privacy Notice describes how we Process your Personal Data. The term “processing” refers to e.g. our registering, storing, and in other ways using your Personal Data in such a manner as is described in this Privacy Notice (“<strong>Processing</strong>”).",
  },
  subtitlethreeDesc: {
    one: `The Privacy Notice applies in the following cases when Processing of Personal Data arises in our business:`,
    two: {
      1: "(i) when RosholmDell provides services to you or the company you represent;",
      2: "(ii) to mailings of invitations to vented and other information;",
      3: "(iii) upon your participation in courses, workshops, seminars and events that are held or arranged by us;",
      4: "(iv) in connection to visits to our website www.rosholmdell.se (“Website”) or via social media platforms;",
      5: "(v) when you apply for a job with us; and",
      6: "(vi) to requests or other contacts with us via e-mail or phone.",
    },
  },
  subtitlefourDesc: {
    one: "RosholmDell Advokatbyrå AB, Reg. No. 556858-6290, Kungsgatan 12, 352 33 Växjö, Sweden, is the data controller for the processing of your personal data which is carried out in accordance with this Privacy Notice.",
  },
  subtitlefiveDesc: {
    one: `RosholmDell Processes Personal Data regarding our clients, our clients’ representatives and contact persons, our clients’ associates and clients, potential clients, adverse parties as well as representatives or contact persons of adverse parties, potential adverse parties, witnesses, employment-seekers, suppliers and other associates. RosholmDell only collects and Processes your Personal Data for specific purposes. This entails that Personal Data is Processed in order to:`,
    two: {
      1: "(i) provide services and fulfil the agreement entered into with you personally, the company or organisation you represent or work for, or the company or organisation you work on behalf of;",
      2: "(ii) provide seminars, workshops, courses, and other events;",
      3: "(iii) enable general customer relationships management (quality management, statistics, market and client analyses, as well as business and methodological development) and customer service, e.g. when you contact us via e-mail, phone or via our Website.",
      4: "(iv) inform and market our business through, inter alia, publishing customer references and other material on our Website, in presentation material used at events, or via brochures;",
      5: "(v) leave information regarding events and news, and address marketing information via e-mail, phone or via social media platforms regarding our business and our services;",
      6: "(vi) process a job application;",
      7: "(vii) prevent fraud, money laundering and financing of terrorism, and carry our risk management; and",
      8: "(viii) comply with applicable legislation.",
    },
    three:
      "What Personal Data we collect and Process depends, inter alia, on (i) how you come in contact with us, and (ii) which of our services we provide for you or the company you represent.",
    four: "In addition to the Personal Data you provide to us, or that we collect when providing our services, we may also collect Personal Data from third parties. These third parties vary from time to time but include inter alia suppliers of public record information, such as the Swedish Companies Registration Office’s business register or the National Land Survey’s property register, as well as credit rating agencies or banks from where we obtain information regarding creditworthiness or information in order to conduct anti-money laundering controls.",
    five: "You are not obligated to provide us with Personal Data but if you wish not to, this may lead to us not being able to undertake an assignment as we will not be able to carry out the necessary disqualification controls and/or anti-money laundering controls.",
  },
  subtitlesixDesc: {
    one: `The Personal Data listed below is Processed by us for the purposes explained under section 5 above:`,
    two: {
      1: "(i) name:",
      2: "(ii) billing and delivery address;",
      3: "(iii) phone number;",
      4: "(iv) e-mail address;",
      5: "(v) title of profession and place of work;",
      6: "(vi) personal identification number;",
      7: "(vii) bank account number;",
      8: "(viii) payment information;",
      9: "(ix) client number;",
      10: "(x) copy of id ID-documentation;",
      11: "(xi) information regarding health in connection with, for example, registration of food preferences and allergies;",
      12: "(xii) reviews;",
      13: "(xiii) resumé;",
      14: "(xiv) customer references; and",
      15: "(xv) information in correspondence with you.",
    },
    three:
      "The Processing of Personal Data is necessary in order for RosholmDell to provide its legal services and perform and administer the assignment and in order to look after you interests and thereby fulfil the agreement that has been entered into with you. In situations where you act as a representative or contact person for a company or organisation that is a client of RosholmDell or in any other way collaborates with RosholmDell, we Process your Personal Data based on our legitimate business interest. For this Processing, we have conducted a balancing of interests where we have, inter alia, taken into account that the Processing does not include any sensitive Personal Data and that we have a clear commercial interest in being able to provide our services to the company or organisation you work for or on behalf of in an efficient manner.",
    four: "The Processing of Personal Data is also necessary in order for RosholmDell to provide good service and client care, and thereby meet our legitimate interest of developing our business and communicate our contacts in an efficient and client friendly manner. As a part of this, RosholmDell will, in some cases, Process sensitive Personal Data regarding health when you, as a part of a registration to an event which is held by us, informs us of, for example, your food preferences and/or allergies. These sensitive Personal Data are Processed for the sole purpose of RosholmDell being able to meet the specifically requested wishes.",
    five: "The Personal Data can also be Processed regarding other contacts with you, for example through newsletters and other dispatches, such as invitations to events, information regarding seminars and educations or other information which we consider to be relevant for you or the company or organisation that you represent. We Process your Personal Data based on our legitimate business interest of being able to provide you, who are already an existing client, or who used to have a business relation with us, relevant marketing and information. For this Processing, we have conducted a balancing of interests where we have, inter alia, taken into account that the Processing does not include any sensitive Personal Data and that we have a clear commercial interest in being able to provide our services to the company or organisation you work for or on behalf of in an efficient manner.",
    six: "If you are in contact with us in connection with seeking employment with us, we base our Processing of your Personal Data on your consent or on our legitimate business interest.",
    seven:
      "We may also process your Personal Data after obtaining your consent, e.g. when you consent to our publishing of customer references on our Website. In the event that we can no longer Process your Personal Data for marketing purposes based on our legitimate business interest we may come to obtain your consent to e.g. send you information. Consent can also be obtained if we wish to store incoming resumés for future recruitment purposes. We will always document whether you have given your consent to our Processing of your Personal Data.",
    eight:
      "Your Personal Data is also Processed, in applicable cases, in order for RosholmDell to comply by its obligations stemming from applicable legislation, court rulings, decisions taken by the authorities, or the Bar Association’s code of conduct. Such obligations may e.g. follow from the Accounting Act (1999:1078), the Money Laundering and the Financing of Terrorism (Prevention) Act (2017:630), or what constitutes professional conduct when conducting disqualification controls.",
  },
  subtitlesevenDesc: {
    one: `Your Personal Data is stored only as long as needed in order to fulfil the purposes for which the Personal Data was gathered in accordance with this Privacy Notice.`,
    two: "The Majority of the Personal Data which is Processed is stored for a period of ten years from the day of the completion of the assignment, in accordance with the RosholmDell’s obligation under the Bar Association’s code of conduct, or for the longer period which is necessary due to the nature of the assignment.",
    three:
      "Personal Data which is Processed in order to develop, analyse, and inform about RosholmDell’s services, is stored for as long as we have a business relationship with you, your employer or the company or organisation you represent.",
    four: "If you have given your consent to the Processing of your Personal Data, RosholmDell Processes your Personal Data for the specific purpose, until you withdraw your consent. You can withdraw your consent at any time by contacting us and objecting to this.",
    five: "In order to ensure RosholmDell’s compliance with the legal obligations stemming from applicable legislation or in order to safeguard our legal interests we may store your Personal Data for a longer period of time than what is stated above. The Personal Data is however never stored for longer than necessary or statutory for each purpose.",
  },
  subtitleeightDesc: {
    one: `RosholmDell uses Facebook, Instagram and LinkedIn as platforms to get in contact with our clients, as well as market and inform about our business and our services. In connection with this, RosholmDell is the data controller for publications and information that contain Personal Data and are made by you as a user in the form of e.g. comments, photos and video clips. RosholmDell does not allow offensive content to be published or made available on our platforms. We ask our users to report unfit content to us in order for us to ensure that such content does not occur. RosholmDell may also, based on what we deem necessary, remove content that has been published on our Facebook, Instagram, or LinkedIn page.`,
  },
  subtitlenineDesc: {
    one: `You have a right to receive information regarding the Processing of your Personal Data we carry out. Below you find a statement of the rights you can claim by contacting us. Our contact information can be found at the very end of this Privacy Notice.`,
    two: {
      title: "Right to access:",
      desc: "You have a right to, free of charge, request information regarding our Processing of your Personal Data. You also have a right to receive a copy of your Personal Data that we Process. Such a request shall be submitted to us in writing. We will respond to your request as swiftly as possible. If we cannot grant you access to the information your request concerns, we will provide a reason as to why. The copy of your Personal Data will be sent to your registered address unless otherwise is agreed with you in writing. In order to ensure that the right person receives the information, we may come to request more information from you.",
    },
    three: {
      title: "Right to rectification:",
      desc: "The main responsibility to ensure that the Personal Data we Process is correct lies with RosholmDell. If you inform us that the Personal Data you have provided to us is no longer correct, we will promptly correct, block or erase such Personal Data.",
    },
    four: {
      title: "Right to erasure:",
      desc: "You have the right to request that RosholmDell, without unnecessary delay, erases your Personal Data. Personal Data shall be erased in the following cases:",
      desc1: {
        1: "(i) if the Personal Data is no longer necessary for the purposes for which it was collected;",
        2: "(ii) if you withdraw your consent and the Processing was based solely on consent;",
        3: "(iii) if Processing is carried out for purposes of direct marketing and you oppose your Personal Data being used for such purposes;",
        4: "(iv) if you oppose the Processing of Personal Data after a balancing of interests has been carried out and your interest outweighs ours;",
        5: "(v) if your Personal Data has not been Processed in accordance with the Data Protection Rules; or",
        6: "(vi) if erasure is necessary in order to comply with a legal obligation.",
      },
    },
    five: "There may be obligations which hinder us from immediately erasing all your Personal Data. These obligations stem from applicable legislation regarding inter alia accounting, as well as from our obligation to store all assignments for a period of at least ten years from the completion of the assignment in accordance with the Bar Association’s code of conduct. If certain Personal Data cannot be erased due to applicable legislation or other rules of conduct that we are obligated to apply, we will inform you of this as well as ensure that your Personal Data will be used solely for the purpose of complying with such legal obligations and not for any other purposes.",
    six: {
      title: "Right to restriction:",
      desc: "You have a right to request that RosholmDell temporarily restricts the Processing of your Personal Data. Such a restriction can be requested in the following cases:",
      desc1: {
        1: "(i) if you consider the Personal Data we have about you to be incorrect and in connection with this have requested rectification;",
        2: "(ii) when the Processing of your Personal Data which is carried out is not compliant with the Data Protection Rules, but you still do not want your Personal Data to be erased but rather restricted; and",
        3: "(iii) when we no longer need your Personal Data for the purposes of our Processing but you need it in order to establish, exert, or defend a legal claim.",
      },
    },
    seven:
      "If you have objected against the Processing of your Personal Data the use of your Personal Data may be restricted during the time of the investigation. Upon the restriction of your Personal Data, RosholmDell will only store your Personal Data and for further Processing obtain your consent.",
    eight: {
      title: "Right to data portability:",
      desc: "You have a right to, in the cases where we Process your Personal Data with your consent or in order to fulfil contractual obligations toward you, require that we provide you with all Personal Data we have about you and which is Processed in an automated manner, in a machine-readable format, which may be inter alia an Excel-file or a CSV-file. If it is technically possible, you further have the right to require that we transfer your Personal Data to another data controller.",
    },
    nine: {
      title: "Right to object:",
      desc: "You have a right to object to our Processing of your Personal Data if the Processing is based on our legitimate interest. RosholmDell will in such a case ask you to specify which Processing you object to. If you object to any Processing we will only continue our Processing of the Personal Data if there are legitimate interests for Processing which outweigh your interests. We will also inform you about our decision.",
    },
  },
  subtitletenDesc: {
    one: `If you have consented to Processing of your Personal Data, you decide if and when your wish to withdraw the voluntary consent that you have given to the Processing of your Personal Data. You can withdraw your consent by (i) contacting us and object to this in writing, or (ii) following the link in the mailings if the consent regards receiving marketing information and other information.`,
  },
  subtitleelevenDesc: {
    one: `In order to provide some of our services we appoint selected third parties. This entails that we share some of the Personal Data we have collected with them, e.g. to collaborative partners such as suppliers of IT-systems, assignment management systems, or which supply printing and distribution. In connection with such transfer of your Personal Data, RosholmDell takes organisational and technical measures in order to ensure that your Personal Data is handled in a safe and secure manner. These selected third parties will only process your Personal Data in manners which follow from this Privacy Notice and in order to fulfil one or more of the purposes listed in this Privacy Notice. When such third parties are data processors for RosholmDell we are responsible toward you for that the Processing of your Personal Data carried out by these third parties is carried out in a correct and legal manner. In such cases, we also have agreements with the suppliers containing instructions for the Processing of Personal Data in accordance with the Data Protection Rules.`,
    two: "RosholmDell may transfer your Personal Data to countries outside the EU/EES, if it is necessary due to the nature of the assignment. The assignment might e.g. need assistance from a foreign counsel or involve an international transaction or dispute. Such transfer will in such case always be carried out in a legal and safe manner.",
    three:
      "We will disclose your Personal Data if it is required by law or if we, as a company, reasonably deem it to be necessary in order to protect our rights, e.g. by disclosing Personal Data to RosholmDell’s liability insurance provider and/or to comply with a court ruling or abide by the verdict of a legal process. We will also disclose your Personal Data when, within the framework af an assignment, this is necessary in order to look after your rights. Such transfers can e.g. be made to courts, authorities, adverse parties, the counsels of adverse parties, as well as to insurance companies in connection to an application for a legal expenses insurance.",
    four: "Your Personal Data may also be disclosed to e.g. your/your company’s accountant in accordance with agreements with you, to banks in connection with handling of client funds, when the bank requires information, and to the Swedish Bar Association when we are obligated to do so.",
    five: "RosholmDell will not sell your Personal Data to a third party unless we have previously obtained your consent. However, we may, in the case that RosholmDell decides to sell, buy, merge with another company or organisation, or in any other way reorganise the business, transfer your Personal Data to potential or actual buyers and their potential advisors.",
  },
  subtitletwelveDesc: {
    one: `In order to protect your personal integrity, discover, prevent and limit the risks of a hacking attack etc., RosholmDell takes on appropriate technical and organisational information safety measures. RosholmDell also takes measures in order to protect your Personal Data against unauthorised access, misuse, reveals, changes and damages. RosholmDell ensures that access to your Personal Data is only granted to employees who need to Process it in order to fulfil their work assignments, and that they abide by confidentiality.`,
  },
  subtitlethirteenDesc: {
    one: `If you are dissatisfied with how your Personal Data has been processed or believe that your Personal Data has been Processed contrary to The Data Protection Rules you can at first-hand contact us. You can also always turn to the supervisory authority in the Member State where you have your place of residence or where the alleged breach has been conducted to file a complaint. The supervisory authority in Sweden is Swedish Authority for Privacy Protection, see`,
    two: "https://www.imy.se/en/RosholmDell",
    three: " annually reviews this Privacy Notice.",
  },
  subtitlefourteenDesc: {
    one: `RosholmDell’s services may in some cases be subject to third party’s terms and conditions. RosholmDell is not responsible for such a third party’s use of your Personal Data as they themselves are data controllers and responsible for the Processing of your Personal Data. Hence, it is important that you observe and read through the terms and conditions of such third parties. The same applies if there is a link on our Website to other websites.`,
  },
  subtitlefifteenDesc: {
    one: `RosholmDell reserves the right to change this Privacy Notice when we deem it to be necessary. Such changes are usually warranted upon potential changes to applicable legislation, due to statements from the supervisory authority, the Swedish Bar Association, or other authorities issuing statements pertaining to the Data Protection Rules. Further, this Privacy Notice will be updated when it is necessary due to changes in our business activities.`,
    two: "If RosholmDell makes comprehensive changes to this Privacy Notice or changes concerning how we Process your Personal Data, you will be informed of this before such a change come into force.",
  },
  subtitlesixteenDesc: {
    one: `If you have questions pertaining to this Privacy Notice or the current Processing of your Personal Data, wish to file a request in accordance with this Privacy Notice or wish to report a violation of this Privacy Notice etc., you are welcome to contact us via e-mail, phone or letter.`,
  },
};
const cookieNoticePage = {
  title: "Cookie Notice",
  titledesc:
    "This cookie notice (“<strong>Cookie Notice </strong>“) describes how RosholmDell Advokatbyrå, org. no. 556858-6290 (“<strong>RosholmDell</strong>“, “<strong>we</strong>” or “<strong>us</strong>“), uses cookies, web beacons and similar technology (“<strong>Cookies</strong>“) to collect and store information when you visit our website",
  titleLink: "www.rosholmdell.se",
  titledesc2:
    "(“<strong>Website</strong>“). The purpose of this Cookie Notice is to be transparent about our usage of Cookies and the information collected through them, for you to have sufficient knowledge to be able to choose whether or not you want to consent to our use of Cookies.",
  subtitleone: "Summary",
  subtitletwo: "What are cookies?",
  subtitlethree: "Why do we use cookies?",
  subtitlefour: "How do we process personal data?",
  subtitlefive: "What categories of cookies do we use?",
  subtitlesix: "What is mean by consent to Cookies?",
  subtitleseven: "How do I delete cookies?",
  subtitleeight: "Update of Cookie Notice",
  subtitlenine: "Contact information",
  subtitleTen: "",
  subtitleoneDesc: {
    one: `This Cookie Notice is intended to help you understand more about this technology and how we use it on the Website. More detailed information regarding our use of Cookies will follow further down in this Cookie Notice.`,
    two: `When you visit or interact with the Website, Cookies are used to store information in order to give you a better, faster, and more secure experience.`,
    three: `Our Cookies have different purposes. They are needed either for the functionality of our services, to help us improve our services or for the Website to carry out certain functions. We use different types of Cookies, those that remain on your computer, mobile or tablet (“<strong>Device</strong>“) for as long as you keep your browser active (Session cookies) and those that remain on your Device for a longer period of time (Persistent cookies). By agreeing to our use of Cookies, you agree to how we process the information collected in connection therewith. The information collected may possibly constitute personal data. You may choose to accept all or certain categories of Cookies. You are free to block, delete or disable Cookies if your Device allows it. Cookie settings are managed in your browser or Device.`,
    four: "We use appropriate physical, technical and organizational security measures to prevent unauthorized processing of personal data collected through our Cookies. These security measures are adapted to the amount and sensitivity of the personal data collected. A unique identifier ensures that only we have access to such information collected through the use of Cookies.",
  },
  subtitletwoDesc: {
    one: "A Cookie is a text file that collects information which the Website stores on your Device. Cookies cannot spread viruses or other malicious software. Cookies are used, among other things, to enable you to use the Website’s services, enable tracking your usage of the Website or to remember your preferences, e.g. language settings. Cookies do not record who you are or where you live, they may however record which websites you have visited on your Device.",
    two: "There are different types of Cookies:",
    three: {
      1: "(i) Session Cookies: These are temporarily stored on your Device when you visit our Website and deleted when you close your browser.",
      2: "(ii) Persistent Cookies: These are stored on your Device until they expire or until you remove them.",
    },
  },
  subtitlethreeDesc: {
    one: `We use Cookies to enable us to make our Website easier and safer for you to use, receive information about how you use our Website and improve the services we offer you.`,
  },
  subtitlefourDesc: {
    one: "In some cases, our use of Cookies may mean that we process your personal data. In these contexts, we follow our principles for processing personal data which you can read more about in our Privacy Policy.",
    two: "Your consent is the legal basis for our processing of your personal data using Cookies. Your consent is obtained when you agree to our use of Cookies via the Cookie banner. The legal basis for processing of personal data collected through necessary Cookies is however our legitimate interest in ensuring the correct technical function, access, and use of the Website and in providing the services requested by you. Therefore, we will not obtain your consent regarding the use of necessary Cookies.",
    three:
      "We retain your Cookie information for up to 13 months after it was last activated (on your last visit to our Website).",
  },
  subtitlefiveDesc: {
    one: `We have divided our Cookies into three different categories. You can agree to all or certain categories of Cookies. By agreeing to a particular category, you accept all Cookies in that category. In the following you can read more about the different categories of Cookies we use and how we use the information collected through them.`,
    two: "You find the complete list of information about all Cookies used on the Website in the Cookie-settings.",
    three: {
      title: "NECESSARY COOKIES:",
      desc: "Necessary Cookies enables the Website to carry out certain basic functions and cannot be rejected. They are used when you use a feature on the Website that needs an answer, e.g. when setting Cookie selection. These Cookies make it possible to navigate between different pages and set personal preferences, e.g. language. Necessary Cookies do not store any personal data.",
    },
    four: {
      title: "FUNCTIONAL COOKIES:",
      desc: "Functional Cookies help the Website to offer better functionality and personalization. These Cookies are used e.g. to remember your previous choices when using our services (such as language setting). If you do not allow Functional Cookies, some or all of these services may stop working properly. You can choose to reject these Cookies in the Cookie banner.",
    },
    five: {
      title: "PERFORMANCE COOKIES:",
      desc: "Performance Cookies allow us to count the number of visits and referrals. They help us to know which pages are most frequently visited and how visitors navigate the Website, so that we can compile statistics and thus improve how content is presented on our Website. We save information about e.g. which links you have clicked on and which region you are in. The information collected by Performance Cookies is aggregated and therefore completely anonymous. You can choose to reject these Cookies in the Cookie banner.",
    },
  },
  subtitlesixDesc: {
    one: `Regardless of whether the Cookies used on our Website involve the processing of personal data or not, we need your consent to place Cookies on your Device. You agree by accepting all or certain categories of Cookies in the Cookie banner that appears when you visit the Website. However, you may not opt out of the Necessary Cookies since they must be placed on your Device for certain services to work.`,
    two: "You can withdraw your consent to Cookies at any time, see section 8 for information on how to proceed.",
  },
  subtitlesevenDesc: {
    one: `You can refrain from giving consent to Cookies (except Necessary Cookies) and you may also withdraw your consent. This is done by changing the consent settings in the Cookie banner or in the Device’s browser. Note that changed Cookie settings take effect from the time of the change and do not affect previously collected information through Cookies.`,
    two: "In your browser settings, you can block all Cookies but the necessary ones. You can also, for instance, choose to only accept first-party cookies or choose to delete Cookies when you close your browser. Keep in mind that the restrictions of Cookies may affect the functionality of the Website.",
    three:
      "Different browsers use different methods to handle Cookies. Follow the instructions below, directly from the browser manufacturer, to configure the settings in your browser. * If you have a Device with a newer browser, you can clear Cookies by using the following shortcut: Ctrl + Shift + Delete. Note that if you use multiple browsers, you need to clear cookies in all browsers you use.",
    four: {
      1: "Microsoft Internet Explorer",
      2: "Google Chrome",
      3: "Safari",
      4: "Firefox",
      5: "Microsoft Edge",
    },
    five: "After clearing Cookies in the browser, our Cookie banner will reappear, and you will have to make a new choice on whether or not to allow Cookies.",
    six: "*These links go to third-party websites, over which we have no control – therefore, we cannot be held responsible for any errors in these.",
  },
  subtitleeightDesc: {
    one: `Any change regarding how we use Cookies will be published here and, if necessary, brought to your attention on our Website.`,
  },
  subtitlenineDesc: {
    one: `If you have questions or comments about our Cookie Notice and/or processing of personal data, you are always welcome to contact us via e-mail`,
    link: "info@rdlaw.se.",
  },
  subtitleTenDesc: {
    one: "",
  },
};
const termsAndConditionsPage = {
  title: "ROSHOLMDELL LAW FIRM’S GENERAL TERMS AND CONDITIONS (2023:1)",
  subtitleone: "APPLICATION",
  subtitletwo: "THE SCOPE OF THE ASSIGNMENT",
  subtitlethree: "UNDERTAKING AND TERMINATION OF ASSIGNMENTS",
  subtitlefour: "IDENTIFICATION AND CONFLICT OF INTEREST",
  subtitlefive: "PROCESSING OF PERSONAL DATA",
  subtitlesix: "CONFIDENTIALITY AND INFORMATION",
  subtitleseven: "ELECTRONIC COMMUNICATION",
  subtitleeight: "ARCHIVING AND RETENTION OF MATERIAL",
  subtitlenine: "INTELLECTUAL PROPERTY",
  subtitleten: "FEES AND EXPENSES",
  subtitleeleven: "INVOICING AND PAYMENT",
  subtitletwelve: "LIMITATION OF LIABILITY",
  subtitlethirteen: "COMPLAINTS",
  subtitlefourteen: "GOVERNING LAW AND DISPUTE RESOLUTION",
  subtitleoneDesc: {
    one: `These general terms and conditions apply to the services
    provided by RosholmDell Advokatbyrå AB
    (“<strong>RosholmDell</strong>” or “<strong>we</strong>”) on behalf of customers (the
    “<strong>Customer</strong>” and the “<strong>Assignment</strong>”). Alongside these
    terms and conditions, the Code of Conduct for members
    of the Swedish Bar Association (the “<strong>Code of Conduct</strong>”)
    and any other rules regarding advocacy prescribed by law
    or regulations apply.`,
  },
  subtitletwoDesc: {
    one: `At the beginning of the Assignment, RosholmDell and
    the Customer will agree on the scope and execution. The
    scope and execution may thereafter change. Our services
    are supplied and created based on the circumstances, facts
    and instructions the Customer has provided for the
    individual Assignment. We assume that the information
    is complete and correct. Unless the Customer provides
    specific instructions on staffing, each Assignment will be
    staffed as deemed efficient for the Customer.
    RosholmDell reserves the right to engage external
    expertise to the extent deemed necessary for the
    fulfilment of the Assignment.`,
    two: `Our services are intended only for the Customer and
    the purposes for which they were provided. We accept no
    responsibility for the use of our work for unintended
    purposes. The Customer may only rely on information
    presented in final versions. Draft versions only represent
    preliminary assessments. Content published on social
    media, newsletters, and other mailings addressed to the
    public do not constitute legal advice. Even if we have
    estimated the outcome of a matter, we accept no liability
    if this outcome is not achieved.`,
    three: `Our services are based on applicable
      Swedish law. No advice is provided directly by
      RosholmDell on matters or conditions in foreign law or
      tax law. If we have provided contact information to other
      advisors from other jurisdictions or in tax law, their
      advice does not constitute legal advice from
      RosholmDell.`,
  },
  subtitlethreeDesc: {
    one: ` We have no obligation to undertake an Assignment
    and may decline an Assignment without stating our
    reasons. The Code of Conduct of the Swedish Bar
    Association states the conditions for which RosholmDell
    is entitled or obliged to resign from an Assignment. Upon
    RosholmDell’s resignation from an Assignment, the
    Customer is required to pay for the work performed and
    for the expenses accrued until the termination of the
    Assignment.`,
    two: `The Client has the right to, at any time, request in
    writing that RosholmDell withdraws from the
    Assignment. However, the Client shall pay for the work
    performed and expenses incurred up to the termination of
    the Assignment.`,
  },
  subtitlefourDesc: {
    one: `For specific Assignments, we are obliged to assess our
    Customer’s identity and ownership structure as well as
    the nature and purpose of the Assignment. We may ask
    for information regarding the Customer and persons
    involved in the Assignment on the account of the
    Customer and the origin of funds and other assets.
    Furthermore, we may be obliged to verify the information
    provided to us. Therefore, we may obtain information
    from external sources. Data collected for that purpose will
    be processed in accordance with the law and the Code of
    Conduct.`,
    two: `We are required by law to report suspicions of money
    laundering or financing of terrorism to the Financial
    Police. By law, we are prevented from informing the
    Customer of such suspicions or of any eventual report to
    the Financial Police. In case of suspicions of money
    laundering or financing of terrorism, we are obliged to
    refuse or resign from theAssignment.`,
    three: `Before accepting an Assignment, we will evaluate
      possible conflicts of interest to comply with the Code of
      Conduct. We will, therefore, ask for the information
      necessary to determine whether an actual or potential
      conflict of interest exists. Such evaluations will also be
      made during an Assignment if new circumstances
      emerge.`,
  },
  subtitlefiveDesc: {
    one: `When RosholmDell performs the Assignment, personal
    data is processed. RosholmDell processes personal data
    in accordance with the Privacy Notice published on `,
    link: "rdlaw.se.",
  },
  subtitlesixDesc: {
    one: ` RosholmDell is, unless otherwise required by law or
    the Code of Conduct of the Swedish Bar Association,
    subject to a duty of confidentiality in relation to
    information received from the Customer about the
    Customer and its affairs in connection with the
    performance of Assignments. If we, within an
    Assignment, work with other advisers or experts, we are
    entitled to provide them with information that is relevant
    to their ability to give advice or perform services.`,
    two: `We have, in certain cases, a statutory obligation to
    disclose information to relevant tax authorities regarding
    the Customer’s VAT number and the amounts we have
    invoiced to the Customer. By engaging RosholmDell, the
    Customer is deemed to have consented to our disclosure
    of such information to relevant tax authorities in
    accordance with current regulations.`,
  },
  subtitlesevenDesc: {
    one: `RosholmDell uses varying means of communication to
    communicate with the Customer, e.g., through the
    internet via e-mail and other electronic means of
    communication. RosholmDell uses necessary security
    systems for communication with the Customer.
    However, using these communication methods entails
    risks for which RosholmDell does not take
    responsibility. Due to the security systems, necessary emails may end up in spam filters, be blocked by firewalls, etc. The Customer is advised to follow up on
    important e-mail correspondence by telephone.`,
  },
  subtitleeightDesc: {
    one: `In hard copy or electronic form, we will retain, or at a
    third-party store, all relevant copies of documents
    processed and generated in the Assignment. These
    documents are archived for the appropriate period based
    on the nature of the Assignment and for at least the period
    demanded by the law or the Code of Conduct.`,
  },
  subtitlenineDesc: {
    one: `We reserve ownership of all intellectual property rights to
    the work we generated under the Assignment. However,
    the Customer is entitled to use the said work for the
    purposes they were provided. Unless otherwise agreed,
    our generated work may not be disseminated to the public,
    sold or used for marketing purposes.`,
  },
  subtitletenDesc: {
    one: `Our fees comply with the rules and principles of the
    Code of Conduct. Unless otherwise agreed, our fees are
    based on factors such as the scope, nature, difficulty, skill,
    and experience required for the Assignment, the value of
    the Assignment, time factors, and the achieved result. To
    support the fee determination, we internally set an hourly
    rate for each lawyer based on their knowledge, skills, and
    experience. Typically, the hourly rates are reviewed and
    adjusted at the beginning of each calendar year.`,
    two: `If the nature of the Assignment permits,
    RosholmDell can provide an initial estimate of the fee for
    the Assignment. Cost estimates must not be seen as a fee
    limit unless explicitly stated otherwise and may be
    changed due to new circumstances.`,
    three: `In addition to fees, we will charge necessary costs
    and expenses that arise in connection with the
    performance of the Assignment. Such necessary costs
    include, for example, registration fees, couriers, travel, as
    well as fees to external advisors and specialists. If a matter
    under an Assignment is decided in legal proceedings and
    our work corresponds to a price higher than that awarded
    to the Customer in the legal proceedings, we reserve the
    right to invoice the Customer for the higher amount. VAT
    will be added as far as RosholmDell is obliged to charge
    VAT.`,
  },
  subtitleelevenDesc: {
    one: `Unless otherwise agreed, accrued fees and expenses will
    be invoiced on a monthly basis. Invoices are due within
    30 days from the date of invoice. In case of late payment,
    interest will be charged in accordance with the provisions
    of the Swedish Interest Act (Sw. Räntelagen (1975:635)).`,
  },
  subtitletwelveDesc: {
    one: `We are only liable for damage caused to the
    Customer due to negligence on our part during the performance of the Assignment. The liability is limited to
    an amount corresponding to five times the fee, excluding
    VAT, that the Customer has paid for the Assignment.
    Furthermore, we are only responsible for actual and direct
    damage suffered by the Customer. Therefore, the amount
    of our liability shall be reduced by any amounts obtained
    from insurance coverage or a third party. In addition, our
    liability does not cover the decrease or loss of turnover,
    profit, or other indirect damage. RosholmDell is not liable
    for any damage suffered by a third party. Employees of
    RosholmDell are not personally liable for any damage
    caused to the Customer. Claims against RosholmDell
    become statute-barred three years from the occurrence
    that gave rise to the claim unless legal proceedings are
    initiated within the said time frame.`,
  },
  subtitlethirteenDesc: {
    one: `If the Customer should be dissatisfied with our services
    and wishes to make complaints or claims, the Customer
    should contact the CEO of RosholmDell. The CEO,
    together with the lawyer responsible for the Assignment,
    will investigate the complaint and answer any questions
    the Customer has. In the event of a claim against
    RosholmDell, a written report must be sent to the CEO
    describing the alleged neglect or error and the expected
    damage.`,
  },
  subtitlefourteenDesc: {
    one: `Swedish law shall apply to the Assignment. Any
    dispute, controversy or claim arising out of or in
    connection with the Assignment shall be finally settled by
    arbitration in accordance with the Rules for Expedited
    Arbitrations of the Arbitration Institute of the Stockholm
    Chamber of Commerce. The place of arbitration shall be
    Växjö, Sweden.`,
    two: `RosholmDell and the Customer undertake, without
    limitation in time, not to reveal the existence or content of
    an arbitral award relating to the Assignment or
    information from negotiations, arbitral proceedings or
    mediations relating to the Assignment. However, this
    shall not apply if the right or obligation to reveal
    information is prescribed by law or to follow a decision
    by a public authority or the Code of Conduct. Nor does it
    apply in the case of civil protest or other judicial
    proceedings relating to the dispute.`,
    three: `In a dispute between a consumer customer and
     RosholmDell, where a mutual agreement has not been
     reached, the Customer may have the right to have the
     dispute tried by the Swedish Bar Association’s Consumer
     Disputes Committee. The term Consumer refers to any
     natural person acting outside the scope of his or her own
     commercial or professional activities. More information
     on the Swedish Bar Association’s Consumer Disputes
     Committee, including contact information, is available at `,
    link: "advokatsamfundet.se/konsumenttvistnamnden/.",
  },
};
export const EnglishStrings = {
  common,
  routeNames,
  homePage,
  aboutPage,
  careerPage,
  ourStoryPage,
  jobpositionPage,
  newsAndInsightsPage,
  servicePage,
  contactPage,
  commercialIpTechnology,
  disputeResolutionPage,
  complainceAndRegulatoryPage,
  corporateAndEquityPage,
  startupGrowthAndVenturesPage,
  environmentalPage,
  employmentPage,
  mergersAndAcquisitionsPage,
  publicProcurementPage,
  railwayAndPublicTransportPage,
  realEstateAndConstructionPage,
  whistleBlowingSolutionPage,
  employmentLawAsaServicePage,
  dpoAsaServicePage,
  whistleblowerPolicyPage,
  privacyNoticePage,
  cookieNoticePage,
  termsAndConditionsPage,
  ipportfolioManagementPage,
};
