import React, { useEffect, useState } from "react";
import commonStyles from "../../components/css/common.module.css";
import styles from "./styles.module.css";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import { useAppData } from "../../providers/AppDataProvider";
import Button from "../../components/button";
import { aboutPageHeroImg } from "../../resources/images";
import ContentUi from "../../components/contentuicomponent";
import { getPostDetailsApi } from "../../networking/api/posts";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import NewsCard from "../../components/newscard";
import Loader from "../../components/loader";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { Helmet } from "react-helmet";

const NewsAndInsightsView = () => {
  const { width, height } = useWindowDimensions();
  const location = useLocation();
  const data = decodeURIComponent(location.search.slice(1));
  const { strings, modalOpen, focus, setFocus, currentLanguage } = useAppData();
  const [postData, setPostData] = useState();
  const [relatedPosts, setRelatedPosts] = useState();
  useEffect(() => {
    setFocus(true);
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [modalOpen]);
  //api for getting individual post details
  useEffect(() => {
    try {
      getPostDetailsApi({ slug: data }).then((res) => {
        if (res.data.type === "success") {
          setPostData(res.data.data.post);
          setRelatedPosts(res.data.data.related_posts);
        } else {
          console.log(res.data.message);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, [data]);
  return (
    <div
      className={
        focus ? styles.pageFocusContainerStyle : styles.pageBlurContainerStyle
      }
    >
      <Helmet>
        <meta property="og:title" content={postData?.title[currentLanguage]} />
        <meta property="og:image" content={postData?.image} />
        <meta
          property="og:description"
          content={postData?.content[0].value[currentLanguage]}
        />
        <meta
          property="og:url"
          content={`https://rosholmdell.se/news&insightsview?${location.search.slice(
            1
          )}`}
        />
        <meta name="og:site_name" content="RosholmDell"></meta>
        <meta name="description" content="Useful Legal Services" />
        <meta name="keywords" content="Useful Legal Services" />

        {postData && (
          <title>RosholmDell | {`${postData?.title[currentLanguage]}`}</title>
        )}
      </Helmet>
      <Navbar blueNav={true} />
      {!postData ? (
        <Loader />
      ) : (
        <div
          className={
            modalOpen
              ? commonStyles.commonMainDownContainerStyle
              : commonStyles.commonMainContainerStyle
          }
        >
          <div className={styles.viewInsideContainerStyle}>
            <div className={styles.contentContainerStyle}>
              <h1
                style={
                  postData.title_size && {
                    fontSize: `${
                      (postData.title_size / 100) *
                      (width > 1080
                        ? 56
                        : width > 485
                        ? 48
                        : width > 360
                        ? 40
                        : 32)
                    }px`,
                    lineHeight: `${
                      (postData.title_size / 100) *
                      (width > 1080
                        ? 60
                        : width > 485
                        ? 52
                        : width > 360
                        ? 44
                        : 36)
                    }px`,
                  }
                }
                className={styles.headerTextSTyle}
              >
                {postData?.title[currentLanguage]}
              </h1>
              <div className={styles.imageWrapperStyle}>
                <img src={postData?.image} className={styles.imgStyle} />
              </div>
              <div className={styles.postContentStyle}>
                <ContentUi Data={postData?.content} />
                {postData?.pdf_url && (
                  <p className={styles.textStyle}>
                    {strings.common.readMore}
                    <a
                      className={styles.linkTextStyle}
                      href={postData?.pdf_url}
                      target="_blank"
                    >
                      {strings.common.here}
                    </a>
                    .
                  </p>
                )}
              </div>
              <div className={styles.cardsGridContainerStyle}>
                {relatedPosts &&
                  relatedPosts.map((item, index) => {
                    return (
                      <Link
                        className={styles.linkStyle}
                        key={index}
                        to={{
                          pathname: "/news&insightsview",
                          search: `${item.slug}`,
                        }}
                        target={"_blank"}
                      >
                        <NewsCard
                          date={item.created_at}
                          image={item.image}
                          headerText={item.title[currentLanguage]}
                          headerContentText={
                            item.content[0].value[currentLanguage]
                          }
                        />
                      </Link>
                    );
                  })}
              </div>
            </div>
          </div>
          <Footer blueFooter={true} />
        </div>
      )}
    </div>
  );
};

export default NewsAndInsightsView;
