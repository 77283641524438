import React, { useState } from "react";
import styles from "./styles.module.css";
import TextInput from "../../components/textInput";
import Button from "../../components/button";
import EmailValidator from "../../helpers/emailvalidator";
import { UserLogin } from "../../networking/api/login";
import { useUserData } from "../../providers/UserDataProvider";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useAppData } from "../../providers/AppDataProvider";

const LoginPage = () => {
  const navigate = useNavigate();
  const { strings } = useAppData();
  const { setUserData, setAccessToken } = useUserData();
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState("");
  const validation = (text) => {
    const res = EmailValidator(text);
    setUser({ ...user, email: text });
    if (res) {
      setError(" ");
    } else {
      setError("Email is not valid");
    }
  };
  //api for user login
  const onSubmitUser = () => {
    if (user.email === "" || user.password === "") {
      setError("Please enter valid details");
    } else {
      try {
        UserLogin(user).then((res) => {
          if (res.data.type === "success") {
            setUserData(res.data.data);
            setAccessToken(res.data.token);
            navigate("/dashboard");
          } else {
            setError(res.data.message);
          }
        });
      } catch (error) {
        setError(error);
      }
    }
  };
  return (
    <div className={styles.inputContainerStyle}>
      <Helmet>
        <meta name="description" content="Useful Legal Services" />
        <meta name="keywords" content="Useful Legal Services" />
        <title>RosholmDell | {strings.common.login}</title>
      </Helmet>
      <h2 className={styles.headerTextStyle}>Login</h2>
      <div className={styles.inputWrapperStyle}>
        <TextInput
          placeholder={"Enter your email.."}
          value={user.email}
          onChange={(e) => validation(e.target.value)}
        />
        <TextInput
          placeholder={"Password"}
          value={user.password}
          type={"password"}
          onChange={(e) => {
            setUser({ ...user, password: e.target.value });
            setError(" ");
          }}
        />
        <p className={styles.errorTextStyle}>{error}</p>
      </div>
      <Button
        buttonName={"Login"}
        onClick={() => onSubmitUser()}
        btnStyle={styles.buttonStyles}
      />
    </div>
  );
};

export default LoginPage;
