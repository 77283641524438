import React, { useState } from "react";
import styles from "./styles.module.css";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
const NumberInput = (props) => {
  return (
    <div className={styles.containerStyle}>
      <div className={styles.leftcontainer}>
        <p className={styles.counrtyCodeTextStyle}>+46 (0)</p>
      </div>
      <div className={styles.deviderStyle}></div>
      <input
        placeholder={props.placeholder}
        value={props.value}
        className={styles.inputStyle}
        onChange={(text) => props.onChange(text)}
        type={"tel"}
      />
      {/* <PhoneInput
        country={"se"}
        placeholder={props.placeholder}
        value={props.value}
        onChange={(text) => props.onChange(text)}
        inputStyle={{ border: "none" }}
        buttonStyle={{ border: "none", backgroundColor: "#ffffff" }}
      /> */}
    </div>
  );
};

export default NumberInput;
