import React, { useEffect, useState } from "react";
import commonStyles from "../../components/css/common.module.css";
import styles from "./styles.module.css";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import { useAppData } from "../../providers/AppDataProvider";
import Button from "../../components/button";
import { BackArrowSvg, NextArrowSvg } from "../../components/svgcomponents";
import { useNavigate } from "react-router-dom";
import { aboutPageHeroImg, orangeRightUpArrow } from "../../resources/images";
import { getTestimonialListApi } from "../../networking/api/testimonial";
import { Helmet } from "react-helmet";

const AboutPage = () => {
  const navigate = useNavigate();
  const { strings, modalOpen, focus, setFocus, currentLanguage } = useAppData();
  const [testiMonialData, setTestimonialData] = useState();
  const [indexNo, setIndexNo] = useState(0);
  useEffect(() => {
    setFocus(true);
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [modalOpen]);
  //api for getting testimonials
  useEffect(() => {
    try {
      getTestimonialListApi().then((res) => {
        if (res.data.type === "success") {
          setTestimonialData(res.data.data);
        } else {
          console.log(res.data.message);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, []);
  const nextClick = () => {
    if (indexNo < testiMonialData?.length - 1) {
      setIndexNo(indexNo + 1);
    }
  };
  const prevClick = () => {
    if (indexNo >= 1) {
      setIndexNo(indexNo - 1);
    }
  };
  return (
    <div
      className={
        focus
          ? styles.aboutPageFocusContainerStyle
          : styles.aboutPageBlurContainerStyle
      }
    >
      <Helmet>
        <meta name="description" content="Useful Legal Services" />
        <meta name="keywords" content="Useful Legal Services" />
        <link rel="canonical" href="https://rosholmdell.se/about" />
        <title>RosholmDell | {strings.common.aboutUs}</title>
      </Helmet>
      <Navbar blueNav={true} />
      <div
        className={
          modalOpen
            ? commonStyles.commonMainDownContainerStyle
            : commonStyles.commonMainContainerStyle
        }
      >
        <div className={styles.aboutPageInsideContainerStyle}>
          <div className={styles.aboutPagetopSectionStyle}>
            <div className={styles.aboutHeaderContainerStyle}>
              <h2 className={styles.aboutHeaderTextStyle}>
                {strings.aboutPage.heroHeaderText}
                <span className={styles.aboutHeaderTextOrangeStyle}>
                  {strings.aboutPage.heroHeaderText2}
                </span>
              </h2>
              <div className={styles.aboutContentMainContainerStyle}>
                <div className={styles.aboutContentWrapperStyle}>
                  <p className={styles.aboutContentHeaderTextStyle}>
                    {strings.aboutPage.heroContentHeader1}
                  </p>
                  <p className={styles.aboutContentTextStyle}>
                    {strings.aboutPage.heroContent1}
                  </p>
                </div>
                <div className={styles.aboutContentWrapperStyle}>
                  <p className={styles.aboutContentHeaderTextStyle}>
                    {strings.aboutPage.heroContentHeader2}
                  </p>
                  <p className={styles.aboutContentTextStyle}>
                    {strings.aboutPage.heroContent2}
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.heroImageWrapperStyle}>
              <img src={aboutPageHeroImg} className={styles.heroImgStyle} />
            </div>
            <div className={styles.aboutMiddleContentContainerStyle}>
              <div className={styles.aboutContentOuterMainContainerStyle}>
                <div className={styles.aboutContentMainContainerStyle}>
                  <div className={styles.aboutContentWrapperStyle}>
                    <p className={styles.aboutContentHeaderTextStyle}>
                      {strings.aboutPage.heroContentHeader3}
                    </p>
                    <p className={styles.aboutContentTextStyle}>
                      {strings.aboutPage.heroContent3}
                    </p>
                  </div>
                  <div className={styles.aboutContentWrapperStyle}>
                    <p className={styles.aboutContentHeaderTextStyle}>
                      {strings.aboutPage.heroContentHeader4}
                    </p>
                    <p className={styles.aboutContentTextStyle}>
                      {strings.aboutPage.heroContent4}
                    </p>
                  </div>
                </div>
                <div className={styles.aboutButtonContainerStyle}>
                  <Button
                    buttonName={strings.aboutPage.contactus}
                    btnStyle={styles.buttonStyle}
                    arrowBtnStyle={styles.arrBtnStyle}
                    onClick={() => {
                      setFocus(false);
                      setTimeout(() => {
                        navigate("/contactus");
                      }, 100);
                    }}
                  />
                </div>
              </div>
              <div className={styles.aboutBottomLinkContainerStyle}>
                <div
                  className={styles.aboutBottomLinkItemWrapperStyle}
                  onClick={() => {
                    setFocus(false);
                    setTimeout(() => {
                      navigate("/services&solutions");
                    }, 100);
                  }}
                >
                  <p className={styles.orangeTextColorStyle}>
                    {strings.aboutPage.link1}
                  </p>
                  <div className={styles.arrowOrangeWrapperStyle}>
                    <img src={orangeRightUpArrow} className={styles.imgStyle} />
                  </div>
                </div>
                <div
                  className={styles.aboutBottomLinkItemWrapperStyle}
                  onClick={() => {
                    setFocus(false);
                    setTimeout(() => {
                      navigate("/ourteam");
                    }, 100);
                  }}
                >
                  <p className={styles.orangeTextColorStyle}>
                    {strings.aboutPage.link2}
                  </p>
                  <div className={styles.arrowOrangeWrapperStyle}>
                    <img src={orangeRightUpArrow} className={styles.imgStyle} />
                  </div>
                </div>
                <div
                  className={styles.aboutBottomLinkItemWrapperStyle}
                  onClick={() => {
                    setFocus(false);
                    setTimeout(() => {
                      navigate("/careers");
                    }, 100);
                  }}
                >
                  <p className={styles.orangeTextColorStyle}>
                    {strings.aboutPage.link3}
                  </p>
                  <div className={styles.arrowOrangeWrapperStyle}>
                    <img src={orangeRightUpArrow} className={styles.imgStyle} />
                  </div>
                </div>
                <div
                  className={styles.aboutBottomLinkItemWrapperStyle}
                  onClick={() => {
                    setFocus(false);
                    setTimeout(() => {
                      navigate("/ourstory");
                    }, 100);
                  }}
                >
                  <p className={styles.orangeTextColorStyle}>
                    {strings.aboutPage.link4}
                  </p>
                  <div className={styles.arrowOrangeWrapperStyle}>
                    <img src={orangeRightUpArrow} className={styles.imgStyle} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.aboutTestimonialContainerStyle}>
            <p className={styles.aboutTestimonialHeaderStyle}>
              {strings.aboutPage.testimonialHeader}
            </p>
            <div className={styles.testimonialContainerStyle}>
              {testiMonialData &&
                testiMonialData.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      {index === indexNo && (
                        <div className={styles.aboutTestimonialWrapperStytle}>
                          <p className={styles.aboutTestimonialTextStyle}>
                            {item.content[currentLanguage]}
                          </p>
                          <p className={styles.aboutContentTextStyle}>
                            {item.author}
                          </p>
                        </div>
                      )}
                    </React.Fragment>
                  );
                })}

              <div className={styles.arrowBtnContainerStyle}>
                <div
                  className={styles.arrowWrapperStyle}
                  onClick={() => prevClick()}
                >
                  <BackArrowSvg
                    color={
                      indexNo > 0
                        ? styles.nextArrowStyle
                        : styles.BackArrowStyle
                    }
                  />
                </div>
                <div
                  className={styles.arrowWrapperStyle}
                  onClick={() => nextClick()}
                >
                  <NextArrowSvg
                    color={
                      indexNo === testiMonialData?.length - 1
                        ? styles.BackArrowStyle
                        : styles.nextArrowStyle
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer blueFooter={true} />
      </div>
    </div>
  );
};

export default AboutPage;
