import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import commonStyles from "../../../components/css/common.module.css";
import Navbar from "../../../components/navbar";
import Footer from "../../../components/footer";
import { useAppData } from "../../../providers/AppDataProvider";
import {
  downOrangeSmallArrow,
  plusBlackIcon,
  KerstinEifrém,
  orangeCheckBox,
  circleEmpty,
} from "../../../resources/images";
import ProfileCard from "../../../components/profilecard";
import MenuPopover from "../../../components/popover";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const WhistleBlowingSolutionPage = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { strings, modalOpen, focus, setFocus } = useAppData();
  const [policy, setPolicy] = useState(false);
  const [provide, setProvide] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const divRefProfile = React.useRef();
  const openPop = Boolean(anchorEl);
  const id = openPop ? "simple-popover" : undefined;
  useEffect(() => {
    setFocus(true);
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [modalOpen]);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(divRefProfile.current);
  };
  const PointsData = [
    strings.whistleBlowingSolutionPage.weprovidePoints.one,
    strings.whistleBlowingSolutionPage.weprovidePoints.two,
    strings.whistleBlowingSolutionPage.weprovidePoints.three,
    strings.whistleBlowingSolutionPage.weprovidePoints.four,
    strings.whistleBlowingSolutionPage.weprovidePoints.five,
    strings.whistleBlowingSolutionPage.weprovidePoints.six,
    strings.whistleBlowingSolutionPage.weprovidePoints.seven,
    strings.whistleBlowingSolutionPage.weprovidePoints.eight,
  ];
  const Solutions = [
    {
      title: strings.servicePage.whistleblowingSolution,
      url: "/services&solutions/whistleblowingsolution",
    },
    {
      title: strings.servicePage.employmentLawSolution,
      url: "/services&solutions/employmentlawasaservice",
    },
    {
      title: strings.servicePage.dpoAsService,
      url: "/services&solutions/dpoasaservice",
    },
    {
      title: strings.servicePage.ipportfoliomanagment,
      url: "/services&solutions/ipportfoliomanagement",
    },
  ];
  const userData = {
    name: "Kerstin Eifrém",
    designation: "Associate",
    image: KerstinEifrém,
    email: "kerstin.eifrem@rdlaw.se",
    mobile: "+46 709 795 140",
  };
  return (
    <div
      className={
        focus
          ? styles.whistlePageFocusContainerStyle
          : styles.whistlePageBlurContainerStyle
      }
    >
      <Helmet>
        <meta name="description" content="Useful Legal Services" />
        <meta name="keywords" content="Useful Legal Services" />
        <link
          rel="canonical"
          href="https://rosholmdell.se/services&solutions/whistleblowingsolution"
        />
        <title>
          RosholmDell | {strings.servicePage.whistleblowingSolution}
        </title>
      </Helmet>
      <Navbar blueNav={true} />
      <div
        className={
          modalOpen
            ? commonStyles.commonMainDownContainerStyle
            : commonStyles.commonMainContainerStyle
        }
      >
        <div className={styles.pageInsideContainerStyle}>
          <div className={styles.headerContainerStyle}>
            <div className={styles.headerTopContainerStyle}>
              <div
                className={styles.linkContainerStyle}
                onClick={() => handleClick()}
                ref={divRefProfile}
              >
                <p className={styles.headerLinkListTextSTyle}>
                  {strings.servicePage.whistleblowingSolution.toLocaleUpperCase()}
                </p>
                <div className={styles.arrowImgWrapperStyle}>
                  <img
                    src={downOrangeSmallArrow}
                    className={styles.arrImgStyle}
                  />
                </div>
              </div>
              <h3 className={styles.headerTextStyle}>
                {strings.whistleBlowingSolutionPage.title}
                <span className={styles.headerTextOrangeStyle}>
                  {strings.whistleBlowingSolutionPage.orangeText}
                </span>
              </h3>
            </div>
            <p className={styles.contentTextStyle}>
              {strings.whistleBlowingSolutionPage.titleDesc}
            </p>
          </div>
          <div className={styles.middleContainerStyle}>
            <div className={styles.middleInsideContainerStyle}>
              <div
                className={
                  policy
                    ? styles.itemContainerStyle
                    : styles.itemContainerStyle2
                }
                onClick={() => {
                  setPolicy(!policy);
                }}
              >
                <p className={styles.contentTextStyle}>
                  {strings.whistleBlowingSolutionPage.ourwhistleBlowingSolution}
                </p>
                <div
                  className={
                    policy
                      ? styles.contentContainerStyle
                      : styles.contentContentOpenStyle
                  }
                >
                  <p className={styles.contentTextStyle}>
                    {strings.whistleBlowingSolutionPage.whistleblowingDescOne}
                  </p>
                  <p className={styles.contentTextStyle}>
                    {strings.whistleBlowingSolutionPage.whistleblowingDescTwo}
                  </p>
                  <p className={styles.contentTextStyle}>
                    <span className={styles.contentOrangeTextStyle}>
                      {strings.whistleBlowingSolutionPage.legalFramework}
                    </span>
                    {strings.whistleBlowingSolutionPage.whistleblowingDescThree}
                  </p>
                </div>
                {!policy && (
                  <div className={styles.imgWrapperStyle}>
                    <img src={plusBlackIcon} className={styles.imgStyle} />
                  </div>
                )}
              </div>
            </div>
            <div className={styles.middleInsideContainerStyle}>
              <div
                className={
                  provide
                    ? styles.itemContainerStyle
                    : styles.itemContainerStyle2
                }
                onClick={() => {
                  setProvide(!provide);
                }}
              >
                <p className={styles.contentTextStyle}>
                  {strings.whistleBlowingSolutionPage.weprovide}
                </p>
                <div
                  className={
                    provide
                      ? styles.contentContainerStyle
                      : styles.contentContentOpenStyle
                  }
                >
                  <p className={styles.contentTextStyle}>
                    {strings.whistleBlowingSolutionPage.weprovideDesc}
                  </p>

                  <ul className={styles.listStyles}>
                    {PointsData.map((item, index) => (
                      <li key={index} className={styles.contentTextStyle}>
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
                {!provide && (
                  <div className={styles.imgWrapperStyle}>
                    <img src={plusBlackIcon} className={styles.imgStyle} />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={styles.userContainerStyle}>
            <div className={styles.userDetailsStyle}>
              <a
                className={styles.userContactLinksTextStyle}
                href={`mailto:${userData.email}`}
              >
                {userData.email}
              </a>
              <a
                className={styles.userContactLinksTextStyle}
                href={`tel:${userData.mobile}`}
              >
                {userData.mobile}
              </a>
              <a
                className={styles.userContactLinksTextStyle}
                onClick={() => navigate("/contactus")}
              >
                {strings.common.writeToUsSmall}
              </a>
            </div>
            <div className={styles.cardContainerStyle}>
              <ProfileCard
                data={userData}
                cardContainerStyle={styles.cardStyle}
                arrWrapperStyle={styles.cardArrowStyle}
                onClick={() => console.log("c")}
              />
            </div>
          </div>
        </div>
        <Footer blueFooter={true} />
      </div>
      <MenuPopover
        id={id}
        open={openPop}
        anchorEl={anchorEl}
        onClose={handleClose}
        position={"center"}
      >
        <div className={styles.popoverContainerStyle}>
          {Solutions.map((item, index) => {
            return (
              <div
                className={styles.popoverLangContainerStyle}
                onClick={() => navigate(item.url)}
              >
                <div className={styles.imageWrapperStyle}>
                  <img
                    src={pathname === item.url ? orangeCheckBox : circleEmpty}
                    className={styles.imgStyle}
                  />
                </div>
                <p className={styles.popoverTextSTyle}>
                  {item.title.toLocaleUpperCase()}
                </p>
              </div>
            );
          })}
        </div>
      </MenuPopover>
    </div>
  );
};

export default WhistleBlowingSolutionPage;
