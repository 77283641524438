import axios from "../axios";
import * as endPoints from "../endpoints";

export const createTestimonialApi = async (data) =>
  Promise.resolve(axios.post(endPoints.createTestimonial, data));

export const getTestimonialListApi = async () =>
  Promise.resolve(axios.get(endPoints.testimonialList));

export const deleteTestimonilaApi = async (Id) =>
  Promise.resolve(
    axios.delete(endPoints.deleteTestimonial.replace("{id}", Id))
  );

export const updateTestimonialApi = async (data) =>
  Promise.resolve(axios.post(endPoints.updateTestimonial, data));
