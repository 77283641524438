import axios from "../axios";
import * as endPoints from "../endpoints";

export const createTeamMemberApi = async (data) =>
  Promise.resolve(axios.post(endPoints.createTeamMember, data));

export const getTeamMembersList = async () =>
  Promise.resolve(axios.get(endPoints.getTeamMembersList));

export const deleteTeamMemberApi = async (Id) =>
  Promise.resolve(axios.delete(endPoints.deleteTeamMember.replace("{id}", Id)));

export const updateTeamMemberApi = async (data) =>
  Promise.resolve(axios.post(endPoints.updateTeamMember, data));

export const getContactListApi = async () =>
  Promise.resolve(axios.get(endPoints.getContactlist));

export const getTeamMemberDetailsApi = async (Id) =>
  Promise.resolve(
    axios.get(endPoints.getTeamMembersDetails.replace("{id}", Id))
  );
