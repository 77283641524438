import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useUserData } from "../providers/UserDataProvider";
import { isValidToken } from "../helpers/tokenvalidator";
import { useEffect } from "react";

const AuthGaurd = (props) => {
  const { accessToken, removeAcessToken, removeUserData } = useUserData();
  const authToken = isValidToken(accessToken);
  const location = useLocation();
  // checking access token is valid or not and removing user data if not valid
  useEffect(() => {
    if (accessToken) {
      const authToken = isValidToken(accessToken);
      if (!authToken) {
        removeAcessToken();
        removeUserData();
      }
    }
  }, []);
  //authentication using auth token
  return (
    <>
      {authToken ? (
        <>{props.children}</>
      ) : (
        <Navigate to={"/login"} state={{ from: location.pathname }} />
      )}
    </>
  );
};

export default AuthGaurd;
