import React, { useState } from "react";
import styles from "./styles.module.css";
import TextInput from "../../../../components/textInput";
import Button from "../../../../components/button";
import {
  createTestimonialApi,
  updateTestimonialApi,
} from "../../../../networking/api/testimonial";
import { useLocation } from "react-router-dom";
import {
  CheckIconSvg,
  CloseIconSvg,
} from "../../../../components/svgcomponents";
import TextAreaInput from "../../../../components/textareainput";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useAppData } from "../../../../providers/AppDataProvider";
import GoBack from "../../../../components/goback";

const CreateTestimonial = () => {
  const location = useLocation();
  const itemData = location.state;
  const navigate = useNavigate();
  const { strings } = useAppData();
  const [testimonial, SetTestimonial] = useState({
    content: itemData ? itemData.content : { en: "", sw: "" },
    author: itemData ? itemData.author : "",
  });
  const [err, setErr] = useState();
  const [success, setSuccess] = useState();
  //validation before api call
  const onSubmit = () => {
    if (testimonial.content.en === "") {
      setErr("English Testimonial is Required");
    } else if (testimonial.content.sw === "") {
      setErr("Swedish Testimonial is Required");
    } else if (testimonial.author === "") {
      setErr("Author is Required");
    } else {
      if (itemData) {
        onUpdateTestimonial();
      } else {
        onSubmitFunction();
      }
    }
  };
  //api for creating testimonial
  const onSubmitFunction = () => {
    setErr();
    setSuccess();
    try {
      createTestimonialApi(testimonial).then((res) => {
        if (res.data.type === "success") {
          SetTestimonial({
            content: { en: "", sw: "" },
            author: "",
          });
          setSuccess(res.data.message);
        } else {
          setErr(res.data.message);
        }
      });
    } catch (error) {
      setErr(error);
    }
  };
  //api for updating edited testimonial
  const onUpdateTestimonial = () => {
    setErr();
    setSuccess();
    try {
      updateTestimonialApi({
        testimonial_id: itemData._id,
        ...testimonial,
      }).then((res) => {
        if (res.data.type === "success") {
          setSuccess(res.data.message);
          alert(res.data.message);
          navigate("/testimoniallist");
        } else {
          setErr(res.data.message);
        }
      });
    } catch (error) {
      setErr(error);
    }
  };
  return (
    <div className={styles.mainContainerStyle}>
      <Helmet>
        <meta name="description" content="Useful Legal Services" />
        <meta name="keywords" content="Useful Legal Services" />
        <title>
          RosholmDell |{" "}
          {itemData
            ? strings.common.updateTestimonial
            : strings.common.createTestimonial}
        </title>
      </Helmet>
      <div className={styles.insideContainerStyle}>
        <GoBack />
        <h1>{itemData ? "Update Testimonial" : "Create Testimonial"}</h1>
        <div className={styles.inputContainerStyle}>
          <p>Testimonial</p>
          <div className={styles.inputInsideContainerStyle}>
            <TextAreaInput
              placeholder={"English Testimonial..."}
              value={testimonial.content.en}
              onChange={(e) => {
                SetTestimonial({
                  ...testimonial,
                  content: {
                    ...testimonial.content,
                    en: e.target.value.trimStart(),
                  },
                });
                setErr();
                setSuccess();
              }}
            />
            <TextAreaInput
              placeholder={"Swedish Testimonial..."}
              value={testimonial.content.sw}
              onChange={(e) => {
                SetTestimonial({
                  ...testimonial,
                  content: {
                    ...testimonial.content,
                    sw: e.target.value.trimStart(),
                  },
                });
                setErr();
                setSuccess();
              }}
            />
            {testimonial.content.length > 1 && (
              <div className={styles.closeIconStyle}>
                <CheckIconSvg color={"#007f00"} />
              </div>
            )}
          </div>
        </div>
        <div className={styles.inputContainerStyle}>
          <p>Author Name</p>
          <div className={styles.inputInsideContainerStyle}>
            <TextInput
              placeholder={"Author Name..."}
              value={testimonial.author}
              onChange={(e) => {
                SetTestimonial({
                  ...testimonial,
                  author: e.target.value.trimStart(),
                });

                setErr();
                setSuccess();
              }}
            />
            {testimonial.author.length > 1 && (
              <div className={styles.closeIconStyle}>
                <CheckIconSvg color={"#007f00"} />
              </div>
            )}
          </div>
        </div>
        {err && <p className={styles.errorMessageStyle}>{err}</p>}
        {success && <p className={styles.successMessageStyle}>{success}</p>}
        <Button
          buttonName={itemData ? "Update" : "Create"}
          btnStyle={styles.buttonStyle}
          onClick={() => {
            onSubmit();
          }}
        />
      </div>
    </div>
  );
};

export default CreateTestimonial;
