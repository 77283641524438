import React from "react";
import Popover from "@mui/material/Popover";
import styles from "./styles.module.css";

const MenuPopover = ({ children, open, onClose, id, anchorEl, position }) => {
  return (
    <Popover
      id={id}
      open={Boolean(open)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 30,
        horizontal: position,
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      elevation={0}
      sx={{
        "& .MuiPopover-paper": {
          borderRadius: 4,
          border: 1,
          borderColor: "#C4C4C4",
          boxShadow: "0px 48px 96px rgba(0, 0, 0, 0.08)",
        },
      }}
    >
      {children}
    </Popover>
  );
};

export default MenuPopover;
