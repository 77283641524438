import React, { useEffect } from "react";
import commonStyles from "../../components/css/common.module.css";
import styles from "./styles.module.css";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import { useAppData } from "../../providers/AppDataProvider";
import { Helmet } from "react-helmet";

const TermsAndConditionsPage = () => {
  const { strings, modalOpen, focus, setFocus } = useAppData();
  useEffect(() => {
    setFocus(true);
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [modalOpen]);

  return (
    <div
      className={
        focus
          ? styles.whistlePolicyPageFocusContainerStyle
          : styles.whistlePolicyPageBlurContainerStyle
      }
    >
      <Helmet>
        <meta name="description" content="Useful Legal Services" />
        <meta name="keywords" content="Useful Legal Services" />
        <link rel="canonical" href="https://rosholmdell.se/terms&conditions" />
        <title>RosholmDell | {strings.common.termsAndCondition}</title>
      </Helmet>
      <Navbar blueNav={true} />
      <div
        className={
          modalOpen
            ? commonStyles.commonMainDownContainerStyle
            : commonStyles.commonMainContainerStyle
        }
      >
        <div className={styles.pageInsideContainerStyle}>
          <h2 className={styles.headerTextStyle}>
            {strings.termsAndConditionsPage.title}
          </h2>
          <div className={styles.itemContainerStyle}>
            <p className={styles.subTitleTextStyle}>
              {strings.termsAndConditionsPage.subtitleone}
            </p>
            <div className={styles.contentContainerStyle}>
              <p
                className={styles.contentTextStyle}
                dangerouslySetInnerHTML={{
                  __html: strings.termsAndConditionsPage.subtitleoneDesc.one,
                }}
              />
            </div>
          </div>
          <div className={styles.itemContainerStyle}>
            <p className={styles.subTitleTextStyle}>
              {strings.termsAndConditionsPage.subtitletwo}
            </p>
            <div className={styles.contentContainerStyle}>
              <p className={styles.contentTextStyle}>
                {strings.termsAndConditionsPage.subtitletwoDesc.one}
              </p>
              <p className={styles.contentTextStyle}>
                {strings.termsAndConditionsPage.subtitletwoDesc.two}
              </p>
              <p className={styles.contentTextStyle}>
                {strings.termsAndConditionsPage.subtitletwoDesc.three}
              </p>
            </div>
          </div>
          <div className={styles.itemContainerStyle}>
            <p className={styles.subTitleTextStyle}>
              {strings.termsAndConditionsPage.subtitlethree}
            </p>
            <div className={styles.contentContainerStyle}>
              <p className={styles.contentTextStyle}>
                {strings.termsAndConditionsPage.subtitlethreeDesc.one}
              </p>
              <p className={styles.contentTextStyle}>
                {strings.termsAndConditionsPage.subtitlethreeDesc.two}
              </p>
            </div>
          </div>

          <div className={styles.itemContainerStyle}>
            <p className={styles.subTitleTextStyle}>
              {strings.termsAndConditionsPage.subtitlefour}
            </p>
            <div className={styles.contentContainerStyle}>
              <p className={styles.contentTextStyle}>
                {strings.termsAndConditionsPage.subtitlefourDesc.one}
              </p>
              <p className={styles.contentTextStyle}>
                {strings.termsAndConditionsPage.subtitlefourDesc.two}
              </p>
              <p className={styles.contentTextStyle}>
                {strings.termsAndConditionsPage.subtitlefourDesc.three}
              </p>
            </div>
          </div>

          <div className={styles.itemContainerStyle}>
            <p className={styles.subTitleTextStyle}>
              {strings.termsAndConditionsPage.subtitlefive}
            </p>
            <div className={styles.contentContainerStyle}>
              <p className={styles.contentTextStyle}>
                {strings.termsAndConditionsPage.subtitlefiveDesc.one}
                <a
                  href="https://rdlaw.se"
                  target="_blank"
                  className={styles.contentBlueTextstyle}
                >
                  {strings.termsAndConditionsPage.subtitlefiveDesc.link}
                </a>
              </p>
            </div>
          </div>

          <div className={styles.itemContainerStyle}>
            <p className={styles.subTitleTextStyle}>
              {strings.termsAndConditionsPage.subtitlesix}
            </p>
            <div className={styles.contentContainerStyle}>
              <p className={styles.contentTextStyle}>
                {strings.termsAndConditionsPage.subtitlesixDesc.one}
              </p>
              <p className={styles.contentTextStyle}>
                {strings.termsAndConditionsPage.subtitlesixDesc.two}
              </p>
            </div>
          </div>
          <div className={styles.itemContainerStyle}>
            <p className={styles.subTitleTextStyle}>
              {strings.termsAndConditionsPage.subtitleseven}
            </p>
            <div className={styles.contentContainerStyle}>
              <p className={styles.contentTextStyle}>
                {strings.termsAndConditionsPage.subtitlesevenDesc.one}
              </p>
            </div>
          </div>

          <div className={styles.itemContainerStyle}>
            <p className={styles.subTitleTextStyle}>
              {strings.termsAndConditionsPage.subtitleeight}
            </p>
            <div className={styles.contentContainerStyle}>
              <p className={styles.contentTextStyle}>
                {strings.termsAndConditionsPage.subtitleeightDesc.one}
              </p>
            </div>
          </div>
          <div className={styles.itemContainerStyle}>
            <p className={styles.subTitleTextStyle}>
              {strings.termsAndConditionsPage.subtitlenine}
            </p>
            <div className={styles.contentContainerStyle}>
              <p className={styles.contentTextStyle}>
                {strings.termsAndConditionsPage.subtitlenineDesc.one}
              </p>
            </div>
          </div>

          <div className={styles.itemContainerStyle}>
            <p className={styles.subTitleTextStyle}>
              {strings.termsAndConditionsPage.subtitleten}
            </p>
            <div className={styles.contentContainerStyle}>
              <p className={styles.contentTextStyle}>
                {strings.termsAndConditionsPage.subtitletenDesc.one}
              </p>
              <p className={styles.contentTextStyle}>
                {strings.termsAndConditionsPage.subtitletenDesc.two}
              </p>
              <p className={styles.contentTextStyle}>
                {strings.termsAndConditionsPage.subtitletenDesc.three}
              </p>
            </div>
          </div>

          <div className={styles.itemContainerStyle}>
            <p className={styles.subTitleTextStyle}>
              {strings.termsAndConditionsPage.subtitleeleven}
            </p>
            <div className={styles.contentContainerStyle}>
              <p className={styles.contentTextStyle}>
                {strings.termsAndConditionsPage.subtitleelevenDesc.one}
              </p>
            </div>
          </div>
          <div className={styles.itemContainerStyle}>
            <p className={styles.subTitleTextStyle}>
              {strings.termsAndConditionsPage.subtitletwelve}
            </p>
            <div className={styles.contentContainerStyle}>
              <p className={styles.contentTextStyle}>
                {strings.termsAndConditionsPage.subtitletwelveDesc.one}
              </p>
            </div>
          </div>
          <div className={styles.itemContainerStyle}>
            <p className={styles.subTitleTextStyle}>
              {strings.termsAndConditionsPage.subtitlethirteen}
            </p>
            <div className={styles.contentContainerStyle}>
              <p className={styles.contentTextStyle}>
                {strings.termsAndConditionsPage.subtitlethirteenDesc.one}
              </p>
            </div>
          </div>
          <div className={styles.itemContainerStyle}>
            <p className={styles.subTitleTextStyle}>
              {strings.termsAndConditionsPage.subtitlefourteen}
            </p>
            <div className={styles.contentContainerStyle}>
              <p className={styles.contentTextStyle}>
                {strings.termsAndConditionsPage.subtitlefourteenDesc.one}
              </p>
              <p className={styles.contentTextStyle}>
                {strings.termsAndConditionsPage.subtitlefourteenDesc.two}
              </p>
              <p className={styles.contentTextStyle}>
                {strings.termsAndConditionsPage.subtitlefourteenDesc.three}
                <a
                  href="https://advokatsamfundet.se/konsumenttvistnamnden/"
                  target="_blank"
                  className={styles.contentBlueTextstyle}
                >
                  {strings.termsAndConditionsPage.subtitlefourteenDesc.link}
                </a>
              </p>
            </div>
          </div>
        </div>
        <Footer blueFooter={true} />
      </div>
    </div>
  );
};

export default TermsAndConditionsPage;
