import React, { useEffect, useRef, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Button from "../../components/button";
import ProfileCard from "../../components/profilecard";
import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import { useAppData } from "../../providers/AppDataProvider";
import styles from "./styles.module.css";
import commonStyles from "../../components/css/common.module.css";
import TextInput from "../../components/textInput";
import NumberInput from "../../components/numberinput";
import TextAreaInput from "../../components/textareainput";
import Modal from "../../components/modal";
import {
  AndersRosholm,
  CarolinaThoft,
  checkBox,
  gallery,
  JonasAxelson,
  map,
  orangeRightUpArrow,
  PeterGruwberger,
  plusBlackIcon,
  minusIcon,
  rightUpArrowSmall,
  circleEmpty,
} from "../../resources/images";
import { getContactListApi } from "../../networking/api/team";
import { MailSendApi } from "../../networking/api/contact";
import EmailValidator from "../../helpers/emailvalidator";
import jsonp from "jsonp";
import PopUP from "../../components/popup";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { Helmet } from "react-helmet";

const ContactUsPage = () => {
  const navigate = useNavigate();
  const { strings, modalOpen, focus, setFocus } = useAppData();
  const [modalData, setModalData] = useState();
  const [user, setUser] = useState({
    full_name: "",
    email: "",
    phone_number: "",
    subject: "",
    message: "",
  });
  const [error, setError] = useState("");
  const [err, setErr] = useState("");
  const [currentCityIndex, setCurrentCityIndex] = useState();
  const [phone, setPhone] = useState("");
  const [captcha, setCaptcha] = useState(false);
  const [enable, setEnable] = useState(false);
  const [popOver, setPopOver] = useState(false);

  useEffect(() => {
    setFocus(true);
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [modalOpen]);
  useEffect(() => {
    if (
      user.full_name.length > 0 &&
      user.email.length > 0 &&
      phone.length > 0 &&
      user.message.length > 0
    ) {
      setEnable(true);
    } else {
      setEnable(false);
      setCaptcha(false);
    }
  }, [user, phone]);
  //api for getting contact members list
  useEffect(() => {
    try {
      getContactListApi().then((res) => {
        if (res.data.type === "success") {
          setModalData(res.data.data);
        } else {
          console.log(res.data.message);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const Offices = [
    {
      id: 1,
      name: "Växjö",
      location: "Kungsgatan 12, SE-352 33, Växjö",
      image: map,
      link: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2179.9841230392617!2d14.806722476878742!3d56.88051527352241!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4657238d78a79e3d%3A0x5730b1b7d4541e52!2sKungsgatan%2012%2C%20352%2033%20V%C3%A4xj%C3%B6%2C%20Sweden!5e0!3m2!1sen!2sin!4v1684135607632!5m2!1sen!2sin",
    },

    {
      id: 2,
      name: "Kalmar",
      location: "Fiskaregatan 44 A, SE-392 31, Kalmar",
      image: map,
      link: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2192.5142583436837!2d16.363090576864604!3d56.66540727343566!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4657d165bce9a383%3A0x18d7a041b03fc0c!2sFiskaregatan%2044A%2C%20392%2031%20Kalmar%2C%20Sweden!5e0!3m2!1sen!2sin!4v1684135699344!5m2!1sen!2sin",
    },

    {
      id: 3,
      name: "Malmö",
      location: "Nordenskiöldsgatan 24, SE-211 19, Malmö",
      image: map,
      link: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2253.434200469177!2d12.992726976795508!3d55.61186017303233!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4653a3f86036545d%3A0x9d2af823ff8a32b4!2sNordenski%C3%B6ldsgatan%2024%2C%20211%2019%20Malm%C3%B6%2C%20Sweden!5e0!3m2!1sen!2sin!4v1684135751441!5m2!1sen!2sin",
    },

    {
      id: 4,
      name: "Helsingborg",
      location: strings.common.address1,
      image: map,
      link: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2228.5393064648374!2d12.691791676519415!3d56.04396517319332!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4652323569921513%3A0xae09c3a428f3d55!2sJ%C3%A4rnv%C3%A4gsgatan%2010B%2C%20v%C3%A5ning.%205%2C%20252%2078%20Helsingborg%2C%20Sweden!5e0!3m2!1sen!2sin!4v1704347184838!5m2!1sen!2sin",
    },
    {
      id: 5,
      name: "Stockholm",
      location: "Vasagatan 7, SE-111 20, Stockholm",
      image: map,
      link: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2035.0543171721797!2d18.054898177016508!3d59.33204697461557!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465f9d60eb89e595%3A0xfd7bf1c1f99e8be8!2sVasagatan%207%2C%20111%2020%20Stockholm%2C%20Sweden!5e0!3m2!1sen!2sin!4v1723021662094!5m2!1sen!2sin",
    },
  ];
  const mapsRedirection = (link) => {
    window.open(link, "__blank");
  };
  const validation = (text) => {
    const res = EmailValidator(text);
    setUser({ ...user, email: text });
    if (res) {
      setError(" ");
    } else {
      setError("Email is not valid");
    }
  };
  // const handleSubmit = () => {
  //   let url =
  //     "https://rdlaw.us14.list-manage.com/subscribe/post-json?u=cd49df441de422a7561dc3a56&amp;id=8707e774cf&amp;f_id=00eb9ae0f0";
  //   jsonp(
  //     `${url}&EMAIL=${user.email}&FNAME=${user.full_name}&PHONE=${
  //       "+46 (0)" + phone
  //     }&MMERGE5=${user.subject}&MMERGE6=${user.message}`,
  //     { param: "c" },
  //     (_, data) => {
  //       const { msg, result } = data;
  //       // do something with response
  //       if (result === "success") {
  //         setPopOver(true);
  //       }
  //     }
  //   );
  // };
  const handleSubmit = () => {
    try {
      MailSendApi({ ...user, phone_number: "+46 (0) " + phone }).then((res) => {
        if (res.data.type === "success") {
          setUser({
            full_name: "",
            email: "",
            phone_number: "",
            subject: "",
            message: "",
          });
          setPhone("");
          setPopOver(true);
        } else {
          setErr(res.data.message);
        }
      });
    } catch (error) {
      setErr(error);
    }
  };
  // const copiedEmail = () => {
  //   setEmailCopied(true);
  //   window.navigator.clipboard.writeText(modalData.email);
  //   setTimeout(() => {
  //     setEmailCopied(false);
  //   }, 1000);
  // };
  // const copiedPhoneNumber = () => {
  //   setPhoneCopied(true);
  //   window.navigator.clipboard.writeText(modalData.phone_number);
  //   setTimeout(() => {
  //     setPhoneCopied(false);
  //   }, 1000);
  // };
  // const linkedinButtonAction = () => {
  //   window.open(modalData.linkedin_url, "__blank");
  // };
  const renderTopConatiner = () => {
    return (
      <div className={styles.contactPagetopSectionStyle}>
        <div className={styles.contactPageTopContainerStyle}>
          <h2 className={styles.topContentTitleTextStyle}>
            {strings.contactPage.contactPageHeaderText}
          </h2>
          <p className={styles.topContentDescTextStyle}>
            {strings.contactPage.contactPageDescText}
          </p>
          <div className={styles.topContainerBtnStyle}>
            <Button
              buttonName={strings.contactPage.contactNumber}
              btnStyle={styles.btnStyle}
              onClick={() => {
                window.location.href = `tel:${strings.contactPage.contactNumber}`;
              }}
            />
            <Button
              buttonName={strings.contactPage.writeToUsText}
              btnStyle={styles.btnStyle}
              onClick={() => {
                tapOnWriteToUs();
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const tapOnWriteToUs = () => {
    document
      .getElementById("contactForm")
      .scrollIntoView({ block: "center", behavior: "smooth" });
  };
  const renderGalleryConatiner = () => {
    return (
      <div className={styles.galleryContainerStyle}>
        {modalData &&
          modalData.map((user, index) => (
            <div key={index} className={styles.profileCardContainerStyle}>
              <ProfileCard
                data={user}
                cardContainerStyle={styles.cardContainerStyle}
                onClick={() => {
                  navigate(`/teammemberdetails/${user._id}`);
                }}
              />
            </div>
          ))}
      </div>
    );
  };
  const renderDevider = () => {
    return <div className={styles.deviderStyle}></div>;
  };

  const renderContactForm = () => {
    return (
      <div className={styles.contactFormContainerStyle}>
        {renderDevider()}
        <div className={styles.sectionContainerStyle}>
          <div>
            <p className={styles.sectionTitleStyle}>
              {strings.contactPage.WriteToUsSmall}
            </p>
          </div>

          <div className={styles.sectionRightContainerStyle}>
            {renderInputContainer()}

            <div
              className={
                enable
                  ? styles.notARobotActiveContainerStyle
                  : styles.notARobotContainerStyle
              }
            >
              <div
                className={styles.checkBoxStyle}
                onClick={() => {
                  if (enable) {
                    setCaptcha(!captcha);
                  }
                }}
              >
                <img
                  src={captcha ? checkBox : circleEmpty}
                  className={styles.checkBoxImgStyle}
                />
              </div>
              <p className={styles.notARobotTextStyle}>
                {strings.contactPage.notARobot}
              </p>
            </div>

            <PopUP
              open={popOver}
              onClick={() => {
                setUser({
                  full_name: "",
                  email: "",
                  phone_number: "",
                  subject: "",
                  message: "",
                });
                setPhone("");
                setPopOver(false);
              }}
            />
            {err && <p className={styles.errorStyle}>{err}</p>}
            <div className={styles.submitBtnContainerStyle}>
              <Button
                buttonName={strings.contactPage.submit}
                btnStyle={
                  captcha ? styles.submitBtnStyle : styles.disableBtnStyle
                }
                disabled={captcha ? false : true}
                onClick={() => {
                  handleSubmit();
                }}
              />
              {captcha && (
                <p className={styles.termsAndConditionsTextStyle}>
                  {strings.contactPage.termsAndConditionsText}&nbsp;
                  <span
                    className={styles.termsAndConditionslinkTextStyle}
                    onClick={() => window.open("/privacynotice", "_blank")}
                  >
                    {strings.contactPage.termsAndConditionsLinkText}
                  </span>
                  &nbsp;{strings.contactPage.termsAndConditionsEndText}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderInputContainer = () => {
    return (
      <div id="contactForm" className={styles.sectionRightSubContainerStyle}>
        <TextInput
          placeholder={strings.contactPage.yourFullName}
          value={user.full_name}
          onChange={(text) => {
            setUser({ ...user, full_name: text.target.value });
            setError();
          }}
        />
        <TextInput
          placeholder={strings.contactPage.yourEmailId}
          value={user.email}
          onChange={(text) => {
            validation(text.target.value);
          }}
          type="email"
        />
        {error && <p className={styles.errorStyle}>{error}</p>}
        <NumberInput
          placeholder={strings.contactPage.yourPhoneNumber}
          value={phone}
          onChange={(e) => {
            setPhone(e.target.value);
          }}
        />
        <TextInput
          placeholder={strings.contactPage.subject}
          value={user.subject}
          onChange={(text) => {
            setUser({ ...user, subject: text.target.value });
          }}
        />
        <TextAreaInput
          placeholder={strings.contactPage.yourMessage}
          value={user.message}
          onChange={(text) => {
            setUser({ ...user, message: text.target.value });
          }}
        />
      </div>
    );
  };

  const selectedCity = (index) => {
    if (index === currentCityIndex) {
      setCurrentCityIndex();
    } else {
      setCurrentCityIndex(index);
    }
  };
  const renderOffices = () => {
    return (
      <div>
        {renderDevider()}
        <div className={styles.sectionContainerStyle}>
          <div>
            <p className={styles.sectionTitleStyle}>
              {strings.contactPage.offices}
            </p>
          </div>
          <div className={styles.sectionRightSubContainerStyle}>
            {Offices.map((office, index) => (
              <div
                key={index}
                className={
                  currentCityIndex === index
                    ? styles.officeContainerStyle
                    : styles.officeContainerStyle2
                }
              >
                <div
                  className={styles.officeSubContainerStyle}
                  onClick={() => selectedCity(index)}
                >
                  <div>
                    <div
                      className={styles.officeTitleContainerstyle}
                      onClick={() =>
                        currentCityIndex === index
                          ? mapsRedirection(office.link)
                          : null
                      }
                    >
                      <p
                        className={
                          currentCityIndex === index
                            ? styles.activeOfficeStyle
                            : styles.inActiveOfficeStyles
                        }
                      >
                        {office.name}
                      </p>
                      <div className={styles.iconContainerStyle}>
                        <img
                          src={
                            currentCityIndex === index
                              ? orangeRightUpArrow
                              : rightUpArrowSmall
                          }
                          className={styles.iconStyle}
                        />
                      </div>
                    </div>
                    <p className={styles.officeLocationTextStyle}>
                      {office.location}
                    </p>
                  </div>

                  <div className={styles.iconContainerStyle}>
                    <img
                      src={
                        currentCityIndex !== index ? plusBlackIcon : minusIcon
                      }
                      className={styles.iconStyle}
                    />
                  </div>
                </div>
                {index === currentCityIndex && (
                  <div
                    className={styles.mapImgContainerStyle}
                    onClick={() =>
                      currentCityIndex === index
                        ? mapsRedirection(office.link)
                        : null
                    }
                  >
                    {/* <img src={office.image} className={styles.mapImgStyle} /> */}
                    <iframe
                      src={office.link}
                      style={{
                        width: "100%",
                        height: "100%",
                        border: "0",
                        allowfullscreen: "",
                        loading: "lazy",
                        referrerpolicy: "no-referrer-when-downgrade",
                      }}
                    ></iframe>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };
  // const renderModalPopUp = () => {
  //   return (
  //     <Modal
  //       closeModal={() => {
  //         setIs_ModalVisible(false);
  //         setModalData();
  //       }}
  //       emailBtnAction={() => {
  //         copiedEmail();
  //       }}
  //       phoneBtnAction={() => {
  //         copiedPhoneNumber();
  //       }}
  //       data={modalData}
  //       emailCopied={emailCopied}
  //       phoneCopied={phoneCopied}
  //       linkedinBtnAction={() => linkedinButtonAction()}
  //     />
  //   );
  // };
  return (
    <div
      className={
        focus
          ? styles.contactPageFocusContainerStyle
          : styles.contactPageBlurContainerStyle
      }
    >
      <Helmet>
        <meta name="description" content="Meet the team at RosholmDell" />
        <meta name="keywords" content="Meet the team at RosholmDell" />
        <link rel="canonical" href="https://rosholmdell.se/contactus" />
        <title>RosholmDell | {strings.common.contactUs}</title>
      </Helmet>
      {/* {is_modalVisible ? (
        renderModalPopUp()
      ) : (
        <> */}
      <Navbar blueNav={true} />
      <div
        className={
          modalOpen
            ? commonStyles.commonMainDownContainerStyle
            : commonStyles.commonMainContainerStyle
        }
      >
        <div className={styles.contactInsideContainerStyle}>
          {renderTopConatiner()}
          {renderGalleryConatiner()}
          {renderContactForm()}
          {renderOffices()}
        </div>
        <Footer blueFooter={true} />
      </div>
      {/* </>
      )} */}
    </div>
  );
};

export default ContactUsPage;
