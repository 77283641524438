import React, { useEffect } from "react";
import styles from "./styles.module.css";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import {
  getTeamMembersList,
  deleteTeamMemberApi,
} from "../../../../networking/api/team";
import {
  getTestimonialListApi,
  deleteTestimonilaApi,
} from "../../../../networking/api/testimonial";
import { useState } from "react";
import Button from "../../../../components/button";
import { useNavigate } from "react-router-dom";
import Loader from "../../../../components/loader";
import { Helmet } from "react-helmet";
import { useAppData } from "../../../../providers/AppDataProvider";
import GoBack from "../../../../components/goback";

const columns = [
  { id: "Author", label: "Author", minWidth: 170 },
  { id: "Description", label: "Description", maxWidth: 200 },
  {
    id: "Edit",
    label: "Edit",
    minWidth: 170,
    align: "right",
  },
  {
    id: "Delete",
    label: "Delete",
    minWidth: 170,
    align: "right",
  },
];

const TestimonialList = () => {
  const navigate = useNavigate();
  const { currentLanguage, strings } = useAppData();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [testimonialData, setTestimonialData] = useState();
  useEffect(() => {
    getTestimonialListData();
  }, []);
  //api for getting testimonial list
  const getTestimonialListData = () => {
    try {
      getTestimonialListApi().then((res) => {
        if (res.data.type === "success") {
          setTestimonialData(res.data.data);
        } else {
          console.log(res.data.message);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  //api for deleting testimonial individually
  const testimonialDeletion = (id) => {
    try {
      deleteTestimonilaApi(id).then((res) => {
        if (res.data.type === "success") {
          removeTestimonial(id);
        } else {
          console.log(res.data.message);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  //updating list after deleting testimonial
  const removeTestimonial = (id) => {
    const newArray = testimonialData.filter((item) => item._id !== id);
    setTestimonialData(newArray);
  };
  return (
    <>
      {!testimonialData ? (
        <Loader loaderStyles={styles.containerStyle} />
      ) : (
        <div className={styles.containerWrapperStyle}>
          <Helmet>
            <meta name="description" content="Useful Legal Services" />
            <meta name="keywords" content="Useful Legal Services" />
            <title>RosholmDell | {strings.common.testimonialList}</title>
          </Helmet>
          <GoBack />
          <h1 className={styles.headerTextStyle}>Testimonials List</h1>

          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: "100%" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell key={column.id}>{column.label}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {testimonialData &&
                    testimonialData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((item, index) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={index}
                          >
                            <TableCell>{item.author}</TableCell>
                            <TableCell
                              style={{ maxWidth: 200, wordBreak: "break-word" }}
                            >
                              {item.content[currentLanguage]}
                            </TableCell>
                            <TableCell>
                              <Button
                                buttonName={"Edit"}
                                onClick={() =>
                                  navigate("/edittestimonial", { state: item })
                                }
                              />
                            </TableCell>
                            <TableCell>
                              <Button
                                buttonName={"Delete"}
                                onClick={() => testimonialDeletion(item._id)}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={testimonialData?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      )}
    </>
  );
};
export default TestimonialList;
