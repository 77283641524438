import React, { useContext, createContext, useEffect, useState } from "react";

const UserDataContext = createContext({
  userData: null,
  accessToken: null,
  setAccessToken: () => {
    return null;
  },
  removeAcessToken: () => {
    return null;
  },
  setUserData: () => {
    return null;
  },
  removeUserData: () => {
    return null;
  },
});

export const UserDataProvider = (props) => {
  const [userData, setUserData] = useState(null);
  const [accessToken, setAccessToken] = useState(null);

  const initialize = async () => {
    let token = localStorage.getItem("token");
    if (token) {
      setAccessToken(JSON.parse(token));
      let data = localStorage.getItem("USER_DATA");
      if (data) {
        setUserData(JSON.parse(data));
      } else {
        setUserData(null);
      }
    } else {
      setAccessToken(null);
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  return (
    <UserDataContext.Provider
      value={{
        userData,
        accessToken: localStorage.getItem("token"),
        setAccessToken: (token) => {
          localStorage.setItem("token", JSON.stringify(token));
          setAccessToken(token);
        },
        removeAcessToken: () => {
          setAccessToken(null);
          localStorage.removeItem("token");
        },
        setUserData: (data) => {
          localStorage.setItem("USER_DATA", JSON.stringify(data));
          setUserData(data);
        },
        removeUserData: () => {
          setUserData(null);
          localStorage.removeItem("USER_DATA");
        },
      }}
    >
      {props.children}
    </UserDataContext.Provider>
  );
};

export const useUserData = () => useContext(UserDataContext);
