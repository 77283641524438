import React, { useEffect } from "react";
import commonStyles from "../../components/css/common.module.css";
import styles from "./styles.module.css";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import { useAppData } from "../../providers/AppDataProvider";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const WhistleBlowerPolicyPage = () => {
  const navigate = useNavigate();
  const { strings, modalOpen, focus, setFocus } = useAppData();
  useEffect(() => {
    setFocus(true);
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [modalOpen]);
  const Data1 = [
    strings.whistleblowerPolicyPage.subtitleoneDesc.one,
    strings.whistleblowerPolicyPage.subtitleoneDesc.two,
    strings.whistleblowerPolicyPage.subtitleoneDesc.three,
  ];
  const Data2 = [
    strings.whistleblowerPolicyPage.subtitletwoDesc.one,
    strings.whistleblowerPolicyPage.subtitletwoDesc.two,
    strings.whistleblowerPolicyPage.subtitletwoDesc.three,
  ];
  const Data3 = [
    strings.whistleblowerPolicyPage.subtitlethreeDesc.three[1],
    strings.whistleblowerPolicyPage.subtitlethreeDesc.three[2],
    strings.whistleblowerPolicyPage.subtitlethreeDesc.three[3],
    strings.whistleblowerPolicyPage.subtitlethreeDesc.three[4],
    strings.whistleblowerPolicyPage.subtitlethreeDesc.three[5],
    strings.whistleblowerPolicyPage.subtitlethreeDesc.three[6],
    strings.whistleblowerPolicyPage.subtitlethreeDesc.three[7],
    strings.whistleblowerPolicyPage.subtitlethreeDesc.three[8],
  ];
  const Data4 = [
    strings.whistleblowerPolicyPage.subtitlefourDesc.three[1],
    strings.whistleblowerPolicyPage.subtitlefourDesc.three[2],
    strings.whistleblowerPolicyPage.subtitlefourDesc.three[3],
    strings.whistleblowerPolicyPage.subtitlefourDesc.three[4],
  ];
  return (
    <div
      className={
        focus
          ? styles.whistlePolicyPageFocusContainerStyle
          : styles.whistlePolicyPageBlurContainerStyle
      }
    >
      <Helmet>
        <meta name="description" content="Useful Legal Services" />
        <meta name="keywords" content="Useful Legal Services" />
        <link
          rel="canonical"
          href="https://rosholmdell.se/whistleblowerpolicy"
        />
        <title>RosholmDell | {strings.common.whistleBlowerPolicy}</title>
      </Helmet>
      <Navbar blueNav={true} />
      <div
        className={
          modalOpen
            ? commonStyles.commonMainDownContainerStyle
            : commonStyles.commonMainContainerStyle
        }
      >
        <div className={styles.pageInsideContainerStyle}>
          <h2 className={styles.headerTextStyle}>
            {strings.whistleblowerPolicyPage.title}
          </h2>
          <div className={styles.itemContainerStyle}>
            <p className={styles.subTitleTextStyle}>
              {strings.whistleblowerPolicyPage.subtitleone}
            </p>
            <div className={styles.contentContainerStyle}>
              {Data1.map((item, index) => {
                return (
                  <p
                    className={styles.contentTextStyle}
                    key={index}
                    dangerouslySetInnerHTML={{
                      __html: item,
                    }}
                  />
                );
              })}
            </div>
          </div>
          <div className={styles.itemContainerStyle}>
            <p className={styles.subTitleTextStyle}>
              {strings.whistleblowerPolicyPage.subtitletwo}
            </p>
            <div className={styles.contentContainerStyle}>
              {Data2.map((item, index) => {
                return (
                  <p
                    className={styles.contentTextStyle}
                    key={index}
                    dangerouslySetInnerHTML={{
                      __html: item,
                    }}
                  />
                );
              })}
            </div>
          </div>
          <div className={styles.itemContainerStyle}>
            <p className={styles.subTitleTextStyle}>
              {strings.whistleblowerPolicyPage.subtitlethree}
            </p>
            <div className={styles.contentContainerStyle}>
              <p className={styles.contentTextStyle}>
                {strings.whistleblowerPolicyPage.subtitlethreeDesc.one}
              </p>
              <p className={styles.contentTextStyle}>
                {strings.whistleblowerPolicyPage.subtitlethreeDesc.two}
              </p>
              <ul>
                {Data3.map((item, index) => {
                  return (
                    <li key={index} className={styles.contentTextStyle}>
                      {item}
                    </li>
                  );
                })}
              </ul>
              <p className={styles.contentTextStyle}>
                <span className={styles.contentOrangeTextstyle}>
                  {strings.whistleblowerPolicyPage.subtitlethreeDesc.four.title}
                </span>
                {strings.whistleblowerPolicyPage.subtitlethreeDesc.four.desc}
              </p>
              <p className={styles.contentTextStyle}>
                <span className={styles.contentOrangeTextstyle}>
                  {strings.whistleblowerPolicyPage.subtitlethreeDesc.five.title}
                </span>
                {strings.whistleblowerPolicyPage.subtitlethreeDesc.five.desc}
              </p>
              <p className={styles.contentTextStyle}>
                <span className={styles.contentOrangeTextstyle}>
                  {strings.whistleblowerPolicyPage.subtitlethreeDesc.six.title}
                </span>
                {strings.whistleblowerPolicyPage.subtitlethreeDesc.six.desc}
              </p>
            </div>
          </div>
          <div className={styles.itemContainerStyle}>
            <p className={styles.subTitleTextStyle}>
              {strings.whistleblowerPolicyPage.subtitlefour}
            </p>
            <div className={styles.contentContainerStyle}>
              <p className={styles.contentTextStyle}>
                {strings.whistleblowerPolicyPage.subtitlefourDesc.one}
              </p>
              <p className={styles.contentTextStyle}>
                {strings.whistleblowerPolicyPage.subtitlefourDesc.two}
              </p>
              <ul>
                {Data4.map((item, index) => {
                  return (
                    <li key={index} className={styles.contentTextStyle}>
                      {item}
                    </li>
                  );
                })}
              </ul>
              <p className={styles.contentTextStyle}>
                {strings.whistleblowerPolicyPage.subtitlefourDesc.four}
              </p>
              <p className={styles.contentTextStyle}>
                {strings.whistleblowerPolicyPage.subtitlefourDesc.five}
              </p>
              <p className={styles.contentTextStyle}>
                {strings.whistleblowerPolicyPage.subtitlefourDesc.six}
              </p>
            </div>
          </div>
          <div className={styles.itemContainerStyle}>
            <p className={styles.subTitleTextStyle}>
              {strings.whistleblowerPolicyPage.subtitlefive}
            </p>
            <div className={styles.contentContainerStyle}>
              <p className={styles.contentTextStyle}>
                <span className={styles.contentOrangeTextstyle}>
                  {strings.whistleblowerPolicyPage.subtitlefiveDesc.one.title}
                </span>
                {strings.whistleblowerPolicyPage.subtitlefiveDesc.one.desc}
              </p>
              <p className={styles.contentTextStyle}>
                <span className={styles.contentOrangeTextstyle}>
                  {strings.whistleblowerPolicyPage.subtitlefiveDesc.two.title}
                </span>
                {strings.whistleblowerPolicyPage.subtitlefiveDesc.two.desc}
                &nbsp;
                <span
                  onClick={() => navigate("/privacynotice")}
                  className={styles.contentBlueTextstyle}
                >
                  {strings.whistleblowerPolicyPage.subtitlefiveDesc.two.desc1}
                </span>
                {strings.whistleblowerPolicyPage.subtitlefiveDesc.two.dot}
              </p>
              <p className={styles.contentTextStyle}>
                <span className={styles.contentOrangeTextstyle}>
                  {strings.whistleblowerPolicyPage.subtitlefiveDesc.three.title}
                </span>
                {strings.whistleblowerPolicyPage.subtitlefiveDesc.three.desc}
              </p>
            </div>
          </div>
        </div>
        <Footer blueFooter={true} />
      </div>
    </div>
  );
};

export default WhistleBlowerPolicyPage;
