import React from "react";
import styles from "./styles.module.css";

const Loader = (props) => {
  return (
    <div
      className={[props.loaderStyles, styles.loaderContainerStyle].join(" ")}
    >
      <div className={styles.loader}></div>
    </div>
  );
};

export default Loader;
