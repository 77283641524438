import React, { useState, useRef } from "react";
import styles from "./styles.module.css";
import MenuPopover from "../popover/index";
import Button from "../button";
import TextInput from "../textInput";
import { CloseIconSvg, CheckIconSvg } from "../svgcomponents";
import ImageUpload from "../imageuploadcomponent";
import TextAreaInput from "../textareainput";

const ContentComponent = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [index1, setIndex1] = useState();

  const openPop = Boolean(anchorEl);
  const id = openPop ? "simple-popover" : undefined;
  const inputRef = useRef();
  const [imageData, setImageData] = useState();
  const [err, setErr] = useState();
  const [success, setSuccess] = useState();
  //image upload function by checking file format
  const imageChange = (e, index) => {
    if (e.target.files && e.target.files.length > 0) {
      var ext = e.target.files[0].name.match(/\.([^\.]+)$/)[1];
      switch (ext) {
        case "jpg":
        case "jpeg":
        case "png":
          setImageData(e.target.files[0]);
          break;
        default:
          alert("Not allowed");
          setImageData();
          inputRef.current.image_link = "";
      }
    }
    const newArray = props.Array.map((item, i) => {
      if (index === i) {
        return { ...item, image_link: "" };
      } else {
        return item;
      }
    });
    props.SetArray(newArray);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  //onclick open of add popup
  const handleClick = (e, index, name) => {
    setIndex1(index);
    setAnchorEl(e.currentTarget);
  };

  const paragraph = {
    type: "paragraph",
    value: { en: "", sw: "" },
  };
  const image = {
    type: "image",
    image_link: "",
  };
  const link = {
    type: "link",
    link: {
      title: { en: "", sw: "" },
      url: "",
    },
  };
  const list = {
    type: "list",
    items: [{ en: "", sw: "" }],
  };
  //add function of different content
  const addFunction = (item) => {
    if (item === "para") {
      const _description = [...props.Array];
      _description.splice(index1, 0, paragraph);
      props.SetArray(_description);
      handleClose();
    } else if (item === "image") {
      const _description = [...props.Array];
      _description.splice(index1, 0, image);
      props.SetArray(_description);
      handleClose();
    } else if (item === "list") {
      const _description = [...props.Array];
      _description.splice(index1, 0, list);
      props.SetArray(_description);
      handleClose();
    } else if (item === "link") {
      const _description = [...props.Array];
      _description.splice(index1, 0, link);
      props.SetArray(_description);
      handleClose();
    }
  };
  //remove function of different conent
  const removeHandler = (index) => {
    const _description = props.Array.filter((item, _index) => _index !== index);
    props.SetArray(_description);
    setImageData();
  };
  //onchange function of content
  const handleOnchange = (e, index, type, lang) => {
    if (type === "paragraph") {
      const newArray = props.Array.map((item, i) => {
        if (index === i) {
          return {
            ...item,
            value: {
              ...item.value,
              en: lang === "en" ? e.target.value : item.value.en,
              sw: lang === "sw" ? e.target.value : item.value.sw,
            },
          };
        } else {
          return item;
        }
      });
      props.SetArray(newArray);
    } else if (type === "image") {
      const newArray = props.Array.map((item, i) => {
        if (index === i) {
          return { ...item, image_link: e };
        } else {
          return item;
        }
      });
      props.SetArray(newArray);
    } else if (type === "link") {
      const newArray = props.Array.map((item, i) => {
        if (index === i) {
          let link = item.link;
          if (e.target.name === "title") {
            if (lang === "en") {
              link.title.en = e.target.value.trimStart();
            } else if (lang === "sw") {
              link.title.sw = e.target.value.trimStart();
            }
          }
          if (e.target.name === "url") {
            link.url = e.target.value.trimStart();
            if (isValidURL(e.target.value)) {
              setSuccess("Valid Url");
              setErr();
            } else {
              setSuccess();
              setErr("Not a Valid Url");
            }
          }
          return { ...item, link };
        } else {
          return item;
        }
      });
      props.SetArray(newArray);
    }
  };
  //list handle function of content
  const listHandle = (e, index, arrInd, lang) => {
    const newArray = props.Array.map((item, i) => {
      if (index === i) {
        const arr = item.items.map((str, index) => {
          if (index === arrInd) {
            return {
              ...str,
              en: lang === "en" ? e.target.value.trimStart() : str.en,
              sw: lang === "sw" ? e.target.value.trimStart() : str.sw,
            };
          } else {
            return str;
          }
        });
        return { ...item, items: arr };
      } else {
        return item;
      }
    });
    props.SetArray(newArray);
  };
  //add list item to content function
  const addListitem = (index) => {
    const newArray = props.Array.map((item, i) => {
      if (index === i) {
        return { ...item, items: [...item.items, { en: "", sw: "" }] };
      } else {
        return item;
      }
    });
    props.SetArray(newArray);
  };
  //remove list item function from content
  const removeListitem = (index, arrIndex) => {
    const newArray = props.Array.map((item, i) => {
      if (index === i) {
        const arr = item.items.filter((str, index) => index !== arrIndex);

        return { ...item, items: arr };
      } else {
        return item;
      }
    });
    props.SetArray(newArray);
  };
  //function for validationg url
  function isValidURL(string) {
    var res = string.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    );
    return res !== null;
  }

  return (
    <div>
      <div className={styles.jobDetailsContainerStyle}>
        <h3>{props.headerText}</h3>
        {props.Array.map((item, index) => {
          return (
            <div key={index}>
              {item.type === "paragraph" && (
                <div className={styles.inputMainContainerStyle}>
                  <Button
                    buttonName={"Add"}
                    onClick={(e) => handleClick(e, index)}
                    btnStyle={styles.buttonStyle}
                  />

                  <div className={styles.inputContainerStyle}>
                    <div className={styles.inputInsideContainerStyle}>
                      <p>English</p>
                      <div className={styles.langInputContainerStyle}>
                        <TextAreaInput
                          placeholder={"English paragraph..."}
                          onChange={(e) =>
                            handleOnchange(e, index, item.type, "en")
                          }
                          value={item.value.en}
                        />
                        {item.value.en.length > 1 && (
                          <div className={styles.closeIconStyle}>
                            <CheckIconSvg color={"#007f00"} />
                          </div>
                        )}
                      </div>
                      <p>Swedish</p>
                      <div className={styles.langInputContainerStyle}>
                        <TextAreaInput
                          placeholder={"Swedish paragraph..."}
                          onChange={(e) =>
                            handleOnchange(e, index, item.type, "sw")
                          }
                          value={item.value.sw}
                        />
                        {item.value.sw.length > 1 && (
                          <div className={styles.closeIconStyle}>
                            <CheckIconSvg color={"#007f00"} />
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className={styles.closeIconStyle}
                      onClick={() => removeHandler(index)}
                    >
                      <CloseIconSvg color={"#000000"} />
                    </div>
                  </div>
                </div>
              )}
              {item.type === "image" && (
                <div className={styles.inputMainContainerStyle}>
                  <Button
                    buttonName={"Add"}
                    onClick={(e) => handleClick(e, index)}
                    btnStyle={styles.buttonStyle}
                  />
                  <div className={styles.inputContainerStyle2}>
                    <ImageUpload
                      inputRef={inputRef}
                      imageChange={(e) => imageChange(e, index)}
                      selectedImage={item.image_link}
                      imageData={imageData}
                      setImageUrl={(url) =>
                        handleOnchange(url, index, item.type)
                      }
                    />
                    {item.value !== "" && (
                      <div className={styles.closeIconStyle}>
                        <CheckIconSvg color={"#007f00"} />
                      </div>
                    )}
                    <div
                      className={styles.closeIconStyle}
                      onClick={() => removeHandler(index)}
                    >
                      <CloseIconSvg color={"#000000"} />
                    </div>
                  </div>
                </div>
              )}
              {item.type === "list" && (
                <div className={styles.inputMainContainerStyle}>
                  <Button
                    buttonName={"Add"}
                    onClick={(e) => handleClick(e, index)}
                    btnStyle={styles.buttonStyle}
                  />
                  <div className={styles.inputContainerStyle}>
                    <div className={styles.listContainerStyle}>
                      {item.items.map((element, arrindex) => {
                        return (
                          <div
                            key={arrindex}
                            className={styles.listInsideContainerStyle}
                          >
                            <div className={styles.listLabelContainerStyle}>
                              <p>English</p>
                              <div className={styles.listInputContainerStyle}>
                                <TextInput
                                  placeholder={"English list..."}
                                  value={element.en}
                                  onChange={(e) =>
                                    listHandle(e, index, arrindex, "en")
                                  }
                                />
                                {element.en.length > 1 && (
                                  <div className={styles.closeIconStyle}>
                                    <CheckIconSvg color={"#007f00"} />
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className={styles.listLabelContainerStyle}>
                              <p>Swedish</p>
                              <div className={styles.listInputContainerStyle}>
                                <TextInput
                                  placeholder={"Swedish list..."}
                                  value={element.sw}
                                  onChange={(e) =>
                                    listHandle(e, index, arrindex, "sw")
                                  }
                                />
                                {element.sw.length > 1 && (
                                  <div className={styles.closeIconStyle}>
                                    <CheckIconSvg color={"#007f00"} />
                                  </div>
                                )}
                              </div>
                            </div>
                            {item.items.length > 1 && (
                              <div
                                className={styles.closeIconStyle}
                                onClick={() => removeListitem(index, arrindex)}
                              >
                                <CloseIconSvg color={"#000000"} />
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                    <Button
                      buttonName={"list+1"}
                      onClick={() => addListitem(index)}
                    />
                    <div
                      className={styles.closeIconStyle}
                      onClick={() => removeHandler(index)}
                    >
                      <CloseIconSvg color={"#000000"} />
                    </div>
                  </div>
                </div>
              )}
              {item.type === "link" && (
                <div className={styles.inputMainContainerStyle}>
                  <Button
                    buttonName={"Add"}
                    onClick={(e) => handleClick(e, index)}
                    btnStyle={styles.buttonStyle}
                  />
                  <div className={styles.inputContainerStyle}>
                    <TextInput
                      placeholder={"English title..."}
                      value={item.link.title.en}
                      name="title"
                      onChange={(e) =>
                        handleOnchange(e, index, item.type, "en")
                      }
                    />
                    <TextInput
                      placeholder={"Swedish title..."}
                      value={item.link.title.sw}
                      name="title"
                      onChange={(e) =>
                        handleOnchange(e, index, item.type, "sw")
                      }
                    />
                    <TextInput
                      placeholder={"url..."}
                      value={item.link.url}
                      name="url"
                      onChange={(e) => handleOnchange(e, index, item.type)}
                    />

                    {item.link.url.length > 1 && (
                      <div className={styles.closeIconStyle}>
                        <CheckIconSvg color={"#007f00"} />
                      </div>
                    )}
                    <div
                      className={styles.closeIconStyle}
                      onClick={() => removeHandler(index)}
                    >
                      <CloseIconSvg color={"#000000"} />
                    </div>
                  </div>
                  {err && <p className={styles.errStyle}>{err}</p>}
                  {success && <p className={styles.successStyle}>{success}</p>}
                </div>
              )}
            </div>
          );
        })}

        <Button
          buttonName={"Add"}
          onClick={(e) => handleClick(e, props.Array.length)}
          btnStyle={styles.buttonStyle}
        />
      </div>
      <MenuPopover
        id={id}
        open={openPop}
        anchorEl={anchorEl}
        onClose={handleClose}
        position={"center"}
      >
        <div className={styles.popoverStyle}>
          <p
            className={styles.textPopupStyle}
            onClick={() => addFunction("para")}
          >
            Paragraph
          </p>
          <p
            className={styles.textPopupStyle}
            onClick={() => addFunction("image")}
          >
            image
          </p>
          <p
            className={styles.textPopupStyle}
            onClick={() => addFunction("list")}
          >
            list
          </p>
          <p
            className={styles.textPopupStyle}
            onClick={() => addFunction("link")}
          >
            link
          </p>
        </div>
      </MenuPopover>
    </div>
  );
};

export default ContentComponent;
