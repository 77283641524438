import React from "react";
import styles from "./styles.module.css";
const ServiceBlock = (props) => {
  return (
    <div className={styles.containerStyle}>
      <p className={styles.textStyle}>
        {props.title}&nbsp;
        <a href={props.link} target="_blank" className={styles.linkStyle}>
          {props.linkName}
        </a>
      </p>
    </div>
  );
};

export default ServiceBlock;
