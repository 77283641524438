import axios from "../axios";
import * as endPoints from "../endpoints";

export const createPostApi = async (data) =>
  Promise.resolve(axios.post(endPoints.createPost, data));

export const getPostsList = async () =>
  Promise.resolve(axios.get(endPoints.postsList));

export const deletePostApi = async (Id) =>
  Promise.resolve(axios.delete(endPoints.deletePost.replace("{id}", Id)));

export const updatePostsApi = async (data) =>
  Promise.resolve(axios.post(endPoints.updatePost, data));

export const getPostDetailsApi = async (data) =>
  Promise.resolve(axios.post(endPoints.getPostDetails, data));

export const getPostDetailsByIdApi = async (id) =>
  Promise.resolve(axios.get(endPoints.getPostDetailsById.replace("{id}", id)));
