import axios from "../axios";
import * as endPoints from "../endpoints";

export const createJobPost = async (data) =>
  Promise.resolve(axios.post(endPoints.createJobPost, data));

export const getJobPostList = async () =>
  Promise.resolve(axios.get(endPoints.getJobPostList));

export const deleteJobPost = async (Id) =>
  Promise.resolve(axios.delete(endPoints.deleteJobPost.replace("{id}", Id)));

export const getJobDetailsApi = async (slug) =>
  Promise.resolve(axios.get(endPoints.getJobDetails.replace("{slug}", slug)));

export const updateJobDetailsApi = async (data) =>
  Promise.resolve(axios.post(endPoints.updateJobDetails, data));
