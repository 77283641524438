import React from "react";
import styles from "./styles.module.css";
const TextAreaInput = (props) => {
  return (
    <textarea
      placeholder={props.placeholder}
      value={props.value}
      className={styles.inputStyle}
      onChange={(text) => props.onChange(text)}
      type="text"
      rows={6}
    />
  );
};

export default TextAreaInput;
