import React from "react";
import styles from "./styles.module.css";

export const GlobeSvg = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99478 5.83951C9.34013 7.37396 8.9151 9.55146 8.9151 12C8.9151 14.4485 9.34013 16.626 9.99478 18.1605C10.3228 18.9292 10.6921 19.4983 11.0599 19.863C11.4243 20.2242 11.7411 20.3437 12 20.3437C12.2589 20.3437 12.5757 20.2242 12.9401 19.863C13.3079 19.4983 13.6772 18.9292 14.0052 18.1605C14.6599 16.626 15.0849 14.4485 15.0849 12C15.0849 9.55146 14.6599 7.37396 14.0052 5.83951C13.6772 5.07076 13.3079 4.50174 12.9401 4.13705C12.5757 3.77578 12.2589 3.65631 12 3.65631C11.7411 3.65631 11.4243 3.77578 11.0599 4.13705C10.6921 4.50174 10.3228 5.07076 9.99478 5.83951ZM9.8938 2.9608C10.4657 2.39383 11.1783 2 12 2C12.8217 2 13.5343 2.39383 14.1062 2.9608C14.6746 3.52435 15.1485 4.29852 15.5287 5.18955C16.2902 6.97465 16.7412 9.38307 16.7412 12C16.7412 14.6169 16.2902 17.0253 15.5287 18.8105C15.1485 19.7015 14.6746 20.4756 14.1062 21.0392C13.5343 21.6062 12.8217 22 12 22C11.1783 22 10.4657 21.6062 9.8938 21.0392C9.32536 20.4756 8.85146 19.7015 8.47132 18.8105C7.70973 17.0253 7.25879 14.6169 7.25879 12C7.25879 9.38307 7.70973 6.97465 8.47132 5.18955C8.85147 4.29852 9.32536 3.52435 9.8938 2.9608Z"
        className={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.62109 8.93558C2.62109 8.4782 2.99187 8.10742 3.44925 8.10742H20.5507C21.0081 8.10742 21.3789 8.4782 21.3789 8.93558C21.3789 9.39296 21.0081 9.76374 20.5507 9.76374H3.44925C2.99187 9.76374 2.62109 9.39296 2.62109 8.93558Z"
        className={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.62109 15.0645C2.62109 14.6071 2.99187 14.2363 3.44925 14.2363H17.9006C18.358 14.2363 18.7288 14.6071 18.7288 15.0645C18.7288 15.5219 18.358 15.8926 17.9006 15.8926H3.44925C2.99187 15.8926 2.62109 15.5219 2.62109 15.0645Z"
        className={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3.65631C7.39191 3.65631 3.65631 7.39191 3.65631 12C3.65631 16.6081 7.39191 20.3437 12 20.3437C16.6081 20.3437 20.3437 16.6081 20.3437 12C20.3437 7.39191 16.6081 3.65631 12 3.65631ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z"
        className={props.color}
      />
    </svg>
  );
};
export const DownArrowSvg = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22C6.48 22 2 17.51 2 12C2 6.48 6.48 2 12 2C17.51 2 22 6.48 22 12C22 17.51 17.51 22 12 22ZM16 10.02C15.7 9.73 15.23 9.73 14.94 10.03L12 12.98L9.06 10.03C8.77 9.73 8.29 9.73 8 10.02C7.7 10.32 7.7 10.79 8 11.08L11.47 14.57C11.61 14.71 11.8 14.79 12 14.79C12.2 14.79 12.39 14.71 12.53 14.57L16 11.08C16.15 10.94 16.22 10.75 16.22 10.56C16.22 10.36 16.15 10.17 16 10.02Z"
        className={props.color}
      />
    </svg>
  );
};
export const UpArrowSvg = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C17.52 2 22 6.49 22 12C22 17.52 17.52 22 12 22C6.49 22 2 17.52 2 12C2 6.49 6.49 2 12 2ZM8 13.98C8.3 14.27 8.77 14.27 9.06 13.97L12 11.02L14.94 13.97C15.23 14.27 15.71 14.27 16 13.98C16.3 13.68 16.3 13.21 16 12.92L12.53 9.43C12.39 9.29 12.2 9.21 12 9.21C11.8 9.21 11.61 9.29 11.47 9.43L8 12.92C7.85 13.06 7.78 13.25 7.78 13.44C7.78 13.64 7.85 13.83 8 13.98Z"
        className={props.color}
      />
    </svg>
  );
};
export const NextArrowSvg = (props) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.75 23.7246H16.75"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={props.color}
      />
      <path
        d="M25.7002 17.701L31.7502 23.725L25.7002 29.75"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={props.color}
      />
      <rect
        x="0.5"
        y="0.5"
        width="47"
        height="47"
        rx="23.5"
        className={props.color}
      />
    </svg>
  );
};
export const BackArrowSvg = (props) => {
  return (
    <svg
      width={props.size || "48"}
      height={props.size || "48"}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.25 23.7246H31.25"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={props.color}
      />
      <path
        d="M22.2998 17.701L16.2498 23.725L22.2998 29.75"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={props.color}
      />
      <rect
        x="0.5"
        y="0.5"
        width="47"
        height="47"
        rx="23.5"
        className={props.color}
      />
    </svg>
  );
};
export const CloseIconSvg = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1276_37259)">
        <path
          d="M6.18549 5.81451C6.45398 5.54602 6.87535 5.52347 7.16934 5.74718L7.24618 5.81427L12.3737 10.94L17.5024 5.81133C17.7953 5.51845 18.2702 5.51845 18.563 5.81133C18.8315 6.07982 18.8539 6.50126 18.6302 6.7952L18.563 6.87199L13.4343 12.0007L18.5616 17.1252C18.8546 17.418 18.8548 17.893 18.5619 18.1858C18.2934 18.4543 17.8721 18.4769 17.578 18.2532L17.5012 18.1861L12.3737 13.0614L7.24784 18.1872C6.95495 18.4801 6.48007 18.4801 6.18718 18.1872C5.9187 17.9187 5.89632 17.4973 6.12003 17.2033L6.18718 17.1265L11.313 12.0007L6.18573 6.87514C5.89277 6.58232 5.8926 6.1074 6.18549 5.81451Z"
          fill={props.color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1276_37259">
          <rect width="24" height="24" fill={props.color} />
        </clipPath>
      </defs>
    </svg>
  );
};
export const CheckIconSvg = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.7099 7.20986C18.617 7.11613 18.5064 7.04174 18.3845 6.99097C18.2627 6.9402 18.132 6.91406 17.9999 6.91406C17.8679 6.91406 17.7372 6.9402 17.6154 6.99097C17.4935 7.04174 17.3829 7.11613 17.29 7.20986L9.83995 14.6699L6.70995 11.5299C6.61343 11.4366 6.49949 11.3633 6.37463 11.3141C6.24978 11.2649 6.11645 11.2408 5.98227 11.2431C5.84809 11.2454 5.71568 11.2741 5.5926 11.3276C5.46953 11.3811 5.35819 11.4583 5.26495 11.5549C5.17171 11.6514 5.0984 11.7653 5.04919 11.8902C4.99999 12.015 4.97586 12.1484 4.97818 12.2825C4.9805 12.4167 5.00923 12.5491 5.06272 12.6722C5.11622 12.7953 5.19343 12.9066 5.28995 12.9999L9.12995 16.8399C9.22291 16.9336 9.33351 17.008 9.45537 17.0588C9.57723 17.1095 9.70794 17.1357 9.83995 17.1357C9.97196 17.1357 10.1027 17.1095 10.2245 17.0588C10.3464 17.008 10.457 16.9336 10.55 16.8399L18.7099 8.67986C18.8115 8.58622 18.8925 8.47257 18.9479 8.34607C19.0033 8.21957 19.0319 8.08296 19.0319 7.94486C19.0319 7.80676 19.0033 7.67015 18.9479 7.54365C18.8925 7.41715 18.8115 7.3035 18.7099 7.20986Z"
        fill={props.color}
      />
    </svg>
  );
};
