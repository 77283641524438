import React, { useState } from "react";
import styles from "./styles.module.css";
import { rightUpArrowSmall } from "../../resources/images";

const Button = (props) => {
  return (
    <button
      disabled={props.disabled || false}
      onClick={props.onClick}
      className={[props.btnStyle, styles.buttonStyle].join(" ")}
      onDoubleClick={props.onDoubleClick}
    >
      {props.buttonName}
      {props.arrowBtn && (
        <div className={props.arrowBtnStyle}>
          <img src={rightUpArrowSmall} className={styles.imgStyle} />
        </div>
      )}
    </button>
  );
};

export default Button;
