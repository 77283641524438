import React from "react";
import styles from "./styles.module.css";
import { aboutPageHeroImg } from "../../resources/images";
import findLinksAndEmails from "../../helpers/linkfinder/index.js";
import { useAppData } from "../../providers/AppDataProvider";

const ContentUi = (props) => {
  const { currentLanguage } = useAppData();
  return (
    <div className={styles.contentContainerStyle}>
      {props.Data?.map((item, index) => {
        return (
          <div key={index} className={styles.contentContainerStyle}>
            {item.type === "paragraph" && (
              <p
                className={styles.jonPositionHeaderContentTextStyle}
                dangerouslySetInnerHTML={{
                  __html: findLinksAndEmails(item.value[currentLanguage]),
                }}
              />
            )}
            {item.type === "link" && (
              <a
                href={item.link.url}
                target="_blank"
                className={styles.jonPositionHeaderLinkTextStyle}
              >
                {item.link.url}
              </a>
            )}
            {item.type === "image" && (
              <div className={styles.heroImageWrapperStyle}>
                <img src={item.image_link} className={styles.heroImgStyle} />
              </div>
            )}

            {item.type === "list" && (
              <ul className={styles.listStyle}>
                {item.items.map((item, index) => {
                  return (
                    <li
                      key={index}
                      className={styles.jonPositionHeaderContentTextStyle}
                      dangerouslySetInnerHTML={{
                        __html: findLinksAndEmails(item[currentLanguage]),
                      }}
                    />
                  );
                })}
              </ul>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ContentUi;
