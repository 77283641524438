import React, { useEffect } from "react";
import styles from "./styles.module.css";
import Button from "../button";
import { closeIcon, gallery } from "../../resources/images";
import { useAppData } from "../../providers/AppDataProvider";
const Modal = (props) => {
  const { strings, currentLanguage } = useAppData();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const renderCloseBtn = () => {
    return (
      <div className={styles.closeBtnStyle} onClick={() => props.closeModal()}>
        <p className={styles.closeBtnTextStyle}>{strings.common.close}</p>
        <img src={closeIcon} />
      </div>
    );
  };
  const renderBtns = () => {
    return (
      <div className={styles.btnContainerStyle}>
        <Button
          onClick={() => props.emailBtnAction()}
          buttonName={
            props.emailCopied ? props.data.email : strings.common.emailCaps
          }
          btnStyle={props.emailCopied ? styles.btnStyle2 : styles.btnStyle}
        />
        <Button
          onClick={() => props.phoneBtnAction()}
          buttonName={
            props.phoneCopied
              ? props.data.phone_number
              : strings.common.phoneNUmberCaps
          }
          btnStyle={props.phoneCopied ? styles.btnStyle2 : styles.btnStyle}
        />
        <Button
          onClick={() => props.linkedinBtnAction()}
          buttonName={strings.common.linkedin.toUpperCase()}
          btnStyle={styles.btnStyle}
        />
      </div>
    );
  };
  return (
    <div className={styles.modalContainerStyle}>
      {renderCloseBtn()}
      <div className={styles.subcaontainerStyle}>
        <div className={styles.imgContainerStyle}>
          <img src={props.data.image} className={styles.imageStyle} />
        </div>
        <div className={styles.detailsContainerStyle}>
          <div className={styles.titleContainerStyle}>
            <h2 className={styles.modalTitleTextStyle}>{props.data.name}</h2>
            <p className={styles.modalTitleDesTextStyle}>
              {props.data.designation[currentLanguage]}
            </p>
          </div>
          {/* // Competence */}
          <div className={styles.otherDetailsStyle}>
            {props.data.competence && (
              <div className={styles.otherDetailsSubStyle}>
                <p className={styles.subTitleStyle}>Competence:</p>
                <div className={styles.listTextStyle}>
                  {props.data.competence &&
                    props.data.competence.map((item, index) => {
                      return (
                        <p
                          key={index}
                          className={
                            !item[currentLanguage].trim()
                              ? styles.subTitleDescTextStyle
                              : styles.subTitleDescStyle
                          }
                        >
                          {item[currentLanguage]}
                        </p>
                      );
                    })}
                </div>
              </div>
            )}
            {/* //Experience */}
            {props.data.experience && (
              <div className={styles.otherDetailsSubStyle}>
                <p className={styles.subTitleStyle}>Experience:</p>
                <div className={styles.listTextStyle}>
                  {props.data.experience &&
                    props.data.experience.map((item, index) => {
                      return (
                        <div>
                          <p
                            className={
                              !item[currentLanguage].trim()
                                ? styles.subTitleDescTextStyle
                                : styles.subTitleDescStyle2
                            }
                          >
                            {item[currentLanguage]}
                          </p>
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
            {/* // Education */}
            {props.data.education && (
              <div className={styles.otherDetailsSubStyle}>
                <p className={styles.subTitleStyle}>Education:</p>
                <div className={styles.listTextStyle}>
                  {props.data.education &&
                    props.data.education.map((item, index) => {
                      return (
                        <div>
                          <p
                            className={
                              !item[currentLanguage].trim()
                                ? styles.subTitleDescTextStyle
                                : styles.subTitleDescStyle2
                            }
                          >
                            {item[currentLanguage]}
                          </p>
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
          </div>
          {renderBtns()}
        </div>
      </div>
    </div>
  );
};

export default Modal;
