import React, { useEffect } from "react";
import styles from "./styles.module.css";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import {
  getJobPostList,
  deleteJobPost,
} from "../../../../networking/api/jobposts";
import { useState } from "react";
import Button from "../../../../components/button";
import { useNavigate } from "react-router-dom";
import Loader from "../../../../components/loader";
import { Helmet } from "react-helmet";
import { useAppData } from "../../../../providers/AppDataProvider";
import GoBack from "../../../../components/goback";

const columns = [
  { id: "JobName", label: "JobName", minWidth: 170 },
  {
    id: "View",
    label: "View",
    minWidth: 170,
    align: "right",
  },
  {
    id: "Edit",
    label: "Edit",
    minWidth: 170,
    align: "right",
  },
  {
    id: "Delete",
    label: "Delete",
    minWidth: 170,
    align: "right",
  },
];

const JobPostList = () => {
  const navigate = useNavigate();
  const { currentLanguage, strings } = useAppData();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [jobData, setJobData] = useState();
  useEffect(() => {
    getJobListData();
  }, []);
  //api for geting job data
  const getJobListData = () => {
    try {
      getJobPostList().then((res) => {
        if (res.data.type === "success") {
          setJobData(res.data.data);
        } else {
          console.log(res.data.message);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  //api for deleting individual job
  const JobDeletion = (id) => {
    try {
      deleteJobPost(id).then((res) => {
        if (res.data.type === "success") {
          removeJobPost(id);
        } else {
          console.log(res.data.message);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  //updating list after deleting job
  const removeJobPost = (id) => {
    const newArray = jobData.filter((item) => item._id !== id);
    setJobData(newArray);
  };
  return (
    <React.Fragment>
      <Helmet>
        <meta name="description" content="Useful Legal Services" />
        <meta name="keywords" content="Useful Legal Services" />
        <title>RosholmDell | {strings.common.joblist}</title>
      </Helmet>
      {!jobData ? (
        <Loader loaderStyles={styles.containerStyle} />
      ) : (
        <div className={styles.containerWrapperStyle}>
          <div>
            <GoBack />
            <h1 className={styles.jobpostingHeaderTextStyle}>
              Job Postings List
            </h1>
          </div>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: "100%" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell key={column.id}>{column.label}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {jobData &&
                    jobData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((item, index) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={index}
                          >
                            <TableCell className={styles.ellipsesText}>
                              {item.title[currentLanguage]}
                            </TableCell>
                            <TableCell>
                              <Button
                                buttonName={"View"}
                                onClick={() =>
                                  navigate(`/jobposition?${item.slug}`)
                                }
                              />
                            </TableCell>
                            <TableCell>
                              <Button
                                buttonName={"Edit"}
                                onClick={() =>
                                  navigate("/editjob", { state: item.slug })
                                }
                              />
                            </TableCell>
                            <TableCell>
                              <Button
                                buttonName={"Delete"}
                                onClick={() => JobDeletion(item._id)}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={jobData?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      )}
    </React.Fragment>
  );
};
export default JobPostList;
