import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.css";
import { BackArrowSvg } from "../svgcomponents";

const GoBack = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.leftArrowWrapperStyle} onClick={() => navigate(-1)}>
      <BackArrowSvg color={styles.arrowBtnStyle} size={28} />
      Go back
    </div>
  );
};

export default GoBack;
